.nw-button-parent{
    display: flex;
    justify-content: space-between;

    .required-field{
        color: #0E75F8;

        span{
            color: #ff0000;
        }
    }

    .nw-button-right{
        // justify-content: flex-end;
        // text-align: right
        margin-left: auto !important;
    }

    .nw-split-button{
        width: auto !important;
        min-width: 120px !important;
        max-width: 450px;
        height: 44px;
        margin-left: auto !important;

        .p-button-text-icon-left{
            background-color: #697CAB !important;
            min-width: 120px !important;
            width: auto !important;
            max-width: 450px;
            height: 44px;
            // padding: 15px;
            font-size: 14px;
            // font-weight: bold
            border-radius: 2px;
            box-shadow: 0px 12px 22px 0px #6A6A6A40;
            font-weight: 700;
            letter-spacing: 0.15px;
            padding: 0 10px !important;
        }

        .p-splitbutton-menubutton{
            background-color: #465372 !important;
            width: auto !important;
            height: 44px;
            // padding: 15px;
            font-size: 14px;
            // font-weight: bold
            border-radius: 2px;
            box-shadow: 0px 12px 22px 0px #6A6A6A40;
            font-weight: 700;
            letter-spacing: 0.15px;
            // padding: 0 10px !important;
        }
        
    }
}

.nw-button-parent-multiple{
    display: flex;
    justify-content: flex-end;

    .nw-button-multiple{
        margin: 0 0 0 15px;
    }
}



.nw-form-download-button{
    padding: 6px 20px !important;

    .p-button{
        border: 1px solid #D2EAFF;
        background-color: #689fde !important;
        color: #ffffff !important;
        border-top-right-radius: 3px !important;
        border-bottom-right-radius: 3px !important;
    }
}

.nw-button{
    background-color: #aaaaaa !important;
    min-width: 120px !important;
    width: auto !important;
    max-width: 450px;
    height: 44px;
    // padding: 15px;
    font-size: 14px;
    // font-weight: bold
    border-radius: 2px;
    box-shadow: 0px 12px 22px 0px #6A6A6A40;
    font-weight: 700;
    letter-spacing: 0.15px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 0 10px !important;

    &.nw-button-no-style{
        min-width: unset !important;
        // width: 100% !important;
        border: unset;
        background-color: #ffffff !important;
        box-shadow: unset;
        display: flex !important;
        align-items: baseline;
        justify-content: center;
        color: #0099FF;
        border-radius: unset;

        &:hover{
            background-color: #0099FF!important;
            color: #ffffff ;
            border-radius: 4px;
        }
    }
    

    .p-button-text{
        padding: 0 !important;
        margin: 0 6px 2px 6px !important;
    }

    .p-button-icon-left{
        // opacity: .4;
        position: unset !important;
        top: unset !important; 
        margin-top: unset !important;
        // height: unset !important;
        // line-height: 1.6;
    }
}

.nw-button.p-button-primary{
    background-color: $primary-color !important;
}

.nw-button.p-button-warning{
    background-color: $warning-color !important;
}

.nw-button{
    &.nw-button-anim-save{
        background-size: 200% auto;
        background-image: linear-gradient(to right, #0099FF 0%, rgb(0, 95, 219) 50%, #0E75F8 100%);
        transition: 0.5s;
        animation: slide-right 2s cubic-bezier(0.21, 0.09, 0.58, 1) infinite both;

        // &:hover {
        //     background-position: right center;
        // }  

        @keyframes slide-right {
            0% {
                background-position: right center;
            }
            50% {
                background-position: left center;
            }
            100% {
                background-position: right center;
            }
        }
          
    }

    &.nw-button-anim-save-success{
        background-color: #00a96a !important;
        border: none !important;
    
        .p-button-icon-left{
            position: relative !important;
            width: 20px;
            // overflow: hidden;
    
            &::before{
                overflow: hidden;
                animation: scale-up-hor-left 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                width: 0px;
                position: absolute;
                top: 0;
                left: 0;
            }
            @keyframes scale-up-hor-left {
                0% {
                    width: 0px;
                  }
                  100% {
                    width: 14px;
                  }
            }
        }
    }
}

.nw-button.p-button-primary:enabled:hover, .p-button.p-button-primary:enabled:hover{
    background-color: #0E75F8 !important;
}



.nw-button.p-button-danger{
    background-color: #E89191 !important;
    border: none !important;
    color: #ffffff;
}

.nw-button.p-button-danger:enabled:hover, .p-button.p-button-danger:enabled:hover{
    background-color: #ce8181 !important;
}


.nw-button.p-button-success{
    background-color: #00a96a !important;
    border: none !important;
    color: #ffffff;

    // .p-button-icon-left{
    //     position: relative !important;
    //     width: 20px;
    //     // overflow: hidden;

    //     &::before{
    //         overflow: hidden;
    //         animation: scale-up-hor-left 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    //         width: 0px;
    //         position: absolute;
    //         top: 0;
    //         left: 0;
    //     }
    //     @keyframes scale-up-hor-left {
    //         0% {
    //             width: 0px;
    //           }
    //           100% {
    //             width: 14px;
    //           }
    //     }
    // }
}

.nw-button.p-button-success:enabled:hover, .p-button.p-button-success:enabled:hover{
    background-color: #00754a !important;
}


.nw-button-left{
    float: left;
}
// .nw-form-body .p-grid > div:last-child{
//     display: flex;
//     justify-content: flex-end;
//     // .nw-button-right{
//     //     justify-content: right;
//     //     // margin-right: -8px !important;
//     // }
// }
.nw-button-center{
    // display: block !important;
    margin: 0 auto !important;
    // width: 210px !important;
}
.nw-upload-button {
    .p-fileupload-choose{ 
        display: block !important;
        margin: 0 auto !important;
        // width: 210px !important;
        background-color: $bgColorWhite !important;
        color: #0E75F8 !important;
        border-style: dashed !important;
        font-weight: bold !important;
        height: 44px;
        min-width: 160px;
        max-width: 250px;
    }

    .p-button-text{
        padding: .75em 1em 0.429em 2em !important;
        word-break: break-word;
    }

    /*
    * File
    */

    .file {
        position: relative;
        display: inline-block;
        cursor: pointer;
        height: 2.5rem;
    }
    .file input {
        min-width: 14rem;
        margin: 0;
        filter: alpha(opacity=0);
        opacity: 0;
    }
    .file-custom {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 5;
        height: 44px;
        padding: 12px 5px;
        line-height: 1.5;
        color: #555;
        background-color: #fff;
        border: 1px solid #2196F3;
        // border-radius: .25rem;
        box-shadow: inset 0 .2rem .4rem rgba(0,0,0,.05);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-size: 12px;
        text-align: left;
    }
    .file-custom:after {
        content: "";
    }
    .file-custom:before {
        position: absolute;
        top: -.075rem;
        right: -.075rem;
        bottom: -.075rem;
        z-index: 6;
        display: block;
        content: "Browse";
        // height: 2.5rem;
        padding: 12px 10px;
        line-height: 1.5;
        color: #ffffff;
        background-color: #004BA0;
        border: .075rem solid #ddd;
        // border-radius: 0 .25rem .25rem 0;
        border: 1px solid #2196F3;
    }
    
    /* Focus */
    .file input:focus ~ .file-custom {
        box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;
    }
    
}

.nw-action-button{
    border: 1px solid #BBE4FF;
    box-shadow: unset;
    color: #0099FF;
    background-color: #FFFFFF !important;
    font-size: 16px;
    &:hover{
        color: #FFFFFF;
        background-color:  #0099FF !important;
        transition: 0.5s;
    }
    &.note{
    
    }

    &.info{

    }

    &.edit{

    }

} 



// .button-note{
//     background-color: unset;
// }