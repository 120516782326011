.walletContainer {
  padding: 2rem 1rem;

  &__inner {
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    &__title {
      text-align: center;
    }
    &__row {
      display: flex;
      justify-content: start;
      padding: 2rem;

      &__left {
        width: 100%;
        // padding: 1rem;
        margin: auto 0;

        &__icons {
          display: flex;
          justify-content: space-between;
          align-items: center;
          // padding: 1rem;

          &__icon {
            padding: 3rem;
            height: 16rem;
            width: 19.5rem;
            background-color: #f5f8fd;
            border-radius: 15px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:hover {
              background-color: #0099ff;
              box-shadow: 0px 11px 7px #5c5c5c4a;
              .circle {
                background-color: #ffffff73;
              }
              .wallet-title {
                color: #fff;
              }
            }

            &.active {
              background-color: #0099ff;
              box-shadow: 0px 11px 7px #5c5c5c4a;
              .circle {
                background-color: #ffffff73;
              }
              .wallet-title {
                color: #ffe600;
              }
            }

            .circle {
              background-color: #6479aa42;
              height: 11rem;
              width: 8rem;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                height: 70%;
                width: 70%;
                object-fit: contain;
              }
            }
            .wallet-title {
              margin-top: 1rem;
              font-size: 15px;
              color: #000000;
              font-weight: 600;
            }
          }
        }
      }
      &__right {
        width: 40%;
        padding: 1rem;

        &__wallet {
          padding: 1rem 2rem;
          border: 5px solid rgb(71, 71, 238);
          height: 100%;
          text-align: center;
          color: rgb(92, 92, 247);

          .fa-wallet {
            font-size: 2rem;
          }
          &__title {
            font-size: 2rem;
          }
        }

        &__recharge {
          background-color: grey;
          border: 2px solid rgb(253, 249, 249);
          padding: 2rem;
          &__inner {
            height: 5rem;
            border: 1px solid red;
            background-color: #fff;

            .tcn {
              border: 2px solid green;
              display: flex;
              justify-content: space-between;
              &__label {
              }
              &__colon {
                margin-right: 10rem;
              }
              &__price {
              }
            }
          }
        }
      }
    }
  }
}
