.inside-dialog-right-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  color: white;

  .data-wrapper-box {
    background-color: #4e8dff;
    border: 1px solid black;
    border-radius: 5px;
    padding: 15px 10px;
  }
}

.first-box-level {
  width: 112px !important;
}

.second-box-level {
  width: 140px !important;
}

.m-l-10 {
  margin-left: 10px;
}
.color-white {
  color: white !important;
}

// .search-result-section {
//   border: 1px solid #e8e8e8;
//   padding-top: 5px;
// }

.first-section-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;

  .image-part {
    // flex: 1;
    min-width: 8%;
    max-width: 12%;
    height: 120;
  }
  .first-border {
    // flex: 8;
    width: 65%;
    justify-content: "center";
    height: 57px;
    border-bottom: 2px solid #949191;
  }
  .first-text {
    text-align: right;
    float: right;
    font-size: 14px;
    margin-right: 3px;
  }
  .circle-part {
    // flex: 1;
    min-width: 6.1%;
    max-width: 10%;
  }

  .inside-circle {
    width: 60px;
    height: 60px;
    display: inline-block;
    border: 1px solid black;
    border-radius: 50%;
    // margin-top: 7px;
    text-align: center;
    line-height: 2;
    font-size: 25px;
    font-weight: bold;

    // float: left;
    // margin-top: 24px;
  }

  .second-border {
    // flex: 2;
    width: 22%;
    justify-content: center;
    height: 57px;
    border-bottom: 2px solid #949191;
    margin-left: -47.9px;
  }
}

//Admisia OA Collection Report
.m-t-30 {
  margin-top: 30px;
}
.font-w-401 {
  font-weight: 401;
}
.collection-summary-header {
  background-color: #697cab;
  padding: 13px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
.collection-summary-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  border-left: 1px solid #d2eaff;
  border-bottom: 1px solid #d2eaff;
  border-right: 1px solid #d2eaff;
  padding: 15px;
  font-size: 16px;

  .summary-container {
    width: 20%;
    height: 110px;
    justify-content: center;
    border: 1px solid #d8d5d5;
    align-items: center;
    text-align: center;
    word-wrap: break-word;
    padding: 18px;
  }

  @media (max-width: 450px) {
    .summary-container {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 1050px) {
    .summary-container {
      height: 150px;
    }
  }
}
