.nw-wallet{
    // background-color: #F6FFFB;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto; 
    // grid-column-gap: 10px;
    // grid-row-gap: 40px;
    // font-family: 'Segoe UI';
    center{
        padding: 40px;
        h2{ 
            // color: $focusColor; 
            font-size: 15px;
        }
        .nw-wallet-title{
            color: #0099FF;
            font-size: 28px;
            font-weight: 100;
            margin: 0px 0 25px 0;
        }
        .nw-wallet-balance{
            color: $inputgroupAddonIconColor;
            font-size: 20px;
            font-weight: 700;
        }
        
    }

    .wallet-balance, .message-balance, .reverse-balance, .today-purchase-balance{
        border: 12px solid;
    }

    .wallet-balance{
        background-color: #ECF6FF;
        border-color: #0099FF20;
        margin: 0 0 20px 0;
        max-width: 400px;

        div{
            span{
                font-size: 15px;
            }
        }
        h2{
            color: #0099FF;
        }
        .nw-wallet-title{
            color: #0099FF;
        }
        .nw-wallet-balance{
            div{
                span{
                    font-family: arial, sans-serif;
                }
            }
            
            color: #004BA0;
        }
        .balance-available{
            color: #0099FF;
        }
    }
    .message-balance{
        background-color: #FFF8EB;
        border-color: #FF230020;
        margin: 0 0 20px 0;
        max-width: 400px;

        h2{
            color: #FF2300;
        }
        .nw-wallet-title{
            color: #FF2300;
        }
        .nw-wallet-balance{
            color: #000000;
        }
        .balance-available{
            color: #FF2300;
        }
    }

    .reverse-balance{
        background-color: #FEFFF6;
        margin: 0 0 20px 0;
        max-width: 400px;

    }


    .today-purchase-balance{
        // background-color: rgb(255, 247, 241)
        background-color: #fff9ff;
        border-color:  #fdd2ff;
        max-width: 400px;

        h2{
            color: #f65cff !important;
        }
        .nw-wallet-title{
            color: #f65cff;
        }
        .nw-wallet-balance{
            
            color: #000000;
            div{
                span{
                    font-family: arial, sans-serif;
                    font-size: 15px;
                }
            }
        }
        .balance-available{
            color: #FF2300;
        }
    }


    .container-password{
        // background-color: rgb(255, 247, 241)
        background-color: #ECECEC;
        border-radius: 4px;
        max-width: 400px;

        h2{
            color: #0099FF;
        }

        .password-title{
            color: #0099FF;
            font-size: 26px;
            margin: 6px 0;
        }

        .password-field{
            color: #000000;
            position: relative;

            .password-input-field{
                background-color: #ffffff;
                // border-radius: unset;
                // width: 250px;
                height: 48px;
                text-align: center;
                color: #004BA0;
                font-size: 15px;
                font-weight: 700;
                filter: blur(4px);
                // box-shadow: 0px 0px 7px 0px;

            }

            .password-overlay{
                background-color: #0099FF;
                // border-radius: unset;
                // width: 250px;
                height: 48px;
                text-align: center;
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                z-index: 1;
                opacity: 0.3;
                border-radius: 4px;
            }
        }

        .footer-text{
            color: #004BA0;
        }

    }
    
}