.nw-form{
    background-color: #ffffff;
    margin: 10px !important;
    border: 1px solid #EBEBEB;
    padding: 20px;
    // min-height: 90vh;

    // ............COMMON STYLE START..................

    .nw-form-no-style{
        border: 0px solid #D2EAFF !important;
        background-color: transparent !important;
    }

    // ............COMMON STYLE END..................


    .seprator{
        max-width: 1024px;
    }

    .panel-box-right{
        background-color: #F9F9F9;
        padding: 20px 20px !important;
        
        div{
            .panel-box-min-max, .panel-box-instruction{
                border-radius: 4px;
                box-shadow: none;

                .p-card-body{
                    padding: 25px 20px;

                    .nw-dataview-small-panel{
                        margin: 0 0 0 0;

                        div{
                            padding: 0px 0 4px 0;

                            div{
                                width: 45%;
                            }

                            div:nth-child(1){
                                justify-content: flex-start;
                                font-weight: bold;
                                font-size: 16px;
                                padding: 0 0 10px 0;
                            }
                      
                            div:nth-child(2){
                                width: auto;
                                font-weight: bold
                                // background: #54CBF8;
                            }
                      
                            div:nth-child(3){
                                justify-content: flex-end;

                                &.primary-color-bg{
                                    border-radius: 3px;
                                    padding: 0 6px;
                                    color: #ffffff;
                                    height: 22px;
                                    font-weight: 700;
                                    letter-spacing: .5px;
                                }
                            }
                        }
                    } 
                }
            }
        }
    }    

    div{
        .p-panel{
            // background-color: $bgColorWhite;
            // margin: 0 -20px;
            
            .p-panel-titlebar{
                border-radius: 0;
                background-color: #68B4F1;
            }
            .p-toggleable-content{
                .p-panel-content{
                    border-radius: 0;
                    border:0 0 1px 0 solid #e0e0e0;

                }
            }

        }
        .nw-form-body{
            background-color: $bgColorWhite;
            max-width: 1024px;
            // padding: 40px;

            // div:last-child{
            //     // display: flex;
            //     // justify-content: flex-end;
            //     .nw-button-right{
            //         justify-content: right;
            //         // margin-right: -8px !important;
            //     }
            // }

            .custom-calender-input, .custom-dropdown {
                .p-inputtext::placeholder, .p-placeholder{
                    color: #000000 !important;
                }
                
            }

            .nw-product-info{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                width: 100% !important;

                div{
                    width: auto;
                    margin: 3px auto;
                    text-align: center;
                    padding: 0 0 0 0;
                    font-size: 12px;
                    font-weight: bold;
        
                    .p-inputgroup{
                        display: flex;
                        flex-wrap: wrap;
        
                        .p-inputgroup-addon {
                            background-color: #ffffff;
                            padding: 0 0px;
                            border: 0px solid #D2EAFF;
                            width: 100%;
                            height: 22px;
                        }

                        .addon-no-style {
                            background-color: transparent;
                        }

                        .nw-inputgroup-desc {
                            background: #f9fcffcc !important;
                            height: 44px;
                            font-size: 13px;
                            padding: 12px 18px;
                            text-align: left;
                            width: 100%;

                        }

                        .desc-no-style {
                            background: transparent !important;
                        }
                    } 
                }
            }

            // .p-checkbox-box{
            //     background: $inputgroupAddonBGColor;
            //     border: $inputgroupAddonBorder;
            //     border-radius: 0;
            // }

            // .p-checkbox-box.p-highlight{
            //     color: $textColorPrimary;
            // }

            // .p-checkbox-label{
            //     padding-top: 2px;
            // }
        }

        

        .p-inputgroup{
            .p-inputgroup-addon{
                background-color: $inputgroupAddonBGColor;
                height: 44px;
                min-width: 200px;
                font-size: 13px;
                // padding: 12px 18px;
                padding: 12px 5px 12px 18px;
                font-weight: bold;
                text-align: left;
                color: $textColorPrimary;
                border: $inputgroupAddonBorder;
                border-radius: 0;
                text-transform: uppercase;

                span{
                    color: red;
                }

                &.link{
                    text-transform: unset;
                    color: $textColorPrimary;
                    color: #0A4675 !important;
                    font-weight: 400;
                }
            }

            .addon-no-style {
                background-color: transparent;
                border: unset;
            }

            .nw-inputgroup-small-span{
                min-width: 150px;
            }

            .p-inputtextarea, .nw-inputtextarea-label{
                min-height: 107px !important;
            }

            .nw-inputtexteditor-label{
                min-height: 185px;

                .message-reply-to{
                    display: flex;
                    align-items: center;

                    i{
                        color: #0099FF;
                    }
                    .username{
                        background-color: #ffffff;
                        text-transform: capitalize;
                        margin: 6px;
                        padding: 2px 5px;
                        width: 100%;
                        border-radius: 10px;
                        color: #727272;
                        font-weight: 400;

                        .name{
                            color: #727272;
                        }
                        .netiid{
                            color: #727272;
                        }
                    }
                }
            }

            .p-editor-container{
                
                .p-editor-toolbar{
                    .ql-formats{
                        margin-right: 5px;

                        .ql-bold{
                            width: 20px;
                        }

                        .ql-color{

                        }
                    }
                }

                .ql-snow .ql-picker.ql-header {
                    width: 108px;
                }
                
                
            }
            
            .p-editor-container{
                min-height: 185px;
                width: 100%;
                display: grid;
                border-left: $inputgroupAddonBorder;
                border-right: $inputgroupAddonBorder;
                border-top: $inputgroupAddonBorder;
                border-bottom: $inputgroupAddonBorder;
                margin: 0 0 0 -1px;

                .p-editor-toolbar{
                    border: none;
                    // max-height: 52px;
                    background-color: #ECF6FF;
                    padding: 2px 10px;
                    // overflow: auto;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                }

                .p-editor-content{
                    border: none;
                    min-height: 107px;
                    max-height: 107px;

                    .ql-editor{
                        padding: 0 10px;
                        margin: 10px;
                    }
                }
            }

            

            .nw-radiobutton-group{
                background-color: $bgColorWhite;
                font-size: 13px;
                padding: 10px 18px;
                font-weight: normal;
                text-align: left;
                border-radius: 0;

                .p-radiobutton-label{
                    // padding: 10px 5px;
                    vertical-align: middle;
                }
            }

            .input-radio-button{
                display: flex;

                .radio-button-inside{
                    display: flex;
                    align-items: center;
                    margin: 0 20px 0 0;
                    
                    .p-radiobutton{
                        width: 14px;
                        height: 14px;

                        .p-radiobutton-box {
                            @extend .p-checkbox-box;
                            border-color: #2196F3 !important;
                            border-radius: 50% !important;
                            width: 14px;
                            height: 14px;
                        }

                        .p-radiobutton-box.p-highlight {
                            border-color: #2196F3 !important;
                            // background-color: #2196F3 !important;
                            color: #ffffff !important;
            
                            .p-radiobutton-icon{
                                background-color: #2196F3 !important;
                            }
                        }
                    }

                    label{
                        height: auto;
                        margin: 0px 5px 2px 5px;
                    }
                }
                

                i{
                    color: #0099FF;
                }

                .p-radiobutton-label{
                    font-size: 14px;
                    color: #7D7D7D;
                    font-weight: 400;
                }
            }

            

            .p-dropdown, .p-inputtext, .p-calendar .p-inputtext{
                background-color: $bgColorWhite;
                height: 44px;
                font-size: 13px;
                padding: 12px 18px;
                font-weight: normal;
                text-align: left;
                border: $inputgroupAddonBorder;
                border-radius: 0;
                margin: 0 0 0 -1px;
                width: 100%;
                min-width: 0;

                .p-dropdown-label{
                    display: block;
                    border: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    font-weight: normal;
                    // width: 100%;
                    padding: 0;
                    font-size: 14px;
                    color: #180d0d;
                    background: #f5f5f500;
                    height: 23px !important;
                }

                .p-dropdown-trigger{
                    background-color: #ffffff00;
                    min-width: 35px;

                    .p-dropdown-trigger-icon{
                        color: #0E75F8;
                    }
                }

                .p-dropdown-panel{
                    padding: 0; 
                    z-index: 995 !important;

                    .p-dropdown-filter-container{
                        background: $bgColorWhite;
                        padding: 5px;
                    }
                }
            }
            .p-button, .p-button-icon-only{
                width: auto !important;
                min-width: 35px !important;
                background: $bgColorWhite;
                color: $inputgroupAddonIconColor;
                border-top: 1px solid #D2EAFF !important;
                border-bottom: 1px solid #D2EAFF !important;
                border-right: 1px solid #D2EAFF !important;
                border-left: 0px solid #D2EAFF !important;
                // margin-left: -1px;
                margin-right: -1px !important;
                margin-left: -1px !important;

                &.p-button-animation{
                    .p-button-text{
                        // color: red;
                        transition: all .2s;
                    }
                }
            }

            .p-button:last-child{
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
            }

            .p-button:hover, .p-button-icon-only:hover{
                width: auto !important;
                // min-width: 120px !important;
                background: $inputgroupAddonIconColor;
                color: $bgColorWhite;
                // border-top: 1px solid #D2EAFF !important;
                // border-bottom: 1px solid #D2EAFF !important;
                // border-right: 1px solid #D2EAFF !important;
                // border-left: 1px solid #D2EAFF !important;
            }
            .nw-inputgroup-label{
                // background: #f0f0f0 !important;
            }
            .nw-inputgroup-desc{
                background: #f9fcffcc !important;
                height: 44px;
                // min-width: 200px;
                font-size: 13px;
                padding: 12px 18px;
                text-align: left;
                width: 100%;

                &.info-bar{
                    background-color: #DCEEFF !important;
                    border: 1px solid #DCEEFF;
                    // height: 52px;
                    font-size: 13px;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    span{
                        margin: 0 auto;

                        .title{
                            font-size: 13px;
                        }

                        .desc{
                            font-weight: 400;
                        }
                    }

                    .first{
                        background-color: $bgColorWhite;
                        padding: 5px 8px;
                        max-width: 130px;
                    }
                }


                .editor-only-view{
                    display: block;
                    border: unset;
                    height: auto;
                    min-height: unset;

                    &.cms-aboutus{
                        .p-editor-toolbar{
                            background-color: pink;
                        }
                    }
        
                    .p-editor-toolbar{
                        display: none;
                        
                        .ql-formats{
                            margin-right: 5px;
        
                            .ql-bold{
                                width: 20px;
                            }
        
                            .ql-color{
        
                            }
                        }
                    }
        
                    .ql-snow .ql-picker.ql-header {
                        width: 108px;
                    }
        
                    .p-editor-content{
                        border: none;
                        min-height: unset;
                        max-height: unset;
        
                        .ql-editor{
                            padding: 0 10px;
                            margin: 0px;
                        }
                    }
                }
            }

            .desc-no-style {
                background: transparent !important;
            }
        }

        .nw-upload-button{
            .nw-inputtextarea-label{
                span{
                    // width: 100% important;
                    // max-width: 250px;
                }

                span:last-child{
                    color: #8A8A8A;
                }
            }

            .nw-upload-button-inside{
                width: 100% !important;
                max-width: none;
                min-height: 107px;
                display: flex !important;
                background-color: #ffffff;
                font-size: 13px;
                padding: 12px 18px;
                font-weight: normal;
                text-align: left;
                border: 1px solid #D2EAFF;
                border-radius: 0;
                margin: 0 0 0 -1px;

                .p-fileupload-choose{
                    width: 100% !important;
                    max-width: none;
                    display: block !important;
                    margin: 0 0 0 0px !important;
                    background-color: #ffffff !important;
                    color: #0E75F8 !important;
                    font-weight: bold !important;
                    height: 100%;
                    min-width: 160px;
                    min-height: none;
                    border-left: 1px solid #B4C6D2 !important;
                    border-right: 1px solid #B4C6D2 !important;
                    border-top: 1px solid #B4C6D2 !important;
                    border-bottom: 1px solid #B4C6D2 !important;
                    border-style: dashed !important;
                    display: flex !important;
                    justify-content: center;
                    align-items: center;

                    .p-button-icon-left{
                        position: unset;
                        top: 0;
                        margin-top: 0;
                        height: auto;
                        display: none;
                    }

                    .p-button-text{
                        padding: 0 0 2px 12px !important;

                        div{
                            color: #B4C6D2;
                            font-size: 18px;

                            .upload-file-label{
                                color: #8E8E8E;
                                font-size: 14px;
                            }
                        }
                    }
                }

                .p-fileupload{
                    width: 100%;

                    .p-fileupload-buttonbar{
                        padding: 0;
                        background-color: transparent;
                        border: unset;

                        .p-fileupload-choose{
                            padding: 5px 0;
                            // overflow: auto;
                        }

                        // .p-fileupload-choose{
                        //     width: 100% !important;
                        //     max-width: none;
                        //     display: block !important;
                        //     margin: 0 0 0 0px !important;
                        //     background-color: #ffffff !important;
                        //     color: #0E75F8 !important;
                        //     font-weight: bold !important;
                        //     height: 100%;
                        //     min-width: 160px;
                        //     min-height: none;
                        //     border-left: 1px solid #B4C6D2 !important;
                        //     border-right: 1px solid #B4C6D2 !important;
                        //     border-top: 1px solid #B4C6D2 !important;
                        //     border-bottom: 1px solid #B4C6D2 !important;
                        //     border-style: dashed !important;
                        //     display: flex !important;
                        //     justify-content: center;
                        //     align-items: center;
        
                        //     .p-button-icon-left{
                        //         position: unset;
                        //         top: 0;
                        //         margin-top: 0;
                        //         height: auto;
                        //         display: none;
                        //     }
        
                        //     .p-button-text{
                        //         padding: 0 0 2px 12px !important;
        
                        //         div{
                        //             color: #B4C6D2;
                        //             font-size: 18px;
        
                        //             .upload-file-label{
                        //                 color: #8E8E8E;
                        //                 font-size: 14px;
                        //             }
                        //         }
                        //     }
                        // }
                    }
                }

                .image-view-main{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    /* flex-wrap: wrap; */
                    width: 100%;
                    flex: 1 1;
                    margin-right: 10px;

                    .upload-image-view{
                        width: max-content;
                        position: relative;
                        margin: 0 10px;
    
                        .delete-upload-button{
                            position: absolute;
                            right: -10px;
                            min-width: 0 !important;
                            color: red;
                            background-color: transparent;
                            top: -11px;
                            /* border: 0px; */
                            border-top: 0px solid #D2EAFF !important;
                            border-bottom: 0px solid #D2EAFF !important;
                            border-right: 0px solid #D2EAFF !important;
                            border-left: 0px solid #D2EAFF !important;
                            margin-right: 0px !important;
                            margin-left: 0px !important;
    
                            .p-button-text{
                                padding: 0 !important;
                            }
                        }
    
                        i{
                            font-size: 62px;
                        }
    
                        .download-file-button{
                            button{
                                background-color: #004BA0;
                                min-width: 50px !important;
                                position: relative;
                                /* left: -10px; */
                                bottom: 0;
                                width: 100% !important;
                                border: 1px solid blue;
    
                                .p-button-text{
                                    padding: 0 !important;
                                    
                                }
                            }
                        }
                    }

                }

                
            }

            
        }
    }
    .disable{
        background: #F6FCFF !important;
    }
}