.nw-tabview{
    padding: $pad;

    .tabview-panel-height-0{
        .p-tabview-panels{
            min-height: unset !important;
        }
    }

    .p-tabview-top{
        background-color: transparent;
        margin: -1px 4px 10px 4px !important;
        border: unset;
        padding: unset;
    }

    .p-tabview{
        .p-tabview-nav{
            background: none !important;
            .p-unselectable-text{
                a{
                    background-color: $tabviewHeader-a;
                    // border-top-right-radius: 30px !important;
                    color: #96B1C2;
                    border: none;
                    // width: 200px;
                    height: auto;
                    font-size: 16px;
                    font-weight: 400;
                    padding: 2px 20px 10px 20px;
                    font-weight: 700;
                }
                
            }
            .p-unselectable-text:focus{
                box-shadow: none;
            }

            .p-unselectable-text:hover a{
                border-top: none !important;
                border-left: none !important;
                border-right: none !important;
                border-bottom: 2px solid #0099FF !important;
                color: #457B9D !important;
            }
            .p-tabview-selected a{
                background-color: $tabviewHeader-a;
                color: #000000;
                font-weight: 700;
                font-size: 16px;
                border-top: none !important;
                border-left: none !important;
                border-right: none !important;
                border-bottom: 2px solid #0099FF !important;
            }
        }
        .p-tabview-panels{
            border: $borderColor;
            min-height: 86vh;
            // padding: 40px 20px;

            .main-section {
                background-color: #ffffff;
                margin: 0px !important;
                border: none;
                padding: 0px;
                min-height: 89vh;
                
            }

            .nw-form {
                margin: 8px !important;
                border: 0px solid #EBEBEB;
                padding: 0px;
            }
        }
    }
}