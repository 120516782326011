.institute-info, .dialog-view {
  margin-bottom: 10px;
  ul {
    list-style: none;

    li {
      display: flex;
      align-items: center;
      &::before {
        content: "\2022";
        color: #0e75f8;
        margin-right: 10px;
        font-size: 2em;
      }
      span:first-child {
        width: 155px;
        text-transform: uppercase;
        font-weight: bold;
      }
      span:nth-child(2) {
        margin: 0 20px;
      }
      span:nth-child(3) {
        color: #0e75f8;
      }
    }
  }
}

.dialog-view{
  margin-top: 20px;
}

.tag-table table{
  border-collapse: unset;
  text-transform: uppercase;
  
  tbody tr td,
  thead tr th{
    background: #E6F4FF !important;
    border: 5px solid white !important;
  }
}