#myProfile{
    .m-t-30{
        margin-top: 30px;
    }
    .m-b-30{
        margin-bottom: 30px;
    }
    .m-0{
        margin: 0 !important;
    }
    .p-b-75{
        padding-bottom: 75px !important;
    }
    .p-datatable-thead{
        display: none;
    }
    .height-auto{
        height: auto !important;
    }
    .main-section{
        background: transparent;
    }
    .personalInfo-wrapper{
        background: linear-gradient(135deg, #0078FF 0%, #22CDFB 45%,);
        margin: -1em -1em 0em -1em !important;
        padding: 30px 30px 30px 40px;
        .p-grid{
            margin-top: 0px;
        }
    }
    .p-tabview-nav li{
        display: contents;
    }
    .profile-info-wrapper{
        .profile-pic-wrapper{
            display: flex;
            align-items: center;
            height: 124px;
            position: relative;
            .profile-pic{
                height: 124px;
                width: 124px;
                border-radius: 50%;
                border: 5px solid #fff;
            }
            .active{
                position: absolute;
                left: 56px;
                bottom: -5px;
                height: 15px;
                width: 15px;
                border-radius: 50%;
                background: #0078FF;
                border: 2px solid #fff;
                z-index: 1;
            }
            .change-image {
                height: 124px;
                width: 124px;
                background: url('../../assets/images/image-upload.png');
                border-radius: 50%;
                position: absolute;
                display: none;
            }
        }
        .profile-pic-wrapper:hover{
            .change-image {
                display: block;
                cursor: pointer;
            }
        }
        .profile-info{
            margin-top: 30px;
            margin-bottom: 5px;
            border-top: 3px solid rgba(255,255,255,.5);
            border-bottom: 3px solid rgba(255,255,255,.5);
            padding-top: 10px;
            padding-bottom: 10px;
            span{
                width: 100%;
                display: block;
                color: #fff;
            }
            .netiID{
                font-size: 14px;
                margin-bottom: 5px;
            }
            .userName{
                font-size: 18px;
                margin-bottom: 5px;
                font-weight: 700;
            }
            .mobileNumber{
                font-size: 14px;
                margin-bottom: 5px;
            }
            .netimail{
                font-size: 14px;
            }
        }
    }
    .progress-wrapper{
        .qr-wrapper{
            width: 100%;
            display: inline-block;
            margin-bottom: 20px;
            .qr-code{
                height: 72px;
                width: 72px;
            }
        }
        .progress {
            display: inline-flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            background: #f5f5f5;
            padding: 10px 15px;
            border-radius: 40px;
            .p-progressbar{
                width: 100%;
                border-radius: 40px;
                margin-right: 10px;
                position: relative;
                .p-progressbar-label{
                    color: #fff;
                    text-align: left !important;
                    padding-left: 200px;
                }
                .p-progressbar-label::before {
                    position: absolute;
                    left:15px;
                    top: 0px;
                    content: "Your Profile Completeness - ";
                    color: #fff;
                }
                .p-progressbar-value{
                    border-radius: 40px;
                }
            }
            .progress-icon{
                .p-button:enabled:focus{
                    border-color: transparent !important;
                }
                .p-button-text{
                    padding:0;
                }
                .p-button.p-button-icon-only{
                    width: auto;
                    background: transparent;
                    border-color: transparent;
                    color: #0099FF;
                }
            }
        }
    }
    .personal-box-wrapper{
        width: 100%;
        .personal-box-title-wrapper{
            background: #0078FF;
            padding: 12px 23px;
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            .personal-box-title{
                color: #fff;
                font-size: 18px;
                font-weight: 700;
            }
            .edit-button{
                .p-button-text{
                    padding: 0;
                }
                .p-button.p-button-icon-only{
                    width: auto;
                    background: transparent;
                    border-color: transparent !important;
                }
            }
        }
        .personal-box {
            background: #E6F4FF;
            padding:12px 10px 23px 10px;
            table {
                width: 100%;
                tbody{
                    td{
                        padding: 5px 10px;
                        border-bottom: 1px solid #FFFFFF;
                        position: relative;
                        &:first-child::before{
                            content: "";
                            height: 5px;
                            width: 5px;
                            background: #CDDAE4;
                            position: absolute;
                            left: 0px;
                            top: 13px;
                            border-radius: 50%;
                        }
                        &:last-child::before{
                            content: ":";
                            height: 15px;
                            width: 5px;
                            position: absolute;
                            left: 0px;
                            top: 4px;
                        }
                    }
                }
            }
            .click-link{
                color: #0099FF;
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
    .change-password-steps{
        ul{
            padding-left: 0px;
            text-align: center;
            li{
                list-style: none;
                display: inline-flex;
                height: 45px;
                width: 45px;
                border-radius: 50%;
                text-align: center;
                margin-right: 25px;
                align-items: center;
                justify-content: center;
                box-shadow: 10px 1px 20px #EDEDED;
                border: 1px solid #EDEDED;
                position: relative;
                &::after{
                    height: 5px;
                    width: 26px;
                    content: "";
                    background: #EDEDED;
                    position: absolute;
                    left: 43px;
                    box-shadow: 10px 1px 20px #EDEDED;
                }
                &:last-child::after{
                    display: none;
                }
                span{
                    height: 34px;
                    width: 34px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 20px;
                    margin-right: 0px;
                    font-weight: 600;
                    line-height: 0;
                    color: #D5D5D5;
                    &.active{
                        background: #0099FF;
                        color: #fff;
                    }
                }
            }
        }
        
    }
    .change-password-steps-wrapper{
        .nw-search-view{
            border-top: none;
        }
        .verifiation-wrapper{
            text-align: center;
            .title{
                text-align: center;
                color: #0099FF;
                font-weight: 600;
            }
            .verify-code-input{
                display: inline-flex;
                align-items: center;
                justify-content: space-between;
                width: 320px;
                input{
                    width: 42px;
                    padding: 10px;
                }
            }
        }
    }
    .add-button {
        height: 55px;
        width: 55px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #E6F4FF;
        position: fixed;
        bottom: 70px;
        right: 50px;
        button{
            height: 40px;
            width: 40px;
            border-radius: 50%;
            background: #0099FF;
        }
    }
    .responsibility-wrapper{
        .responsibility-title {
            font-weight: 800;
            font-size: 16px;
            color: #0099FF;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        p{
            font-size: 14px;
            line-height: 1.8;
        }
    }
    
    @media screen and (min-width:1030px){
        .profile-info-wrapper{
            display: inline-flex;
            align-items: center;
            .profile-info{
                margin-left: 30px;
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }
        .progress-wrapper{
            .qr-wrapper{
                width: 100%;
                align-items: end;
                justify-content: flex-end;
                display: inline-flex;
            }
            
        }
    }
    
}