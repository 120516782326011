.universal-notice-section{
    // margin: 10px !important;
    padding: 0px;
    border: unset;
    min-height: unset;
    // margin: 10px 4px 0px 4px !important;
    margin: 0 !important;
    border-bottom: 1px solid #50E199;

    .p-grid{
        margin: 0;




        .slider-main{
            padding: 0;
            position: relative;

            // .notice-main{
            //     display: block;
            // }

            // &:hover .notice-main{
            //     display: block;
            // }

            img{
                display: none;
            }

            a{
                
                .header{
                    background-color: #F4FFEF;
                    height: 49px;
                    padding: 0px;
                    text-align: left;
                    font-size: 16px;
                    font-weight: 700;
                    color: $textColorBlack;
                    border: none;
                    border-radius: unset;
                    // display: flex;
                    // align-items: center;
                    // transition: all .5s ease-in-out;
                    padding: 13px 28px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .header-left{
                        i{
                            font-size: 18px;
                            margin-right: 20px;
                            color: #50E199;
                        }

                        .message-title{
                            // color: #000000;
                            font-size: 16px;
                        }
                        .message-details{
                            font-size: 14px;
                        }
                    }
                    .header-right{
                        text-align: right;

                        span{
                            font-size: 14px;
                        }

                        .message-qty{
                            color: #119FFF;
                            margin: 0 20px 0 0;
                        }
                    }
                }
            }

            



            .notice-main{
                display: none;
                background-color: #F4FFEF;
                padding: 0 0 20px 38px;
                position: absolute;
                z-index: 1;
                width: 100%;
                border-bottom: 1px solid #50E199;

                .notice-body{
                    // color: #ffffff;
                    padding: 40px 30px;

                    .nw-inputtexteditor{
                        &.only-view{
                            .p-editor-toolbar{
                                display: none;
                            }

                            .p-editor-content{
                                border: 0px solid;

                                .ql-editor{
                                    padding: 5px 0;
                                }
                            }

                        }
                    } 
                }

                .notice-footer{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0px 28px 5px 28px;

                    .footer-left{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-wrap: wrap;
                        // color: #F7FF00;
                        font-weight: 700;

                        >span{
                            margin-right: 20px;

                            i{
                                // color: #ffffff;
                            }
                        }

                        .download-attachment{
                            a{
                                // color: #F7FF00;
                            }
                        }

                        .date{
                            font-weight: 500;

                            .published-title, .expire-title{
                                letter-spacing: 1.40px;
                            }

                            .published-date, .expire-date{
                                font-weight: 700;
                            }
                        }
                        
                    }

                    .footer-right{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 150px;

                        button{
                            background-color: transparent;
                            border: unset;
                            font-weight: 600;
                            color: #000000;

                            .p-button-icon-left {
                                line-height: 1.2;
                            }
                        }
                    }
                }
            }
            
        }







        // >div{
        //     width: 100%;

        //     .carousel{
        //         .slider-wrapper{
        //             .slider.animated{
        //                 .slide{
        //                     background: transparent;

        //                     &.selected{

        //                     }

        //                     .p-col-12{
        //                         img{
        //                             display: none;
        //                         }
        //                     }
                            
        //                 }
        //             }
        //         }

        //         .control-dots{
        //             // display: none;
        //         }

        //         .thumbs-wrapper{
        //             // display: none;
        //         }
        //     }
        // }

        // .p-col-12{
        //     padding: 0px 0px;

        //     .p-accordion{
        //         position: relative;

        //         .p-accordion-tab{
        //             margin-bottom: 0px;

        //             .p-accordion-header{
        //                 height: 49px;
        //                 box-shadow: 0 1px 0 #50E199;
                        
        //                 a{
        //                     background-color: #F4FFEF;
        //                     height: 100%;
        //                     padding: 0px;
        //                     text-align: left;
        //                     font-size: 16px;
        //                     font-weight: 700;
        //                     color: $textColorBlack;
        //                     border: none;
        //                     border-radius: unset;
        //                     // display: flex;
        //                     // align-items: center;
        //                     // transition: all .5s ease-in-out;
        //                     padding: 13px 28px;
        //                     display: flex;
        //                     align-items: center;
        //                     flex-direction: row-reverse;
        //                     justify-content: space-between;
        
        //                     .p-accordion-toggle-icon{
        //                         color: $textColorBlack !important;
        //                         position: unset;
        //                         right: unset;
        //                         height: 15px;
        //                     }
        
        //                     .p-accordion-header-text{
        //                         margin-left: unset;
        //                         width: 100%;

        //                         .header{
        //                             display: flex;
        //                             justify-content: space-between;
        //                             align-items: center;

        //                             .header-left{
        //                                 i{
        //                                     font-size: 18px;
        //                                     margin-right: 20px;
        //                                     color: #50E199;
        //                                 }

        //                                 .message-title{
        //                                     // color: #000000;
        //                                     font-size: 16px;
        //                                 }
        //                                 .message-details{
        //                                     font-size: 14px;
        //                                 }
        //                             }
        //                             .header-right{
        //                                 text-align: right;

        //                                 span{
        //                                     font-size: 14px;
        //                                 }

        //                                 .message-qty{
        //                                     color: #119FFF;
        //                                     margin: 0 20px 0 0;
        //                                 }
        //                             }
        //                         }
        //                     }
        //                 }
        
        //                 &:not(.p-disabled).p-highlight a {
        //                     // background-color: $datatable-headerBg;
        //                     // border: unset;
        //                     // color: $textColorWhite;
                            
        //                 }
        
        //                 &:not(.p-highlight):not(.p-disabled):hover a {
        //                     font-weight: 700;
        //                 }
        //             }

        //             .p-toggleable-content{
        //                 background-color: #F4FFEF;
        //                 // position: absolute;
        //                 width: 100%;
        //                 z-index: 1;
        //                 padding: 12px 28px 12px 44px;
        //                 box-shadow: 0px 3px 6px 0px #00000030;

        //                 .p-accordion-content{
        //                     border: unset;
        //                     background-color: unset;

        //                     div{
        //                         .notice-body{
        //                             // color: #ffffff;

        //                             .nw-inputtexteditor{
        //                                 &.only-view{
        //                                     .p-editor-toolbar{
        //                                         display: none;
        //                                     }

        //                                     .p-editor-content{
        //                                         border: 0px solid;

        //                                         .ql-editor{
        //                                             padding: 5px 0;
        //                                         }
        //                                     }

        //                                 }
        //                             } 
        //                         }

        //                         .notice-footer{
        //                             display: flex;
        //                             justify-content: space-between;
        //                             align-items: center;
        //                             padding: 20px 0 5px 0;

        //                             .footer-left{
        //                                 display: flex;
        //                                 justify-content: space-between;
        //                                 align-items: center;
        //                                 flex-wrap: wrap;
        //                                 // color: #F7FF00;
        //                                 font-weight: 700;

        //                                 >span{
        //                                     margin-right: 20px;

        //                                     i{
        //                                         // color: #ffffff;
        //                                     }
        //                                 }

        //                                 .download-attachment{
        //                                     a{
        //                                         // color: #F7FF00;
        //                                     }
        //                                 }

        //                                 .date{
        //                                     font-weight: 500;

        //                                     .published-title, .expire-title{
        //                                         letter-spacing: 1.40px;
        //                                     }

        //                                     .published-date, .expire-date{
        //                                         font-weight: 700;
        //                                     }
        //                                 }
                                        
        //                             }

        //                             .footer-right{
        //                                 display: flex;
        //                                 justify-content: space-between;
        //                                 align-items: center;
        //                                 width: 150px;

        //                                 button{
        //                                     background-color: transparent;
        //                                     border: unset;
        //                                     font-weight: 600;
        //                                     color: #000000;

        //                                     .p-button-icon-left {
        //                                         line-height: 1.2;
        //                                     }
        //                                 }
        //                             }
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
    }
    
    
    
}