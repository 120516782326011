@import url('https://fonts.googleapis.com/css?family=Open+Sans');
body {
    font-family: 'Open Sans', sans-serif;
}

.text-bold {
    font-weight: bold !important;
}

.text-center {
    text-align: center !important;
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}
.margin-size-30 {
    margin-left: 20px !important;
}

.font-left-20 {
    font-size: 30px !important;
}

.link {
    color: #fff !important;
    cursor: pointer !important;
}

a.link:hover {
    color: #fff !important;
    text-decoration: underline !important;
}

.scroll-div {
    height: 180px;
    overflow-y: scroll;
}

/* color class name */

.disable {
    background-color: #acaaaa !important;
}

.required {
    color: #FF0F0F !important;
}

.error-message {
    color: #FF0F0F !important;
}

.padding-top {
    padding-top: 5px;
}

.error-message {
    color: #FF3333;
    font-size: 12px;
    /* float: left; */
}

.dialog-data-list {
    padding: 10px;
    border: 1px solid #efefef
}

.dialog-data-list span {
    width: 100%;
    display: inline-block;
    padding: 5px;
    text-align: left;
    border-bottom: 1px solid #eee;
}

.dialog-data-list span:last-child {
    border: none;
}

/* 
.tooltip-error {
    position: absolute;
	top: 20.6em;
	left: 600;
	padding: .5em 1em .4em;
	background-color: #f66;
	border: 1px solid #f00;
	border-radius: 5px;
	color: #fff;
} */

/* .tooltip-error::after,
.tooltip-error::before {
	content: '';
	position: relative;
	top: 100%;
	left: 15px;
	border: solid transparent;
} */

/* .tooltip-error::after {
	margin-left: 1px;
	border-top-color: #f66;
	border-width: 7px;
} */

/* .tooltip-error::before {
	border-top-color: #f00;
	border-width: 8px;
} */

.profile-custom-table-header {
    text-align: center;
    font-size: medium;
    font-weight: bold;
    margin-bottom: 8px;
    margin-top: 8px;
}

.formControl {
    margin-bottom: 10px;
}

.formControl label,
label {
    font-weight: 700;
    margin-bottom: 5px;
    display: inline-block;
}

.text-success {
    color: #4CAF50;
    font-weight: bold
}

.text-info {
    color: #64B5F6;
    font-weight: bold
}

.text-warn {
    color: #FFD54F;
    font-weight: bold
}

.text-danger {
    color: #EF5350;
    font-weight: bold
}

.status-enable {
    background: #34A835;
    border-radius: 5px;
    padding: 5px 10px;
    color: #fff;
    text-align: center;
}

.status-disable {
    background: #e91224;
    border-radius: 5px;
    padding: 5px 10px;
    color: #fff;
    text-align: center;
}

.custom-dialog {
    width: 50vw;
}

.custom-md-dialog {
    width: 40vw;
}

.custom-sm-dialog {
    width: 30vw;
}

.padding-0 {
    padding: 0px !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.padding-bottom-0 {
    padding-bottom: 0px !important;
}

.fixed-width-button {
    max-width: 100px !important;
}

.p-picklist-source-controls {
    display: none !important;
}

.text-success {
    color: #4CAF50;
    font-weight: bold
}

.text-info {
    color: #64B5F6;
    font-weight: bold
}

.text-warn {
    color: #FFD54F;
    font-weight: bold
}

.text-danger {
    color: #EF5350;
    font-weight: bold
}

.custom-div-error-message {
    width: 450px;
    height: 50px;
    position: fixed;
    margin: auto;
    top: 60px;
    /* bottom: 0; */
    left: 0;
    right: 0;
    /* background: #555; */
    /* padding: 10px; */
    /* -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px; */
    z-index: 1004;
}

.custom-div-process-bar {
    width: 100%;
    height: auto;
    position: fixed;
    margin: auto;
    top: 0px;
    /* bottom: 0; */
    left: 0;
    right: 0;
    /* background: #555; */
    /* padding: 10px; */
    /* -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px; */
    z-index: 1004;
}

.custom-div-center {
    width: 450px;
    height: 250px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #555;
    padding: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    z-index: 1004;
}

#custom-msg-red {
    background: rgba(255, 102, 0, 1);
    background: -moz-linear-gradient(top, rgb(255, 102, 0, 1) 0%, rgba(250, 125, 125, 1) 65%, rgba(255, 0, 0, 1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 102, 0, 1)), color-stop(65%, rgba(250, 125, 125, 1)), color-stop(100%, rgba(255, 0, 0, 1)));
    background: -webkit-linear-gradient(top, rgba(255, 102, 0, 1) 0%, rgba(250, 125, 125, 1) 65%, rgba(255, 0, 0, 1) 100%);
    background: -o-linear-gradient(top, rgba(255, 102, 0, 1) 0%, rgba(250, 125, 125, 1) 65%, rgba(255, 0, 0, 1) 100%);
    background: -ms-linear-gradient(top, rgba(255, 102, 0, 1) 0%, rgba(250, 125, 125, 1) 65%, rgba(255, 0, 0, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 102, 0, 1) 0%, rgba(250, 125, 125, 1) 65%, rgba(255, 0, 0, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#fcaa74', endColorstr='#ff0000', GradientType=0);
}

.rainbow-button {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    /* position: fixed;
    top: 80px;
    right: 0; */
    width: calc(15vw + 6px);
    height: calc(4vw + 6px);
    background-image: linear-gradient(90deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F 80%, #00C0FF 100%);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 1.4vw;
    font-weight: bold;
    z-index: 1004;
    font-family: Helvetica, Sans-serif;
}

.rainbow-button:after {
    content: attr(alt);
    width: 15vw;
    height: 4vw;
    border-radius: 5px;
    background-color: #191919;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rainbow-button:hover {
    animation: slidebg 2s linear infinite;
}

@keyframes slidebg {
    to {
        background-position: 15vw;
    }
}

/**Bijoy point book style start**/

/* book design */

#book {
    margin: 30px auto;
    position: relative;
    -webkit-transition-duration: .5s;
    -moz-transition-duration: .5s;
    -ms-transition-duration: .5s;
    -o-transition-duration: .5s;
    transition-duration: .5s;
    -webkit-perspective: 2000px;
    -moz-perspective: 2000px;
    -ms-perspective: 2000px;
    -o-perspective: 2000px;
    perspective: 2000px;
}

.main {
    width: 200px;
    height: 270px;
    margin: auto;
    color: #E1E1E1;
    position: relative;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition-duration: .5s;
    -moz-transition-duration: .5s;
    -ms-transition-duration: .5s;
    -o-transition-duration: .5s;
    transition-duration: .5s;
    /* -webkit-transform:rotate3d(0,1,0,-10deg);
    -moz-transform:rotate3d(0,1,0,-10deg);
    -ms-transform:rotate3d(0,1,0,-10deg);
    -o-transform:rotate3d(0,1,0,-10deg);
    transform:rotate3d(0,1,0,-10deg); */
}

/* = Book Font
  -------------------------------------------------------------- */

.book-front {
    width: 200px;
    height: 270px;
    position: absolute;
    top: 0;
    bottom: 0;
    font-size: 15px;
    text-align: center;
    text-shadow: 0 2px 0 rgba(30, 35, 45, 1);
    /* box-shadow:inset 3px 0 10px rgba(0,0,0,0.1); */
    z-index: 10;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 0% 50%;
    -moz-transform-origin: 0% 50%;
    -ms-transform-origin: 0% 50%;
    -o-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transition-duration: .5s;
    -moz-transition-duration: .5s;
    -ms-transition-duration: .5s;
    -o-transition-duration: .5s;
    transition-duration: .5s;
    -webkit-transform: translate3d(0, 0, 25px);
    -moz-transform: translate3d(0, 0, 25px);
    -ms-transform: translate3d(0, 0, 25px);
    -o-transform: translate3d(0, 0, 25px);
    transform: translate3d(0, 0, 25px);
}

.shishu0 {
    background: #283593 url(./assets/images/bijoyicons/shishu0.png);
}

.shishu1 {
    background: #283593 url(./assets/images/bijoyicons/shishu-shikkah1.png);
}

.shishu2 {
    background: #283593 url(./assets/images/bijoyicons/shishu2.png);
}

.class0-prak {
    background: #283593 url(./assets/images/bijoyicons/class0-prak.png);
}

.class1 {
    background: #283593 url(./assets/images/bijoyicons/class1.png);
}

.class2 {
    background: #283593 url(./assets/images/bijoyicons/class2.png);
}

.class3 {
    background: #283593 url(./assets/images/bijoyicons/class3.png);
}

.class4 {
    background: #283593 url(./assets/images/bijoyicons/class4.png);
}

.book-cover {
    width: 200px;
    height: 270px;
    overflow: hidden;
    background-position: 50% 45%;
    background-repeat: no-repeat;
    background-size: 100px 100px;
    position: absolute;
    top: 0;
    bottom: 0;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 10px 0px 0px 10px;
    background-repeat: no-repeat !important;
    background-size: 80px 80px !important;
}

.book-cover-back {
    width: 200px;
    height: 270px;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #29303A;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotate3d(0, 1, 0, -180deg);
    -moz-transform: rotate3d(0, 1, 0, -180deg);
    -ms-transform: rotate3d(0, 1, 0, -180deg);
    -o-transform: rotate3d(0, 1, 0, -180deg);
    transform: rotate3d(0, 1, 0, -180deg);
}

.title {
    margin: 30px 0 25px 0;
    height: 38px;
    font-size: 20px;
}

.author {
    height: 20px;
    font-size: 20px;
}

.publisher {
    width: 100%;
    position: absolute;
    bottom: 22px;
}

/* = Book Back
  -------------------------------------------------------------- */

.book-back {
    width: 200px;
    background: #29303A;
    position: absolute;
    top: 0;
    bottom: 0;
    color: white;
    -webkit-transform: rotate3d(0, 1, 0, -180deg) translate3d(0, 0, 25px);
    -moz-transform: rotate3d(0, 1, 0, -180deg) translate3d(0, 0, 25px);
    -ms-transform: rotate3d(0, 1, 0, -180deg) translate3d(0, 0, 25px);
    -o-transform: rotate3d(0, 1, 0, -180deg) translate3d(0, 0, 25px);
    transform: rotate3d(0, 1, 0, -180deg) translate3d(0, 0, 25px);
    z-index: 8;
}

/* = Book Bone
  -------------------------------------------------------------- */

/* .book-bone{
    width:50px;
    background:#29303A;
    box-shadow:1px 0 0 #29303A,-1px 0 0 #29303A;
    position:absolute;
    top:0; bottom:0; 
    left: -17px;
    -webkit-transform:rotate3d(0,1,0,-90deg);
    -moz-transform:rotate3d(0,1,0,-90deg);
    -ms-transform:rotate3d(0,1,0,-90deg);
    -o-transform:rotate3d(0,1,0,-90deg);
    transform:rotate3d(0,1,0,-90deg);
  } */

/* = Order
  -------------------------------------------------------------- */

.book-cover:after,
.book-back:after {
    /*  */
    content: "";
    width: 3px;
    position: absolute;
    top: 0;
    left: 10px;
    bottom: 0;
    background: rgba(0, 0, 0, 0.06);
    /* box-shadow:1px 0 3px rgba(255, 255, 255, 0.1); */
    box-shadow: 4px 0 2px rgba(255, 255, 255, 0.2);
}

.book-page,
.book-top,
.book-right,
.book-bottom {
    background: #EEEFE9;
}

.book-right {
    width: 40px;
    height: 260px;
    position: absolute;
    top: 5px;
    right: -18px;
    box-shadow: 0 1px 0 #EEEFE9, 0 1px 0 #EEEFE9;
    -webkit-transform: rotate3d(0, 1, 0, 90deg);
    -moz-transform: rotate3d(0, 1, 0, 90deg);
    -ms-transform: rotate3d(0, 1, 0, 90deg);
    -o-transform: rotate3d(0, 1, 0, 90deg);
    transform: rotate3d(0, 1, 0, 90deg);
}

.book-top {
    width: 200px;
    height: 50px;
    position: absolute;
    top: -20px;
    left: 0;
    -webkit-transform: rotate3d(1, 0, 0, 90deg);
    -moz-transform: rotate3d(1, 0, 0, 90deg);
    -ms-transform: rotate3d(1, 0, 0, 90deg);
    -o-transform: rotate3d(1, 0, 0, 90deg);
    transform: rotate3d(1, 0, 0, 90deg);
}

.book-bottom {
    width: 200px;
    height: 50px;
    position: absolute;
    bottom: -20px;
    left: 0;
    -webkit-transform: rotate3d(1, 0, 0, -90deg) translate3d(0, 0, 0);
    -moz-transform: rotate3d(1, 0, 0, -90deg) translate3d(0, 0, 0);
    -ms-transform: rotate3d(1, 0, 0, -90deg) translate3d(0, 0, 0);
    -o-transform: rotate3d(1, 0, 0, -90deg) translate3d(0, 0, 0);
    transform: rotate3d(1, 0, 0, -90deg) translate3d(0, 0, 0);
}

.book-right,
.book-top,
.book-bottom {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
}

/* = Flip
  -------------------------------------------------------------- */

/* Cover */

.view-cover:hover .main {
    -webkit-transform: rotate3d(0, 1, 0, -20deg);
    -moz-transform: rotate3d(0, 1, 0, -20deg);
    -ms-transform: rotate3d(0, 1, 0, -20deg);
    -o-transform: rotate3d(0, 1, 0, -20deg);
    transform: rotate3d(0, 1, 0, -20deg);
}

.book-button {
    background: rgb(115, 37, 163) !important;
    border-color: rgb(115, 37, 163) !important;
    width: 80%;
    margin-bottom: 5px !important;
    margin-left: 15px !important;
}

.book-button:focus {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 0.1em rgb(161, 62, 223) !important;
    -moz-box-shadow: 0 0 0 0.1em rgb(161, 62, 223) !important;
    box-shadow: 0 0 0 0.1em rgb(161, 62, 223) !important;
}

/**Bijoy Point Book Style End**/

.softwareDownload {
    margin: 15px auto 0;
    min-height: 195px;
}

.softwareDownload img {
    width: 100%;
    height: auto;
    display: inline-block;
    margin: 0 auto;
    padding: 20px 15px 15px;
}

.softwareDownload img.resize {
    width: 100px;
    height: 100px;
}

.softwareDownload .header {
    font-size: 18px;
    margin-bottom: 10px;
    display: block;
}

.center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.devicePassword i.fa-check,
.devicePassword i.fa-lock {
    color: #fff;
    font-size: 18px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    align-items: center;
    display: inline-grid;
    margin-top: 10px;
    margin-bottom: 10px;
}

.devicePassword i.fa-check {
    background: #34A835;
}

.devicePassword i.fa-lock {
    background: #e91224;
}

.devicePassword .fa-phone-square {
    font-size: 40px;
    color: #283593;
    margin: 10px 0px;
}

/**Bijoy Point Book Style End**/

@media only screen and (max-width: 767px) {
    .custom-dialog {
        width: 90vw;
    }
    .custom-sm-dialog {
        width: 90vw;
    }
    .custom-md-dialog {
        width: 90vw;
    }
    .softwareDownload img {
        width: 100%;
        height: auto;
    }
    .softwareDownload img.resize {
        width: 150px;
        height: 150px;
    }
}


#zmmtg-root {
    display: none;
}

#aria-notify-area {
    display: none;
}

