
 $colorPrimary: Blue;
 $colorSecondary: red;

 .main-section.download{
    background-color: #fefefc !important;

/*.........Download LIST START...........*/ 

    .download-software{
        .description{
            background-color: #ffffff;
            flex: 1 1 auto;
            max-width: 625px;
            transition: all 1s;
            display: flex;
            flex-wrap: wrap;
            border: 1px solid #D2EAFF;
            // border-radius: 4px;
            margin: 0 25px 25px 0;
            padding: 0px 0px;
            box-shadow: 0px 8px 25px #00000003;
            max-width: none;
            
            &:hover {
                background-color: #ffffee;
                box-shadow: 0px 8px 25px #{$colorPrimary}17;
            }

            &:hover div .p-button span{
                color: #0099FF;
            }
            
            
            
            .description-inside{
                padding: 5px ;
                display: flex;
                align-items: center;
                
                &:hover .class-name > label{
                    color: $colorSecondary;
                }
                
                &:hover .download-corner-button.ui-button{
                    background-color: $colorPrimary;
                }

                .download-img{
                    display: flex;
                    // justify-content: center;
                    position: relative;
                    

                    &::after{
                        content: "";
                        right: 5px;
                        position: absolute;
                        /* border: 1px solid red; */
                        height: 90%;
                        background-color: #D2EAFF;
                        width: 1px;
                    }
                    
                    .img-div{
                        flex: 0 1 auto;
                        transition: all .5s;
                        width: 100px;
                        height: 85px;
                        // border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 5px;

                        img{
                            max-width: 100%;
                            // height: 100%;
                        }
                    }
                }

                .download-details{
                    label{
                        width: 100%;
                        margin-bottom: 0;
                        font-weight: 400 !important;

                        &:nth-child(1){
                            font-size: 22px;
                            color: #002171;
                        }
                        &:nth-child(2){
                            font-size: 16px;
                            color: #0099FF;
                        }
                        &:nth-child(3){
                            font-size: 12px;
                            color: #B1B1B1;
                        }
                    }

                    
                }
                
                
                
                .p-button{
                    background-color: transparent;
                    border: none;
                    // float: right;
                    width: 100%;

                    span{
                        color: #D2EAFF;
                        font-size: 30px;
                    }
                }
                
    /*                            .download-corner-button.ui-button{
                    background-color: $colorPrimary;
                }*/
            }
        }
        
        
        
    }
}
                
                /*.........Download LIST END...........*/ 



.main-section.bijoy{
    background: #000000 url('../images/bijoy-bg.png') repeat fixed center;
    background-size: cover;
    // margin: -10px -16px !important;
    // border: 0 solid #EBEBEB;
    // padding: 0px;
    // min-height: 92vh;

    .download-book{
        max-width: 1366px;
        margin: 0 auto;
        padding: 30px;

        .download-book-header{
            display: flex;
            align-items: flex-end;
            position: relative;
            margin: 0px 0 20px 0;
            padding: 10px 0 20px 0;

            &::before{
                position: absolute;
                content: "";
                height: 2px;
                border-top: 1px solid #D5D5D5;
                border-bottom: 1px solid #D5D5D5;
                width: 100%;
                bottom: 0;
            }

            & > .text{
                position: absolute;
                background-color: #ffffff;
                padding: 0 0 0 16px;
                top: 88%;
                right: 0;
            }

            .header-title{
                font-size: 30px;
                flex: 1 0 auto;
            }

            .header-image{
                height: 60px;
                width: 60px;

                img{
                    width: 100%;
                }
            }
        }

        .book-main-cover{
            position: relative;
            // margin: 0 auto !important;
            width: 260px;
            // height: 330px;

            .book-img{
                width: 260px;
                height: 330px;
                margin: 0 auto;
                background-position: center !important;
            }

            .shishu0 {
                background: url("../images/bijoyicons/shishu0.png");
            }

            .download-button{
                max-width: 250px;
                margin: 10px auto;
                

                .p-button{
                    margin: 5px 1px;
                    border-radius: unset;
                    border: unset;


                    .p-button-icon-left{
                        font-size: 26px;
                    }

                    .p-button-text{
                        position: relative;
                        width: 200px;
                        float: right;
                        padding: 10px 20px;
                        text-align: left;
                        font-size: 12px;

                        &::before{
                            position: absolute;
                            content: "";
                            height: 66%;
                            width: 1px;
                            background-color: white;
                            left: 8px;
                        }
                    }
                }

                .desktop-button{
                    // background-color: #ED1C24;
                    background-color: #A5A5A5;

                    &:hover{
                        background-color: #ED1C24;
                    }
                }

                .android-button{
                    background-color: #A5A5A5;

                    &:hover{
                        background-color: #3DD256;
                    }
                }
            }

            .main{
                width: 280px;
                height: 330px;

                .book-front{
                    width: 280px;
                    height: 330px;

                    .book-cover{
                        background-size: 100% 100% !important;
                    }

                    .shishu0 {
                        background: url("../images/bijoyicons/shishu0.png");
                    }


                }
            }
        }
    }
}