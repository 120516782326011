body .p-tabview .p-tabview-panels{
    padding: 1em 1em !important;
}
.input-field-overflow{
    white-space: nowrap;
    overflow-y: hidden;
}
    .clearfix {
      display: block;
      content: "";
      clear: both;
    }
    .m-0{
        margin:0px;
    }
    .p-0{
        padding:0px !important;
    }
    .p-l-0{
        padding-left:0px !important;
    }
    .p-r-0{
        padding-right:0px !important;
    }
    .p-b-0{
        padding-bottom:0px !important;
    }
    .m-l-m-35{      
        margin-left: -35px !important;
    }
    .m-r-m-35{
        margin-right: -35px !important;
    }
    .m-b-20{
        margin-bottom: 20px;
    }
    .m-b-0{
        margin-bottom: 0px !important;
    }
    .m-b-10{
        margin-bottom: 10px;
    }
    .m-t-20{
        margin-top: 20px !important;
    }
    .m-t-15{
        margin-top: 15px !important;
    }
    .m-t-8{
        margin-top: 8px !important;
    }
    .m-l-0{
        margin-left: 0px !important;
    }
    .m-r-0{
        margin-right: 0px !important;
    }
    .bg-blue{
        background: #42A5F5;
    }
    .bg-parple{
        background: #7E57C2;
    }
    .bg-green{
        background: #66BB6A;
    }
   .portal-notify-box-wrapper {
        padding: 50px 40px;
        background-image: url('../images/box-wave.png');
        background-position: left bottom;
        background-repeat: no-repeat;
        background-size: contain;
        .overview-title {
            font-size: 22px;
            color: #fff;
            margin-bottom: 10px;
        }
        .overview-numbers {
            font-size: 80px;
            color: #fff;
            line-height: 1;
        }
        .notify-icon {
            text-align: right;
            margin-top: 15px;
        }
   }
   .nw-data-table table .p-datatable-tbody tr td .p-button.p-button.btn-lg{
        width: auto !important;
        &.bg-blue{
            background: #42A5F5 !important;
            border-color: #42A5F5 !important;
        }
        &.bg-parple{
            background: #7E57C2 !important;
            border-color: #7E57C2 !important;
        }
        &.bg-green{
            background: #66BB6A !important;
            border-color: #66BB6A !important;
        }
   }
   .textareaHeight{
       height: 107px;
       overflow: auto;
   }
   .nw-m-t-80{
       margin-top: 80px;
   }
   .nw-m-t-60{
        margin-top: 60px;
    }
    .nw-m-t-45{
        margin-top: 45px;
    }
   .nw-w-100{
       width: 100%;
   }
   .portal-dashboard-profile{
        background: #42A5F5;
        height: 185px;
        background-image: url('../images/notify-wave.png');
        background-position: left bottom;
        background-size: 100% 50px;
        background-repeat: no-repeat;
        position: relative;
    .portal-profile-info-wrapper{ 
        display: inline-flex;
        align-items: center;
        .portal-profile-info{
            margin-top: 50px;
            h1{
                font-size: 25px;
                color: #ffffff;
                font-weight: 700;
                line-height: 1.8;
            }
            h3{
                color: #ffffff;
                font-size: 18px;
                letter-spacing: 1px;
            }
        }
        .active{
            color: #ffffff;
            line-height: 1.8;
            position: relative;
            display: inline-block;
            font-size: 14px;
            font-weight: 800;
            &::after{
                position: absolute;
                height: 10px;
                width: 10px;
                border-radius: 50%;
                background: #47FF06;
                content: "";
                top: 10px;
                right: -18px;
            }
        }
    }
    .portal-profile-image{
        width: 320px;
        img{
            width: 220px;
            height: 220px;
            border-radius: 50%;
            position: absolute;
            top: 20px;
            left: 45px;
        }
    }
   }
   .protal-single-info-box {
        background: #F3F3F3;
        height: 100%;
        padding: 20px 10px;
    }
    .protal-single-info-box{
        .protal-single-info-title{
            position: relative;
            h4{
                font-size: 20px;
                color: #000;
                margin-bottom: 15px;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
                &::after{
                    content: "";
                    height: 5px;
                    width: 100%;
                    background: #42A5F5;
                    position: absolute;
                    left: 0px;
                    bottom: -5px;
                }
            }
        }
        .protal-single-info-content-box {
            margin-top: 35px;
            .protal-single-info-content{
                margin-bottom: 10px;
                h4{
                    color: #42A5F5;
                    font-size: 13px;
                    line-height: 1.8;
                }
                p {
                    color: #585858;
                    font-size: 12px;
                    line-height: 1.8;
                    font-weight: 700;
                    word-break: break-word;
                }
                &:last-child{
                    margin-bottom: 0px;
                }
            }
        }
    }
    .at-glance-single-info-box{
        .protal-single-info-title{
            position: relative;
            display: flex;
            justify-content: space-between;
            padding: 25px;
            background: #F3F3F3;
            align-items: center;
            h4{
                font-size: 26px;
                color: #000;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
                font-weight: normal;
            }
            & h4.inner-title{
                font-size: 16px;
                font-weight: 700;
            }
        }
        .subTitleName {
            padding: 15px 25px;
            position: relative;
            h5{
                 line-height: 1.8;
                 font-size: 15px;
                 font-weight: bold;
                 &.danger{
                    color: #FF0000;
                }
            }
            span{
                line-height: 1.8;
                color: #000000;
                font-size: 14px;
                margin-right: 5px;
            }
            .editDialog{
                width: auto;
                background: transparent;
                border: none;
                color: #2196F3;
                font-size: 18px;
                position: absolute;
                top: 0;
                right: 10px;
                & span{
                    color: #2196F3 !important;
                }
                &:hover{
                    background: transparent;
                }
            }
            
        }
        .protal-single-info-content-box{
            padding: 10px;
            background: #F3F3F3;
        }
        table{
            width: 100%;
            padding: 10px;
            border-collapse: collapse;
            & tr{
                border-bottom: 1px solid #ffffff;
            }
            & td{
                padding: 10px 16px 10px;
                 line-height: 1.8;
                 width: 50%;
                 & span{
                        color: #0099FF;
                        text-decoration: underline;
                        font-weight: 900;
                        cursor: pointer;
                    }
            }
            & td:first-child{
                font-weight: bold;
                font-size: 12px;
                position: relative;
                &::before{
                    content: "";
                    position: absolute;
                    height: 8px;
                    width: 8px;
                    background: #DEDEDE;
                    border-radius: 50%;
                    left: 0px;
                    top: 18px;
                }
            }
            & td:last-child{
                position: relative;
                &::before{
                    content: ":";
                    position: absolute;
                    border-radius: 50%;
                    left: 0px;
                    top: 10px;
                    color: #000000;
                }
            }
        }
        &.payment-refundtable-review{
            .protal-single-info-title{
                background: #697cab;
                padding: 8px;
                h4{
                    font-size: 18px;
                    color: #ffffff;
                }
            }
            .protal-single-info-content-box{
                padding: 0px;
            }
            td {
                padding: 7px 20px ;
                line-height: 1.8;
                width: 50%;
            }
            table td:first-child::before {
                content: "";
                position: absolute;
                height: 6px;
                width: 6px;
                background: #DEDEDE;
                border-radius: 50%;
                left: 7px;
                top: 18px;
            }
            td:last-child::before {
                top: 6px;
            }
        }
    }
    .payment-refundtable-review{
        .protal-single-info-title{
            background: #697cab;
            padding: 8px;
            h4{
                font-size: 18px;
                color: #ffffff;
            }
        }
        .protal-single-info-content-box{
            padding: 0px;
        }
        td {
            padding: 7px 20px ;
            line-height: 1.8;
            width: 50%;
        }
        table td:first-child::before {
            content: "";
            position: absolute;
            height: 6px;
            width: 6px;
            background: #DEDEDE;
            border-radius: 50%;
            left: 7px;
            top: 18px;
        }
        td:last-child::before {
            top: 6px;
        }
    }
    .teacher-portal{
        .portal-dashboard-profile{
            background-color: #66BB6A;
        }
        .protal-single-info-box .protal-single-info-title h4::after {
            content: "";
            height: 5px;
            width: 100%;
            background: #66BB6A;
            position: absolute;
            left: 0px;
            bottom: -5px;
        }
        .protal-single-info-box .protal-single-info-content-box .protal-single-info-content h4{
            color: #66BB6A;
        }
        
    }
    .teacher-portal-menu-component .p-menuitem-icon {
        color: #D1D1D1 !important;
    }
    .teacher-portal-menu-component .menu-item-active .p-menuitem-icon {
        color: #66BB6A !important;
    }
    .from-group-heading{
        font-size: 18px;
        font-weight: 600;
        color: #0099FF;
        margin-left: 30px;
        position: relative;
        & ::before{
            font-family: 'Font Awesome 5 Free';
            position: absolute;
            left: -27px;
            top: 3px;
            content: "\F013";
            height: 10px;
            width: 10px;
            color: #B4C6D2;
        }
    }
    .admin-point{
        .custom-inputswitch{
            .p-inputswitch {
                width: 23px !important;
                height: 16px !important;
                margin-left: 10px;
                margin-right: 10px;
            }
            .p-radiobutton-label{
                font-size: 14px;
                color: #7D7D7D;
                font-weight: 400;
                margin-left: 0px;
            }
            .p-inputswitch-slider{
                height: 16px;
                width: 23px !important;
                background-color: #ffffff !important;
                border: 2px solid #B1B1B1 !important;
                top: 3px;
            }
            .p-inputswitch .p-inputswitch-slider:before{
                background-color: gainsboro !important;
                height: 10px !important;
                width: 10px !important;
                bottom: 1px !important;
            }
            .p-inputswitch-checked .p-inputswitch-slider{
                background: #0099FF !important;
                border: 2px solid #0099FF !important;
            }
            .p-inputswitch-checked .p-inputswitch-slider:before{
                left: -9px !important;
                background-color: #ffffff !important;
            }
        }
        .calculation-box-wrapper{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            
            .calculation-box {
                border: 1px solid #D2EAFF;
                border-radius: 5px;
                position: relative;
                display: block;
                width: 100%;
            }
            .plusSign::after{
                position: absolute;
                right: -21px;
                top: 10px;
                content: "\F067";
                height: 26px;
                width: 26px;
                background: #ffffff;
                color: #3DD256;
                font-weight: bolder;
                text-align: center;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: 'Font Awesome 5 Free';
                z-index: 1;

            }
            .calculation-box-inner-wrapper {
                padding: 10px;
            }
            .calculation-box-title {
                background: #ECF6FF;
                padding: 12px 18px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                .p-inputgroup-addon{
                    font-size: 13px;
                    padding: 0;
                    font-weight: bold;
                    text-align: left;
                    color: #0A4675;
                    border-radius: 0;
                    text-transform: uppercase;
                    & span {
                        color: red;
                    }
                }
            }
            .calculation-box-amount {
                font-size: 18px;
                font-weight: 700;
                color: #66B2E5;
                padding-left: 15px;
                width: 180px;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-top: 25px;
                position: relative;
                &::before{
                    position: absolute;
                    left: 0px;
                    top: 24px;
                    content: "";
                    height: 14px;
                    width: 10px;
                    background-image: url('../images/bluetksign.png');
                    background-repeat: no-repeat;
                }
                
            }
            .calculation-box-field{
                position: relative;
                input{
                    background: transparent;
                    padding: 12px 18px;
                    border: 1px solid #D2EAFF;
                    width: 85%;
                    position: relative;
                }
                .input-label{
                    font-size: 14px;
                    color: #7D7D7D;
                    line-height: 3;
                    font-weight: 800;
                }
                &.full-width{
                    input{
                        width: 94%;
                    }                    
                }
                &.idSearchField{
                    position: relative;
                    input{
                        width: 100%;
                    } 
                    button{
                            position: absolute;
                            width: auto;
                            right: 11px;
                            bottom: 6px;
                            background: transparent;
                            color: #2196F3;
                            font-weight: 700;
                            border-color: #2196F3;
                    }
                    button:hover{
                        background-color: #2196F3 !important;
                        color: #ffffff !important;
                        transition: 0.2s;
                    }
                    .info-tooltips-wrapper{
                        padding: 23px 30px;
                        position: absolute;
                        right: 170px;
                        top: 0px;
                        border-radius: 5px;
                        -webkit-box-shadow: -1px 0px 56px 10px rgba(236,236,236,0.8);
                        -moz-box-shadow: -1px 0px 56px 10px rgba(236,236,236,0.8);
                        box-shadow: -1px 0px 56px 10px rgba(236,236,236,0.8);
                        background: #fff;
                        width: fit-content;
                        border: 1px solid #ECECEC;
                        .info-tooltips{
                            .info-tooltips-title{
                                font-size: 18px;
                                font-weight: 700;
                                color: #0099FF;
                                padding-left: 40px;
                                position: relative;
                                &::before{
                                    position: absolute;
                                    left: 10px;
                                    top: 2px;
                                    content: "\f00c";
                                    height: 15px;
                                    width: 15px;
                                    color: #3DD256;
                                    font-family: 'Font Awesome 5 Free';
                                }
                            }
                        }
                        .nw-search-view {
                            border-top: none;
                            padding: 0;
                            margin: 0;
                            &::after{
                                position: absolute;
                                right: -9px;
                                top: 58px;
                                background: #fff;
                                content: "";
                                height: 15px;
                                width: 15px;
                                transform: rotate(45deg);
                                border-right: 1px solid #ECECEC;
                                border-top: 1px solid #ECECEC;
                            }
                        }
                    }
                    .error-tooltips-wrapper {
                        background: #fff;
                        position: absolute;
                        right: 170px;
                        top: 33px;
                        border-radius: 5px;
                        border: 1px solid #ECECEC;
                        -webkit-box-shadow: -1px 0px 56px 10px rgba(236,236,236,0.8);
                        -moz-box-shadow: -1px 0px 56px 10px rgba(236,236,236,0.8);
                        box-shadow: -1px 0px 56px 10px rgba(236,236,236,0.8);
                        .error-tooltips{
                            padding: 23px 30px;
                            position: relative;
                            display: inline-flex;
                            display: inline-flex;
                            align-items: center;
                            color: #FF62B0;
                            span{
                                font-size: 18px;
                                font-weight: 700;
                                color: #FF5EB4;
                            }
                            i{
                                margin-right: 5px;
                            }
                            .msgClose {
                                right: 5px;
                                top: 5px;
                            }
                        }
                        .error-tooltips::after{
                            position: absolute;
                            right: -8px;
                            top: 27px;
                            background: #fff;
                            content: "";
                            height: 15px;
                            width: 15px;
                            transform: rotate(45deg);
                            border-right: 1px solid #ECECEC;
                            border-top: 1px solid #ECECEC;
                        }
                    }
                }
            }
            
            .inputTkSign, .percentSign{
                position: relative;
            }
            .inputTkSign::after{
                position: absolute;
                right: 7px;
                bottom: 15px;
                content: "";
                height: 14px;
                width: 10px;
                background-image: url('../images/darkbluetksign.png');
                background-repeat: no-repeat;
            }
            .percentSign::after{
                position: absolute;
                right: 7px;
                bottom: 18px;
                content: "%";
                height: 14px;
                width: 10px;
                font-weight: 600;
                color: #004BA0;
            }
        }
        .calculation-total-box {
            height: 201px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #D2EAFF;
            border-radius: 5px;
            background: #ECF6FF;
            position: relative;
            .calculation-box-title{
                padding: 12px 18px 5px;
            }
            .calculation-box-amount{
                color: #0099FF;
                padding-top: 0px;
                position: relative;
                text-align: center;
                width: fit-content;
                margin: 0 auto;
                max-width: 150px;
                &::before{
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    content: "";
                    height: 14px;
                    width: 10px;
                    background-image: url('../images/darkbluetksign.png');
                    background-repeat: no-repeat;
                }
                &.no-tkSign{
                    padding-left: 0px;
                    
                }
                &.no-tkSign::before{
                    display: none;
                }
            }
            .top-position{
                position: absolute;
                top: 20px;
                left: 20px;
                .calculation-box-amount{
                    color: #66B2E5;
                    padding-top: 0px;
                    margin: 0 ;
                    &::before{
                        background-image: url('../images/bluetksign.png');
                    } 
                }                
            }
            .left-percent{
                text-align: center;
                position: absolute;
                width: 100%;
                left: 0;
                bottom: 20px;
                font-weight: 900;
                color: #FF5EB4;
            }
        }
        .product-info-dialog{
            .nw-search-view{
                padding-top: 10px;
            }
            .from-group-heading{
                color: #83A4B9;
            }
            .p-datatable .p-datatable-header{
                background: transparent !important;
            }
            .p-datatable-header div span{
                p{
                    padding: 0 5px;
                }
                color: #004BA0 !important;
                display: inline-flex;
            }

        }
    }
    .at-a-glance{
        padding-top: 15px;
        padding-bottom: 15px;
        height: auto;
        background-image: url('');
        background-position: left bottom;
        background-size: 100% 50px;
        background-repeat: no-repeat;
        .portal-profile-info-wrapper{
            width: 100%;
            justify-content: flex-start;
            height: 100%;
            padding-left: 40px;
        }
        .portal-profile-image {
            width: auto;
            & img{
                position: relative;
                width: 125px;
                height: 125px;
                top: auto;
                left: auto;
            }
            &.user-active{
                position: relative;
            }
            &.user-active::after{
                position: absolute;
                height: 10px;
                width: 10px;
                background: #47FF06;
                border-radius: 50%;
                content: "";
                right: 4px;
                bottom: 30px;
            }
        }
        .portal-profile-info {
            margin-left: 40px;
            margin-top: 0px !important;
            padding: 5px 0px;
            border-top: 2px solid #fff;
            border-bottom: 2px solid #fff;
            h1{
                line-height: unset;
                
            }
            h3{
                line-height: 1.8;
            }
            @media screen and (min-width: 980px){ 
                h1{
                    line-height: unset;
                    width: 250px;
                    white-space: break-spaces;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                }
                h3{
                    line-height: 1.8;
                    white-space: break-spaces;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .basic-detais-wrapper{
            margin-left: 40px;
            table{
                tr{
                    td{
                        span{
                            display: block;
                            width: 100%;
                            padding-right: 20px;
                        }
                        .info-header{
                            font-size: 12px;
                            color: #EEFF00;
                            margin-bottom: 10px;
                        }
                        .info-content{
                            font-size: 14px;
                            font-weight: bold;
                            color: #ffffff;
                        }
                    }
                }
            }
        }
        .top-btn-group {
            position: absolute;
            top: 10px;
            right: 10px;
            button{
                background: transparent;
                border: none;
                &:hover{
                    background: transparent;
                    border: none;
                }
            }
        }
    }
    .dialog-radio-btn{
        display: inline-flex;
        .p-radiobutton-label{
            margin-top:0px;
            margin-bottom: 0px;
        }
    }

    .partner-profile-wrapper{
        .p-col-12{
            margin: 0;
        }
        .profile-pic{
            
            img{
                height: auto;
                width: 100%;
                border-radius: 50%;
                padding: 5px;
                -webkit-box-shadow: -4px -5px 50px 3px rgba(112, 112, 112, 0.3);
                -moz-box-shadow: -4px -5px 50px 3px rgba(112, 112, 112, 0.3);
                box-shadow: -4px -5px 50px 3px rgba(112, 112, 112, 0.3);
                background: #0199ff;
                max-height: 205px;
                max-width: 205px;
                border: 5px solid #fff;
            }
            &.no-radius{
                img{
                    border-radius:0;
                }
            }
        }
        .p-fieldset{
            border:1px solid #E2F1FF;
            .p-fieldset-legend-text{
                font-size: 16px;
                font-weight: 800;
                color: #6095BF;
            }
        }
        .p-fieldset-content{
            padding: 10px !important;
        }
        .profile-name {
            font-size: 18px;
            font-weight: 600;
            display: block;
        }
        .profile-position {
            font-weight: 600;
            font-size: 14px;
            color: #0099FF;
        }
        .detailslist-wrapper{
            ul {
                list-style: none;
                display: inline-flex;
                flex-wrap: wrap;
                margin-top: 25px;

                li{
                    font-size: 14px;
                    padding-right: 16px;
                    display: inline-flex;
                    align-items: center;
                    margin-bottom: 15px;
                    i{
                        color: #0099FF;
                        font-size: 16px;
                        padding-right: 10px;
                        display: inline-flex;
                        align-items: center;
                    }
                }

            }
        }
        
    }
    .nw-form.partner-profile-wrapper div .p-inputgroup .p-inputgroup-addon{
        min-width: 110px;
    }
    .nw-form.partner-profile-wrapper div .p-inputgroup .p-button{
        min-width: 120px !important;
        color: #ffffff;
    }
    .gradient-parpel{
        background: rgb(77,27,217);
        background: linear-gradient(0deg, rgba(77,27,217,1) 0%, rgba(126,87,194,1) 71%);
    }
    .partner-at-a-glance{
        &.portal-dashboard-profile{
            .netiWorld-DashBoard-wrapper{
                .wallet-icon{
                    height: 50px;
                    width: 45px;
                }
                .notify-box-title{
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    padding-right: 50px;
                    h1{
                        margin-left: 10px;
                        font-weight: 700;
                        margin:0px;
                        margin-left: 10px;
                        margin-top: 5px;
                    }
                    p{
                        margin-left: 10px;
                        font-size: 12px;
                        color: #EEFF00;
                    }
                }
            }
        }
        .at-glance-single-info-box {
            .protal-single-info-title{
                background: rgb(77,27,217);
                background: linear-gradient(0deg, rgba(77,27,217,1) 0%, rgba(126,87,194,1) 71%);
                h4{
                    color: #ffffff;
                }
                .editDialog{
                    background: transparent;
                    font-size: 22px;
                    border: none;
                    position: absolute;
                    right: 20px;
                }
                .editDialog:hover{
                    background: transparent;
                }
            }
            .protal-single-info-content-box{
                margin-top: 10px;
                .inner-title{
                    margin-bottom: 10;
                    p{
                        font-weight: bold;
                        font-size: 14px;
                        border: 1px solid #000000;
                        display: inline-block;
                        padding: 2px 5px;
                    }
                    span{
                        color: #0099FF;
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
            }
        }
        .wallet-information-wrapper{
            padding: 25px 32px;
            .color-yellow{
                color: #EEFF00 !important;
            }
            .wallet-title{
                h3{
                    color: #ffffff;
                    font-size: 24px;
                    font-weight: 300;
                    margin-bottom: 10px;
                }
            }
            .wallet-information{
                margin-bottom: 10px;
            }
            hr{
                border-color: #EEFF00;
            }
            .wallet-info {
                margin-top: 10px;
                margin-bottom: 10px;
                display: inline-flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }
            .wallet-info{
                span{
                    font-weight: 600;
                    font-size: 16px;
                    color: #ffffff;
                    text-shadow: 2px 3px rgba(0,0,0,0.5);
                }
            }
        }
    }
    
    
    
   @media screen and (max-width:639px){
    .portal-dashboard-profile{
        height: 100%;
        .portal-profile-image {
            img {
                width: auto;
                height: auto;
                border-radius: 50%;
                position: relative;
                top: 2px;
                left: 45px;
            }
        }
    }
    .portal-dashboard-profile {
        .portal-profile-info-wrapper{
            display: block;
            .portal-profile-info{
                margin-top: 20px;
                margin-left: 20px;
            }
        }
    }
    .portal-menu {
        position: absolute;
        right: 8px;
        top: 74px;
        z-index: 1;
        padding: 5px;
    }
    .p-xs-12{
        width: 100%;
    }

   }
   @media screen and (max-width:767px){
        .box-second-table{
            &.p-l-0{
                padding-left: 0.5em !important;
            }
            &.p-r-0{
                padding-left: 0.5em !important;
            }
        }
        

   }
   @media screen and (min-width:1020px) and (max-width:1757px){
    .due-bill-info-table-responsive {
        .table-middle-col{
            .col-number{
                width: 100px;
            }
        }
    }
   }
   @media screen and (max-width:1020px){
    .at-a-glance{
        height: auto;
        .portal-dashboard-profile {
            .portal-profile-info-wrapper{
                display: block;
                .portal-profile-info{
                    margin-top: 20px;
                    margin-left: 20px;
                }
            }
        }
        
        .portal-profile-info-wrapper{
            padding-left: 0px;
            display: block;
        }
        .portal-profile-image{
            text-align: center;
            margin-bottom: 10px;
            margin-top: 10px;
            img{
                width: 125px;
                height: auto;
                left: 0px;
            }
        }
        .portal-profile-info {
            margin-left: 0px !important;
            margin-bottom: 10px;
            margin-top: 10px;
        }
        .portal-profile-info-wrapper{
            height: auto;
        }
        .basic-detais-wrapper{
            margin-left: 0px;
            margin-bottom: 10px;
            margin-top: 10px;
        }
    }
   }
   .p-relative{
       position: relative;
   }
   .custom-tooltips{
        position: absolute;
        background: #E91E63;
        color: #fff;
        padding: 10px 20px;
        top: -23px;
        left: 30%;
        z-index: 1;
        & div{
            position: relative;
            &::after{
                height: 15px;
                width: 15px;
                background: #E91E63;
                content: "";
                position: absolute;
                left: 40%;
                bottom: -18px;
                transform: rotate(45deg);
            }
        }
    }
    .emptyDropdown{
        position: absolute;
        right: 7px;
        top: 15px;
        color: #0E75F8;
        z-index: 1;
    }
    .p-inputgroup-flex{
        align-items: center;
    }
    .inline-percent {
        color: #004BA0;
        padding-left: 20px;
        padding-right: 20px;
    }
    .inline-percent-taka{
        position: relative;
        background: #DCF1FF;
        color: #0099FF;
        padding: 5px 20px;
        font-weight: 700;
        font-size: 18px;
        border-radius: 5px;
        &::before{
            position: absolute;
            left: 7px;
            top: 4px;
            content: "";
            height: 14px;
            width: 10px;
            background-image: url('../images/bluetksign.png');
            background-repeat: no-repeat;
        }
    }
    
    .revenue-wrapper{
        margin-bottom:-8px;
        width: 100%;
        position: relative;
        
        .revenue-box{
            padding: 15px;
            border: 1px solid #D2EAFF;
            height: 105px;
            display: flex;
            align-items: center;
            position: relative;
            border-radius: 5px;
            .revenue-title {
                color: #002171;
                font-weight: 700;
                line-height: 1.8;
                 text-transform: uppercase;
            }
            .revenue-price-amount{
                font-weight: 700;
                padding-left: 10px;
                color: #66B2E5;
                font-size: 18px;
                position: relative;
                line-height: 1.8;
            }
            .revenue-price-amount::before{
                position: absolute;
                left: -2px;
                top: 4px;
                content: "";
                height: 14px;
                width: 10px;
                background-image: url('../images/bluetksign.png');
                background-repeat: no-repeat;
            }
            .revenue-percentage {
                position: absolute;
                height: 60px;
                width: 60px;
                border-radius: 50%;
                color: #fff;
                background: #2AC77D;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                font-size: 18px;
                right: 15px;
                top: 25px;
            }
        }
    }
    
    .msgClose{
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        &::before{
            content: "\E90B";
            font-family: 'primeicons';
            height: 10px;
            width: 10px;
            font-size: 18px;
            color: #C4E5FF;
            position: relative;
        }
    }
    .formTkSign{
        padding-left: 10px;
        position: absolute;
        margin-left: 20px;
        height: 44px;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 900;
        color: #66B2E5;
        right: -90px;
        &::before{
            position: absolute;
            left: -2px;
            top: 10px;
            content: "";
            height: 14px;
            width: 10px;
            background-image: url('../images/bluetksign.png');
            background-repeat: no-repeat;
        }
    }
    .percentageNotify {
        height: 76px;
        width: 95px;
        background: #2AC77D;
        color: #fff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 12px !important;
        text-transform: uppercase;
        position: absolute;
        right: 0px;
        bottom: -85px;
        & div{
            position: relative;
        }
        & div::before{
                content: "";
                position: absolute;
                left: 15px;
                top: -20px;
                height: 15px;
                width: 15px;
                background-color: #2AC77D;
                transform: rotate(45deg);
            }
        & div span:first-child{
            display: block;
            font-size: 25px;
            font-weight: 700;
            text-align: center;
        }
    }
    .p-inputgroup-sm{
        &.p-inputgroup{
            display: block !important;
        }
    }
    .new-field-add-wrapper{
            position: relative;
            margin-top: 20px;
            margin-bottom: 20px;
            margin-left: 30px;
            padding-left: 20px;
            .new-field-add-button{
                position: relative;
                cursor: pointer;
                & span{
                    font-size: 13px;
                    font-weight: 700;
                    color: #0E75F8;
                    margin-left: 30px;
                    &::before{
                        font-family: 'Font Awesome 5 Free';
                        content: "\f055";
                        position: absolute;
                        left: 0px;
                        top: -1px;
                        font-size: 22px;        
                    }
                }
            }
        
        &::before{
            content: "";
            position: absolute;
            left: -10px;
            top: 18px;
            height: 1px;
            width: 20px;
            background-color: #BCE0FF;
        }
        &::after{
            content: "";
            position: absolute;
            left: -10px;
            top: -27px;
            height: 45px;
            width: 1px;
            background-color: #BCE0FF;
        }
    }
    .customPercentageTable{
        padding: 0 54px;
        margin-top: -60px;
        .p-datatable-thead > tr > th{
            text-align: left !important;
            border: none !important;
            padding-bottom: 15px !important;
        }
        .p-paginator{
            display: none !important;
        }
        .p-datatable-tbody > tr{
            background: transparent !important;
            td{
                color: #585858 !important;
            }
        }
    }

    .product-info-dialog{
        .nw-search-view{
            &.no-border{
                border-top:none;
            }
        }
    }
    .nw-dialogue-data-table{

    }
    .indiana-scroll-container{
        border-left: 1px solid #D2EAFF;
        border-right: 1px solid #D2EAFF;
    }

    .revenue-wrapper{
        padding-left: 208px !important;
        &::before{
            content: "";
            position: absolute;
            left: 50px;
            top: 50px;
            height: 1px;
            width: 158px;
            background-color: #BCE0FF;
            transition: width 5s;
        }
        &::after{
            content: "";
            position: absolute;
            left: 50px;
            top: -8px;
            height: 59px;
            width: 1px;
            background-color: #BCE0FF;
            transition: height 5s;
        }
    }
    .p-inputgroup-sm{
        width: 400px;
        position: relative;
        &.p-inputgroup{
            display: flex !important;
        }
    }
    .percentageNotify {
        position: absolute;
        left: 420px;
        top: -17px;
        & div::before{
            left: -30px;
            top: 15px;
        }
    }
    
    .divisionAttendenceWrapper{
        background: rgb(151,195,253);
        background: linear-gradient(0deg, rgba(151,195,253,1) 0%, rgba(194,153,248,1) 57%);
        width: 100%;
        display: block;
        .map-wrapper{
            width: 100%;
            position: relative;
            & > div > div{
                margin: 0 auto;
                img{
                    position: relative !important;
                }
            }
            .map-image-wrapper{
                position: relative;
            }
            .totalInfo-wrapper{
                padding: 45px 35px;
                background: rgba(126,66,181,0.5);
                position: relative;
                width: 100%;
                bottom: 0;
                .totalInfo-details{
                    h1{
                        color: #FFF700;
                        font-size: 20px;
                        text-transform: uppercase;
                        line-height: 1.8;
                        font-weight: 600;
                    }
                    h4{
                        font-size: 15px;
                        color: #ffffff;
                        font-weight: 600;
                    }
                    .dot-1{
                        position: relative;
                        &::before{
                            content: "";
                            height: 14px;
                            width: 14px;
                            background: #11FFEF;
                            position: absolute;
                            left: -20px;
                            top: 3px;
                        }
                    }
                    .dot-2{
                        position: relative;
                        &::before{
                            content: "";
                            height: 14px;
                            width: 14px;
                            background: #FF346B;
                            position: absolute;
                            left: -20px;
                            top: 3px;
                        }
                    }
                    .dot-3{
                        position: relative;
                        &::before{
                            content: "";
                            height: 14px;
                            width: 14px;
                            background: #00A8FF;
                            position: absolute;
                            left: -20px;
                            top: 3px;
                        }
                    }
                }
            }

                .mapImage{
                    position: relative; width: 540px; height: 469px;
                }
                
                .mapPin{
                    position: absolute;
                    height: 44px;
                    width: 34px;
                    background: url('../images/map-pin.png');
                    top: 157px;
                    left: 189px;
                    z-index: 1;
                    transform:translate(-67%, -66%);
                    background-repeat: no-repeat;
                    background-position: center;
                    cursor: pointer;
                    span{
                        height: 44px;
                        width: 34px;
                        background-color: #000;
                    }
                }
                .map-details-wrapper{
                    border-radius: 5px;
                    .map-details{
                        display: flex;
                        min-width: 280px;
                        .map-data-left{
                            width: 50%;
                            padding: 15px 10px 15px 30px;
                            text-align: center;
                            margin-right: 15px;
                            div{
                                height: 44px;
                                width: 34px;
                                background: url('../images/map-pin-details.png');
                                background-repeat: no-repeat;
                                background-position: center;
                                margin: 0 auto;
                            }
                            p{
                                font-size: 20px;
                                font-weight: bold;
                            }
                        }
                        .map-data-right{
                            width: 50%;
                            padding: 15px 30px 15px 10px;
                            ul{
                                padding-left: 0px;
                                li{
                                    list-style: none;
                                    font-size: 10px;
                                    line-height: 1.8;
                                    color: #0078FF;
                                    font-weight: bold;
                                    position: relative;
                                    span{
                                        color: #000;
                                    }
                                    
                                    &:nth-child(3)::before{
                                        height: 7px;
                                        width: 7px;
                                        content: "";
                                        position: absolute;
                                        left: -12px;
                                        top: 7px;
                                        background-color: #11FFEF;
                                    }
                                    &:nth-child(4)::before{
                                        height: 7px;
                                        width: 7px;
                                        content: "";
                                        position: absolute;
                                        left: -12px;
                                        top: 7px;
                                        background-color:  #FF346B;
                                    }
                                    &:nth-child(5)::before{
                                        height: 7px;
                                        width: 7px;
                                        content: "";
                                        position: absolute;
                                        left: -12px;
                                        top: 7px;
                                        background-color:  #00A8FF;
                                    }
                                }
                            }
                        }
                        .closeBtn {
                            color: #FF346B;
                            font-size: 15px;
                            position: absolute;
                            right: 5px;
                            top: 5px;
                            cursor: pointer;
                        }
                    }
                }
                // @keyframes bounce-1 {
                //     100% {top: 157px;}
                //     50% {top: 150px;}
                // }
                // @keyframes bounce-2 {
                //     100% {top: 92px;}
                //     50% {top: 82px;}
                // }
                // .mapIcon-1:hover{
                //     animation-name: bounce !important;
                //     animation-delay: 0.1s !important;
                //     animation-duration: 2.5s !important;
                //     // animation-fill-mode: both !important;
                //     // animation-timing-function: linear !important;
                //     // animation-iteration-count: infinite !important;
                // }
                // .mapIcon-2:hover{
                //     animation-name: bounce-2 !important;
                //     animation-delay: 0.1s !important;
                //     animation-duration: 2.5s !important;
                //     // animation-fill-mode: both !important;
                //     // animation-timing-function: linear !important;
                //     // animation-iteration-count: infinite !important;
                // }
                
                // @keyframes bounce { 
                //     0%, 20%, 40%, 60%, 80%, 100% {transform: translateY(0);}
                //     50% {transform: translateY(-5px);}
                // } 
            
        }
        .map-menu-wrapper{
            width: 100%;
            background: #000;
            background: rgba(80,5,136,0.6);
            .title-wrapper{
                padding: 25px 25px 15px;
                display: flex;
                align-items: center;
                .title-icon{
                    font-size: 40px;
                    color: #fff;
                    margin-right: 15px;
                }
                .title{
                    margin: 0px;
                    height: auto;
                    .main-title h1{
                        font-size: 20px;
                        margin: 0;
                        color: #fff;
                        margin-bottom: 5px;
                        position: relative;
                        &::after{
                            content: "";
                            position: absolute;
                            left: 0;
                            bottom: -3px;
                            height: 2px;
                            width: 15px;
                            background: #FFF700;
                        }
                    }
                    .sub-title{
                        color: #fff;
                        font-size: 15px;
                    }
                }
            }
            .calender-wrapper{
                padding: 0 20px;
                margin-top: 10px;
                .emptyDropdown{
                    top: 10px;
                    color: #7A44B2;
                }
                .p-calendar .p-datepicker:before, .p-calendar .p-datepicker:after{
                    left: 75% !important;
                }
                .p-inputtext{
                    border: 2px solid #E6D4FC;
                    border-radius: 5px;
                    color: #7944B1;
                }
                .p-inputtext:focus{
                    border: 2px solid #E6D4FC !important;
                    box-shadow: inset 0 0 0px #E6D4FC !important;
                }
                .p-inputtext::placeholder{
                    color: rgba(12,68,177, 0.8) !important;
                }
                .p-datepicker-calendar tbody tr td.p-datepicker-today span{
                    background: #7944B1 !important;
                }
                .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container .p-datepicker-calendar tbody tr td span:hover{
                    color: #7944B1 !important;
                    border: 1px solid #7944B1 !important;
                }
                .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-header .p-datepicker-title .p-datepicker-month, .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-header .p-datepicker-title .p-datepicker-year{
                    border-color: #7a44b2 !important;
                    color: #7a44b2 !important;
                }
            }
            .radio-button-wrapper{
                padding: 20px 20px 10px;
                & > div{
                    margin-bottom: 15px;
                }
                .p-radiobutton-icon {
                    background-color: #664DA0 !important;
                }
                .p-radiobutton-box.p-highlight{
                    border-color: #C299F8;
                    background-color: #ffffff;
                    color: #ffffff;
                }
                .p-radiobutton-box:hover, .p-radiobutton-box.p-highlight:not(.p-disabled):hover{
                    border-color: #ffffff;
                    background-color: #C299F8;
                }
                .p-radiobutton-label {
                    color: #ffffff;
                    font-size: 13px;
                    font-weight: 600;
                    margin-left: 10px;
                }
            }
            .attendence-filters{
                padding: 25px;
                background: rgba(32,22,107,0.7);
                margin-bottom: 15px;
                .color-name-1 {
                    height: 16px;
                    width: 17px;
                    background: #11FFEF;
                    margin-right: 10px;
                }
                .color-name-2 {
                    height: 16px;
                    width: 17px;
                    background: #FF346B;
                    margin-right: 10px;
                }
                .color-name-3 {
                    height: 16px;
                    width: 17px;
                    background: #00A8FF;
                    margin-right: 10px;
                }
                .custom-inputswitch{
                    display: flex;
                    margin-bottom: 13px;
                }
                .custom-inputswitch:last-child{
                    margin-bottom: 0px;
                }
                .p-inputswitch {
                    width: 23px !important;
                    height: 16px !important;
                    margin-left: 10px;
                    margin-right:0px;
                }
                .attendaence-cat {
                    color: #fff;
                    font-weight: 700;
                    font-size: 12px;
                    min-width: 75px;
                }
                .p-radiobutton-label{
                    font-size: 14px;
                    color: #7D7D7D;
                    font-weight: 400;
                    margin-left: 0px;
                }
                .p-inputswitch-slider{
                    height: 16px;
                    width: 23px !important;
                    background-color: #ffffff !important;
                    border: 2px solid #ffffff  !important;
                    top: 3px;
                }
                .p-inputswitch .p-inputswitch-slider:before{
                    background-color: #201665 !important;
                    height: 10px !important;
                    width: 10px !important;
                    bottom: 1px !important;
                }
                .p-inputswitch-checked .p-inputswitch-slider{
                    background: #43FF00 !important;
                    border: 2px solid #43FF00 !important;
                }
                .p-inputswitch-checked .p-inputswitch-slider:before{
                    left: -9px !important;
                    background-color: #201665 !important;
                }
            }
            .division-wrapper{
                cursor: pointer;
                
                .division-list{
                    list-style: none;
                    li{
                        padding:10px 25px;
                        background: rgba(0,0,61,0.7);
                        opacity: 0.5;
                        color: #ffffff;
                        cursor: pointer;
                        h1{
                            font-size: 14px;
                            line-height: 1.8;
                        }
                        h3{
                            font-size: 12px;
                            color: #E1A5FF;
                        }
                    }
                    li.active{
                        opacity: 1;
                    }
                }
            }
        }
    }
    .danger{
        color: #FF0000 !important;
    }
    .nw-confirm-dialog{
        button.p-dialog-titlebar-icon.p-dialog-titlebar-close.p-link {
            position: absolute !important;
            top: 0px;
        }
        
        .p-link:focus{
            box-shadow: none;
            border-color: transparent;
        }
        .confirm-wrapper{
            text-align: center;
            margin: 50px auto;

            h1{
                font-size: 26px;
                color: #000000;
                margin-bottom: 30px;
            }
            .nw-button-parent-multiple{
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
            .p-button-danger{
                background: #FF0000 !important;
            }
            .p-button-primary{
                background: #1BB823 !important;
                border-color: #1BB823 !important;
            }
            .p-button-primary:hover{
                background:#6BA873 !important;
                border-color: #6BA873 !important;
            }
            .p-button-primary:focus{
                box-shadow: 0 0 0 0.2em #1BB823;
            }

            .body-wrapper{
                border: 2px solid #E6F4FF;
                border-radius: 5px;
                padding: 20px;
                margin: 20px;

                header{
                    font-size: 16px;
                    font-weight: 700;
                }

                h1{
                    font-size: 50px;
                    color: #0099FF;
                    margin-bottom: 0px;
                }

                footer{
                    color: #004BA0;
                }
            }
        }
    }
    .custom-header {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        width: 100%;
        &::after{
            content: "";
            position: absolute;
            left: 0;
            bottom: -5px;
            background: #E8E8E8;
            height: 1px;
            width: 100%;
        }
        &::before{
            content: "";
            position: absolute;
            left: 0;
            bottom: -10px;
            background: #E8E8E8;
            height: 1px;
            width: 100%;
        }
        h1, i{
            color: #83A4B9;           
        }
        h1{
            font-size: 16px;
            margin-left: 10px;
        }
    }
    .no-border{
        border: none !important;
    }
    .p-t-0{
        padding-top: 0px;;
    }

    .task-badge.active {
        background-color: #47FF06;
    }
    .total-payable-wrapper{
        display: inline-flex;
        align-items: center;
        background: #D8FFE8;
        border: 1px solid #D2EAFF;
        padding: 5px 10px !important;
        margin-top: 10px;
        .p-inputgroup-addon{
            font-size: 18px;
            color: #585858;
            margin-right: 53px;
            font-weight: bold;
        }
        .nw-inputgroup-desc{
            font-size: 18px;
            color: #00AFD4;
            font-weight: bold;
        }
    }
    @media only screen and (min-width: 990px) {
        .box-first-table{
            padding-right: 0 !important;
        }
        .box-second-table{
            padding-left: 0 !important;
        }
        .box-second-table{
            position: relative;
            &::after{
                content: "";
                position: absolute;
                right: 7px;
                top: 48px;
                height: 36px;
                width: 3px;
                background: #697cab;
            }
            &::before{
                content: "";
                position: absolute;
                left: 0px;
                top: 48px;
                height: 36px;
                width: 3px;
                background: #697cab;
                z-index: 5;
            }
        }
        .divisionAttendenceWrapper{
            display: flex;
            .map-wrapper{
                & > div{
                    margin-top: 100px;
                    img{
                        position: absolute !important;
                    }
                }
                .totalInfo-wrapper{
                    position: absolute;
                }
            }
            .map-menu-wrapper{
                width: 186px;
            }
        }

    }
    .box-second-table::after{
        content: "#47FF06";
    }
    @media only screen and (max-width: 990px) {
        .divisionAttendenceWrapper .map-wrapper > div{
            width: 100% !important;
            height: auto !important;
        }
    }.panel-box-header{
        .inActive{
            color:  #FC8F00;
            font-weight: 800;
        }
        .active{
            color:#66BB6A;
            font-weight: 800;
        }
    }
    .info-box-wrapper {
        width: 100%;
        background: #E8F0FD;
        border-radius: 10px;
        padding: 20px;
        text-align: center;
        position: relative;
        min-height: 206px;
        &.not-hover:hover{
            background: #E8F0FD;
            box-shadow: none;
            cursor: auto;
        }
        &.profile-wrapper{
            background: #004BA0;
            .portal-dashboard-profile{
                background: transparent;
            }
            &.not-hover:hover{
                background: #004BA0;
                box-shadow: none;
                cursor: auto;
            }
            .info-table{
                margin-left: 70px;
                table{
                    width: 100%;
                    
                }
                tr{
                    & :first-child{
                        position: relative;
                        color: #EEFF00;
                        padding-left: 10px;
                        &::before{
                            height: 6px;
                            width: 6px;
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 13px;
                            background: #EEFF00;
                            border-radius: 50%;
                        }
                    }
                }
                td{
                    padding: 5px;
                    color: #ffffff;
                    font-weight: 800;
                    text-align: left;
                }
                
            }
            .portal-profile-image{
                img{
                    height: 144px;
                    width: 144px;
                    padding: 10px;
                    background: #ffffff;
                }
            }
        }
        .info-icon {
            height: 52px;
            width: 52px;
            border-radius: 50%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 27px;
            color: #ffff;
        }
        .font-size-50{
            font-size: 50px !important;
        }
        .font-size-30{
            font-size: 30px !important;
        }
        .font-size-20{
            font-size: 20px !important;
        }
        .icon-bg-1{
            background: #6EC5FF;
        }
        .icon-bg-2{
            background: #A4E2DF;
        }
        .icon-bg-3{
            background: rgba(201,164,226,1);
        }
        .icon-bg-4{
            background: rgba(6,16,233,0.5);
        }
        .icon-bg-5{
            background: #66BB6A;
        }
        .icon-bg-6{
            background: rgba(0,33,113,0.5);
        }
        .icon-bg-7{
            background: rgba(123,93,233,0.5);
        }
        .icon-bg-8{
            background: rgba(255,0,0,0.5);
        }
        .icon-bg-9{
            background: #FF5E84;
        }
        .info-text{
            color: #000000;
            margin-top: 20px;
            h4{
                font-size: 21px;
                line-height: 1.8;
            }
            h1{
                font-size: 40px;
            }
            span {
                font-size: 13px;
                font-weight: 600;
                line-height: 1.8;
            }
            p {
                margin-top: 15px;
                font-size: 14px;
                font-weight: 600;
                color: #7D7D7D;
            }
        }
        .info-title {
            margin-top: 15px;
            font-size: 14px;
            font-weight: 600;
            color: #7D7D7D;
            position: absolute;
            bottom: 15px;
            left: 0;
            right: 0;
        }
        .info-button {
            font-size: 16px;
            position: absolute;
            top: 10px;
            left: 10px;
            cursor: pointer;
            &.info-btn-1{
                color: #6EC5FF;
            }
            &.info-btn-2{
                color: #A4E2DF;
            }
            &.info-btn-3{
                color: rgba(201,164,226,1);
            }
            &.info-btn-4{
                color: rgba(6,16,233,0.5);
            }
            &.info-btn-5{
                color: #66BB6A;
            }
            &.info-btn-6{
                color: rgba(0,33,113,0.5);
            }
            &.info-btn-7{
                color: rgba(123,93,233,0.5);
            }
            &.info-btn-8{
                color: rgba(255,0,0,0.5);
            }
            &.info-btn-9{
                color: #FF5E84;
            }
        }
        .chart-box{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .chart-box-title{
            font-weight: 800;
        }
        .netiWorld-DashBoard-wrapper .chart-box-wrapper .point-list{
            overflow: auto;
            min-height: 275px;
            display: inline-flex;
            align-items: center;
            &.no-height{
                min-height: auto !important;
                height: 250px;
                display: block;
            }

        }
        .netiWorld-DashBoard-wrapper .chart-box-wrapper .point-list table {
            width: auto;
            text-align: left;
        }
        .netiWorld-DashBoard-wrapper .chart-box-wrapper .point-list table tr{
            border-bottom: none;
            display: flex;
        }
        .netiWorld-DashBoard-wrapper .chart-box-wrapper .point-list table td{
            // padding-right: 25px;
            min-width: fit-content;
        }
        .netiWorld-DashBoard-wrapper .chart-box-wrapper .point-list table td:last-child{
            display: inline-flex;
        }
        .netiWorld-DashBoard-wrapper .chart-box-wrapper .point-list table td:last-child span{
            margin-right: 10px;
        }
    }
    .info-box-wrapper:hover{
        cursor: pointer;
        background: #0099FF;
        -webkit-box-shadow: -1px 1px 29px -8px rgba(0,0,0,0.75);
        -moz-box-shadow: -1px 1px 29px -8px rgba(0,0,0,0.75);
        box-shadow: -1px 1px 29px -8px rgba(0,0,0,0.75);
        .info-text{
            color: #ffffff;
            margin-top: 20px;
            p {
                color: #FFE600;
            }
        }
        .info-button{
            color: #ffffff;
        }
    }
    .full-width-info-wrapper{
        background: #004BA0;
        padding: 32px;
        border-radius: 15px;
        @extend .profile-image
    }
    .partner-performance-title{
        text-align: center;
        padding:20px 10px;
        border-radius: 10px;
        -webkit-box-shadow: -1px 15px 15px -10px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: -1px 15px 15px -10px rgba(0, 0, 0, 0.3);
        box-shadow: -1px 15px 15px -10px rgba(0, 0, 0, 0.3);
        background: #F5F8FD;
        margin-top: 40px;
        h1{
            color: #2A2A2A;
            font-size: 20px;
            font-weight: 500;
        }
    }
    .performance-table-wrapper{
        margin-top: 40px !important;

        .p-dataview .p-dataview-content{
            background-color:#F5F8FD !important;
            border: unset;
            padding: 0px !important;

            .p-grid{
                margin: 0px !important;
                padding: 0px !important;
            }
        }
        .item-template-wrapper{
            .w-60{
                width: 60px;
            }
            border-bottom: 1px solid #C9E7FF !important;
            & > div{
                >div{
                    &:nth-child(1){
                        border-right: 1px solid #C9E7FF !important;
                    }
                }
                
            }
            .profile-image{
                & img{
                    height: 59px;
                    width: 59px;
                    border:5px solid #B4C6D2;
                    border-radius: 50%;
                }
            }
            .profile-details-wrapper {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                .profile-details{
                    display: grid;
                    margin-left: 15px;
                    .netiId{
                        color: #727272;
                        font-weight: 700 ;
                        margin-bottom: 5px;
                    }
                    .name{
                        color: #2A2A2A;
                        font-weight: 700 ;
                        margin-bottom: 5px;
                    }

                    .location{
                        color: $primary-color;
                        font-weight: bolder;
                        font-size: 15px;
                    }
                }
            }
            .region-wrapper-full{
                padding-left: 15px;

                .number{
                    color: $primary-color;
                    font-weight: bolder;
                    font-size: 15px;
                }
                .profile-details-wrapper{
                    @extend .number
                }
                .region-wrapper{
                    &.only-region{
                        justify-content: left;
                        width: 100%;
                    }
                    & i{
                        margin-right: 5px;
                    }
                    @extend .number
                }

                .amount-wrapper{
                    margin: 10px 10px 10px 0;
                    background-color: #ffffff;
                    width: 100%;
                    height: 100%;
                    text-align: left;
                    padding: 0 10px;
                    display: flex;
                    align-items: center;
                    font-weight: 700;
                    border-radius: 9px;
                    color: #004BA0;

                    .amount{

                    }

                }
            }
            .region-wrapper{
                @extend .region-wrapper-full
                
            }
            .total-score-wrapper{
                align-items: center;
                justify-content: center;
                .total-score{
                    padding: 10px 15px;
                    background: #fff;
                    border-radius: 10px;
                    span{
                        width: 100%;
                        display: block;
                        font-size: 15px;
                        font-weight: 700;
                        color: $primary-color-dark;
                    }
                }
            }
        }
    }
    
    @media screen and (min-width: "767px"){
        .partner-performance-title{
            h1{
                font-size: 26px;
            }
        }
        .region-wrapper-full{
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

