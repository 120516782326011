/*code for payment status*/
.paymentStatus {
    padding: 10px 0px 4px 0px;
    text-align: center;
}

.paymentStatus h1 {
    color: white;
}

.content-left {
    display: flex;
    align-items: center;
    justify-content: left;
}
.successPayment {
    background-color: #ffffff;
    text-align: center;
    max-width: 45%;
    margin: 0 auto;
    border: 1px solid #c7c3c3;
    border-radius: 7px;
}
.successTop {
    color: white;
    background-color: #1890ff;
    padding: 25px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.successTop h3 {
    color: white;
}


@media (min-width: 1200px) and (max-width: 1366px) {
    .nw-search-view{
        div{
            div{
    
                .p-inputgroup{
    
                    .p-inputgroup-addon{
                        // margin: 5px 0px;
                        // min-width: 120px; 
                    }

                    
                }
            }
        }
    }

}
@media (max-width:1199px){
    .layout-wrapper .layout-main .layout-content{
        padding: 0px;
    }

    .seprator{
        .seprator-inside{
            & > div{
                padding-right: 0px
            }
        }
    
        .seprator-inside::after{
            content: none;
    
        }
    }

    .nw-form{
        div{
            .nw-form-body{
                // padding: 20px;
                padding: 0px;

                .seprator-after{
                    background-color: #D2EAFF;
                }
            }
            .p-inputgroup{
                display: flex;
                flex-wrap: wrap;

                .p-inputgroup-addon{
                    background-color: $bgColorWhite;
                    padding: 0 0px;
                    border: 0px solid #D2EAFF;
                    width: 100%;
                    height: 22px;
                }
                .p-inputtext{
                    widows: 85%;
                }
                .nw-inputtextarea-label, .nw-inputtexteditor-label{
                    min-height: 22px !important;
                }
            }

            .nw-upload-button{
                .nw-inputtextarea-label{
                    height: auto;
                    padding-bottom: 5px;
                }
            }

            .nw-search-view{
                .p-inputgroup {
                    flex-wrap: nowrap;

                    .p-inputgroup-addon{
                        width: auto;
                    }
                }

                
                
                
            }

            // .p-inputgroup >.p-inputgroup-addon{
            //     flex: 1 0 100%;
            // }
            // .p-inputgroup >.p-inputgroup-addon:not(:first-child) {
            //     flex: 1;
            //   }
            .p-inputtext {
                flex:1 1  !important;
                // min-width:20%;/* set a min-width*/
                // max-width:450px;
            }
            .nw-button-parent{
                .p-button {
                    flex:0 1  !important;
                    // min-width:20%;/* set a min-width*/
                    // max-width:450px;
                }
            }
            
        }
    }

    .nw-topbar-left-menu{
        width: auto;
    }

    .nw-wallet{
        center{
            margin: 0 auto 20px !important;
        }
    }

    .universal-notice-section{
        .p-grid{
            .slider-main{
                .notice-main {
                    padding: 0 0 20px 0px;
                }
            } 
        } 
    } 

    .main-section.netiworld-main-dashboard{
        .header {
            > div:first-child {
                padding-right: 0px;
            }
            .advertise-info {
                text-align: center;
            }
        }
    } 
    
}

// @media (max-width:2000px){
    
// }

@media (max-width:575px){
    .nw-topbar-left-menu{
        width: 100%;
    }

    .nw-datatable-detail-view {
        .p-datatable{
            .p-datatable-header{
                .header-title{
                    .header-title-right{
                        .p-inputgroup{
                            display: none;
                        }
                    }
                }
            }

            .p-datatable-wrapper{
                .p-datatable-tbody{
                    tr{
                        td{
                            .input-switch-button{
                                display: grid;
    
                                i{
                                    margin: 0px;
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}

@media (min-width: 768px) and (max-width: 896px) {

    .nw-topbar-left-menu{
        width: 100% !important;
    }

    .nw-dialog{
        // min-width: 90vw;
        // max-height: 90vh;
        // min-height: 30vh;
        // overflow: auto;
        // padding: 20px !important;

        min-width: 90%;
        max-height: 70%;
        min-height: 40%;
        /* overflow: auto; */
        padding: 0px !important;
        box-shadow: 0 26px 36px rgba(0, 0, 0, 0.16) !important;
        height: 70%;
    }

    .topbar-search{
        display: none;
    }
}

@media (max-width: 896px) {

    .layout-mask {
        z-index: 0;
    }

    .layout-wrapper{

        .layout-menu-container {
            z-index: 1;
        }

        .layout-main {
            padding-top: 65px;
        }
    } 

    .nw-topbar{

        .p-fluid{
            display: block;
        }

        .nw-topbar-left-menu{
            .nw-topbar-button{
                .layout-menu-button{
                    // position: absolute;
                    left: -10px;
                }
                .topbar-menu-button{
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
            .nw-breadcrumb{
                font-size: 14px;

                .route-bar{
                    .route-bar-breadcrumb{
                        padding: 0 !important;
                        
                    }
                }
            }
        }

        .nw-topbar-right-menu{
            margin-top: 0px !important;

            .topbar-menu-visible{
                li{
                    .p-button{
                        i{
                            color: #000000;   
                        }
                    
                    }
                }
            }

            .topbar-turorial-link{
                position: absolute;
                right: 5px;
                margin: 0 !important;
                font-size: 18px !important;
                bottom: 0 !important;

                span{
                    display: none;
                }
            }
        }
    }

    .topbar-search {
        display: none;
    }

    

    .layout-wrapper .layout-topbar{
        .topbar-menu.topbar-menu-visible {
            display: flex !important;
            flex-wrap: nowrap;
            justify-content: center ;
            align-content: center;
            width: auto;
            flex-direction: row-reverse;
            background-color: $bgColorWhite;
            top: 50px;
            right: 0;
            padding: 10px;
            height: 60px;
            margin-right: 0;
    
            li{
                flex: 0 1;
    
                &:nth-child(5){
                    display: none;
                }
    
                button {
                    // width: 83px;
    
                    .topbar-item-name{
                        color: #727272;
                    }
    
                    .topbar-badge{
                        position: absolute;
                        top: 0px;
                        left: 20px;
                    }

                    i{
                        color: #000000;
                    }
                }
                
                
            }
            .active-topmenuitem{
                .fadeInDown{
                    position: absolute;
                    width: 205px;
                    box-shadow: 0 2px 10px 0 rgba(0, 3, 6, 0.16);
                    li{
                        .p-link{
                            width: 100% !important;
                        }
                    }
                }
                // .fadeInDown::before{
                //     bottom: 100%;
                //     left: 50%;
                //     border: solid transparent;
                //     content: " ";
                //     height: 0;
                //     width: 0;
                //     position: absolute;
                //     pointer-events: none;
                //     left: 225px;
                //     border-color: rgba(216, 216, 220, 0);
                //     border-bottom-color: #e0e0e0;
                //     border-width: 10px;
                //     margin-left: -10px;
                // }
            }
        }
    } 
    .topbar-menu-visible::before{
        margin-left: 49px !important;
    }
    .topbar-menu-visible::after{
        margin-left: 50px !important;
    }

    .layout-wrapper .layout-topbar .topbar-menu:before, .layout-wrapper .layout-topbar .topbar-menu:after{
        bottom: 100%;
        // left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        left: unset;
        border-color: rgba(216, 216, 220, 0);
        border-bottom-color: #ffffff;
        border-width: 10px;
        margin-left: -10px;
        right: 10px !important;
    
    }

    // .topbar-menu:after{
    //     left: 140px;
    // }

    .nw-dialog{
        min-width: 90%;
        max-height: 70%;
        min-height: 40%;
        /* overflow: auto; */
        padding: 0px !important;
        box-shadow: 0 26px 36px rgba(0, 0, 0, 0.16) !important;
        height: 70%;
    }

    .nw-datatable-detail-view {
        .p-datatable{
            .p-datatable-header{
                .header-title{
                    .header-title-right{
                        .p-inputgroup{
                            // display: none;
                        }
                    }
                }
            }

            .p-datatable-wrapper{
                .p-datatable-tbody{
                    tr{
                        td{
                            .input-switch-button{
                                display: grid;
    
                                i{
                                    margin: 0px;
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}

.payment-window{
    z-index: 1000;
    position: absolute;
    top: -140px;
    left: -127px;
    right: 0;
    bottom: 0;
    width: 303px;
    height: 222px;
    background: white;
    border: 1px solid #1890ff;
    border-radius: 17px;
    padding: 20px;
}
.content-bottom {
    display: flex;
    justify-content: center;
}
.cancelbtn {
    font-size: 15px;
    cursor: pointer;
    width: 70px;
    height: 25px;
    border-radius: 20px;
    border: 1px solid #e36e6e;
    text-align: center;
    background-color: #E89191;
    color: white;
    margin-right: 10px;
}
.proccedbtn {
    font-size: 15px;
    cursor: pointer;
    width: 80px;
    height: 25px;
    border-radius: 20px;
    border: 1px solid #40a9ff;
    text-align: center;
    background-color: #1890ff;
    color: white;
}
.content-top h1{
    font-size: 18px;
    font-family: SegoeUI;
    text-align: center;
    color: #1890ff;
    font-weight: 800;
}
.content-top p{
    font-size: 15px;
    font-family: SegoeUI;
    text-align: center;
    font-weight: 500;
}
.content-top .last-p{
    font-size: 18px;
    font-family: SegoeUI;
    text-align: center;
    font-weight: 900;
}

.payment-options{
    z-index: 1000;
    position: absolute;
    top: -114px;
    left: -180px;
    bottom: 0;
    width: 400px;
    height: 260px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.payment-options-f {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}


.content-l {
   
    display: flex;
    width: 100%;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 0px 32px;
    margin-bottom: 13px;
}
.content-data {
    width: 49%;
    height: 71px;
    border: 1px solid;
}
.content-data img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer
}
.cross-icon {
    position: absolute;
    right: -57px;
    top: -13px;
    border: 1px solid black;
    text-align: center;
    cursor: pointer;
}
.cross-icon:active{
    background-color: #0099ff;
}
.img-box{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: right;
    justify-content: right;;
}
.details-wrap{
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #D2EAFF;
}
.bolateColor{
    color: #c8e6f9;
    font-size: 20px;
}
.bolateColor p{
    color: #1b2362 !important;
    font-size: 14px !important;
}
.bolateColor span{
    color: #5b86c2 !important;
}
.profile-wrap{
   width: 60%;
   margin: 0 auto;
   border: 1px solid #D2EAFF;
}
.im {
    width: 28%;
    height: 130px;
    border: 2px solid;
    margin-bottom: 10px;
}
.im img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.dt{
   width: 25%; 
}
.dt p{
    width: 35%;
    /* border: 1px solid; */
    text-align: center;
    background-color: #1890ff;
    /* padding: 10px 2px; */
    margin: 0 auto;
    margin-top: 14px;
    border-radius: 5px;

}
.profile-wrapper{
 display: flex;
 flex-direction: column;
}

.img-part-invoice {
    width: 120px;
    height: 120px;
    border: 1px solid;
    margin-bottom: 10px;
    margin-right: 23px;
}
.img-part-invoice img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.roll-part{
   width: 25%; 
}
.roll-part p{
    width: 92%;
    text-align: center;
    background-color: #1890ff;
    margin: 39px auto 0;
    border-radius: 5px;
}
.data-box {
    padding: 18px 0 0 31px;
}


.login-wrapper {
    width: 100%;
    background-color: #323da6;
}
.nav-bar {
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-items: center;
    justify-content: space-between;
    padding-top: 17px;
    padding-bottom: 6px;
}
  
  .logo{ 
    position: relative;
    width: 140px;
  }
  
  .logo img{
    width: 100%;
    object-fit: cover;
  }
  
  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .navigation ul{
    list-style: none;
  }
  .navigation ul li {
    display: inline-block;
  
  }
  .li-padding{
    padding-left: 35px;
  }
  
  .navigation ul li a{
    text-decoration: none;
    color: #fff; 
    font-size: 20px;
    font-family: SegoeUI;
    font-weight: 500;
    font-style: normal;
    letter-spacing: .3px;
  }
  .navigation ul li a:hover{
   border-bottom: 3px solid white;
  }
  .menubar {
      display: none;
  }
  .main-wrapp {
      width: 100%;
      background-color: #1b2362;
      padding-top: 10px;
  }
  .content-wrapp {
    max-width: 1000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }
  .left-content{
      width: 98%;
      height: 500px;
      padding-right: 2%;
  }
  .left-content img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 4px solid white;
      border-radius: 12px;
  }
  .right-content{
    width: 100%;
    height: 500px;;
    background-color: #eff9fb;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   padding:19px 19px 0 19px;
  }
  .r-c-bg-color{
    background-color: white;
  }
  .inner-logo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
  .img-logo {
    position: relative;
    width: 25%;
  
  }

.img-logo img {
    width: 100%;
    object-fit: cover;
}
.inner-logo p{
 text-align: center;
 font-size: 18px;
 color: #464040;
 padding-top: 2px;
}
.edu-span{
    font-size: 16px;
    color: #1b2362;
    letter-spacing: .01px;
    font-weight: 600;
}
.login-input{
    width: 100%;

}
.login-input-text {
    width: 85%;
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    color: #a39f9f;
    padding: 0 30px;
  margin-bottom: 14px;
}
.login-input-field{
    width: 100%;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.inputt{
    width: 100%;
    height: 45px;
    border: 1px solid grey;
    background-color: rgb(255, 255, 250) !important;
    outline: none;
    
} 

    .login-input .logininput {
        background: #323da6;
        width: 73%;
        height: 45px;
        border: 1px solid;
        outline: none;
        color: #ffffff;
        border-radius: 6px;
        font-size: 20px;
        cursor: pointer;
        text-align: center;
        padding-top: 3px;
        margin: 0 auto;
        padding: 3px 30px;
}
.login-input .logininput:hover {
    background: #0d1444;
    z-index: 1234;
}
.forgotpass {
    
        display: flex;
        justify-content: end;
        margin: 10px 0;
    
}
.navbar-res {
    display: none;
}
.signup-newuser{
    width: 42%;
    text-align: center;
    margin: 0 auto;
}
.signup-newuser p {
    color: white;
    background: #323da6;
    padding:0 6px;
    border-radius: 14px;
    
}

.login-social-list{
    margin-top: 10px;
    position: relative;
  }
  .login-social-list ul{
    list-style: none;
    font-size: 19px;
    display: flex;
    justify-content: center;
  }
  .login-social-list li{
    margin-left: 5px;
    width: 27px;
    height: 27px;
    text-align: center;
    line-height: 25px;
    border-radius: 50%;
    color: #2f313e;
    border: 1px solid #565151;
  }
  .login-social-list li:hover{
    background-color: rgb(24, 82, 158);
    cursor: pointer;
    color: black;
  }
  
  .login-social-list ul li a:hover{
    color: black;
  }
  .login-social-list ul li a{
    position: absolute;
    top: 15px;
    margin-left: -7px;
    color: white;
  }

  .package-section{
      width: 100%;
      background-color: #1b2362;

  }
  .package-img{
      max-width: 1045px;
      margin: 0 auto;
  }
  .package-img img{
    width: 100%;
    object-fit: cover;
}
.footter-wrap{
    width: 100%;
    margin: 0 auto;
    background-color: #282626;
}
.footer-content {
    text-align: center;
    color: white;
}
.footer-content p{
    font-size: 15px;
    padding: 10px 0;
    font-weight: 400;
}
@media (min-width: 768px) and (max-width: 991px) {

    .nav-bar {
        padding-left: 20px;
        padding-right: 20px;
    }
    .content-wrapp {
       padding: 0 20px;
      }
      .left-content{
          height: 430px;
      }
      .left-content img {
          width: 100%;
          height: 100%;
          object-fit: cover;
      }
      .right-content{
        height: 430px;;
        padding:30px 19px 0 19px;
      }
      .inner-logo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
      }
      .img-logo {
        position: relative;
        width: 25%;
      
      }

    .img-logo img {
        width: 100%;
        object-fit: cover;
    }
    .inner-logo p{
     font-size: 16px;
     padding-top: 0;
    }
    .edu-span{
        font-size: 19px;
        color: #1b2362;
        letter-spacing: .01px;
        font-weight: 600;
    }
    .login-input{
        width: 100%;
    
    }
    .login-input-text {
        padding: 0 30px;
        margin-bottom: 5px;
    }
    .login-input-field{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .inputt{
        height: 35px;   
    } 
    
        .login-input .logininput {
            width: 69%;
            height: 40px;
            padding-top: 3px;
            padding: 3px 30px;
    }
    .login-input .logininput:hover {
        background: #0d1444;
        z-index: 1234;
    }
    .forgotpass {
        
            display: flex;
            justify-content: end;
            margin: 10px 0;
        
    }
    .signup-newuser{
        width: 56%;
    }
    .signup-newuser p {
        padding: 0px 1px;
        border-radius: 15px;
    }
}


@media (max-width: 767px) {
    
    .nav-bar {
        position: relative;
        padding-left: 30px;
        padding-right: 30px;
    }
    .navigation {
        display: none;
    }
    .menubar {
        display: block;
        /* background-color: white; */
        color: #4e4848;
        width: 40px;
        height: 30px;
    }
    .menubar p i{
        display: block;
        color: white;
        height: 30px;
        padding: 9px;
        object-fit: contain;
        font-size: 30px;
        cursor: pointer;
    }
    .navbar-res {
        display: block;
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        height: 105px;
        background-color: #1b2362;
        z-index: 123434;
        text-align: center;
    }
    .navbar-res ul{
        list-style: none;
    }
    .navbar-res ul li{
        padding: 8px 0;
        /* background-color: #0099ff; */
        /* border: 1px solid red; */
        border-bottom: 1px solid grey;
    }
      .navbar-res ul li a{
        text-decoration: none;
        color: black;
        font-size: 22px;
        font-family: SegoeUI;
        font-weight: 500;
        font-style: normal;
        letter-spacing: .3px;
        /* background: green; */
        color: white;
        width: 100%;
        
      }
      .left-content{
          display: none;
      }
      .main-wrapp {
        width: 100%;
        padding: 10px 30px;
      }
      .package-section {
          display: none;
      }
      .right-content {
        width: 100%;
        // height: 500px;
        height: auto;
        border-radius: 12px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center;
        padding: 19px 19px 0 19px;
      }
      .forgotpass {
        justify-content: center;
        margin: 10px 0;
        font-size: 12px;
    }
    .signup-newuser {
        width: 174px;
        text-align: center;
        margin: 0 auto;
    }
    .signup-newuser{
        width: 56%;
    }
    .signup-newuser p {
        padding: 0px 1px;
        border-radius: 15px;
    }
.successPayment {
    background-color: #ffffff;
    text-align: center;
    max-width: 60% !important;
    margin: 0 auto !important;
    border: 1px solid #c7c3c3;
    border-radius: 7px;
}

.continueButton {
    outline: 0;
    padding: 20px 24px;
    background-color: #0d1444;
    color: white;
    font-weight: 600;
    border: none;
    border-radius: 10px;
}

button.continueButton:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
  
}
@media (max-width: 500px) {

.login-input .logininput {
    width: 67%;
    padding: 0;
 }
 .signup-newuser {
    width: 62%;
    font-size: 10px;
 }
  .successPayment {
      background-color: #ffffff;
      text-align: center;
      max-width: 80% !important;
      margin: 0 auto !important;
      border: 1px solid #c7c3c3;
      border-radius: 7px;
  }

  .continueButton {
      outline: 0;
      padding: 20px 24px;
      background-color: #0d1444;
      color: white;
      font-weight: 600;
      border: none;
      border-radius: 10px;
  }

  button.continueButton:hover {
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}
@media (max-width: 400px) {
    .successPayment {
        background-color: #ffffff;
        text-align: center;
        max-width: 80% !important;
        margin: 0 auto !important;
        border: 1px solid #c7c3c3;
        border-radius: 7px;
    }

    .continueButton {
        outline: 0;
        padding: 20px 24px;
        background-color: #0d1444;
        color: white;
        font-weight: 600;
        border: none;
        border-radius: 10px;
    }

    button.continueButton:hover {
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
}
@media (max-width: 280px) {
    .successPayment {
        background-color: #ffffff;
        text-align: center;
        max-width: 80% !important;
        margin: 0 auto !important;
        border: 1px solid #c7c3c3;
        border-radius: 7px;
    }

    .continueButton {
        outline: 0;
        padding: 20px 24px;
        background-color: #0d1444;
        color: white;
        font-weight: 600;
        border: none;
        border-radius: 10px;
    }

    button.continueButton:hover {
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
}
@media (min-width: 1900px) {
    #successPayment {
            background-color: #ffffff;
            text-align: center;
            max-width: 30% !important;
            margin: 0 auto !important;
            border: 1px solid #c7c3c3;
            border-radius: 7px;
        }
    
        .continueButton {
            outline: 0;
            padding: 20px 24px;
            background-color: #0d1444;
            color: white;
            font-weight: 600;
            border: none;
            border-radius: 10px;
        }
    
        button.continueButton:hover {
            cursor: pointer;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }

    .nav-bar {
        max-width: 57%;
        padding-top: 14px;
        padding-bottom: 8px;
    }
      
      .logo{ 
        position: relative;
        width: 156px;
      }
      
      
      .li-padding{
        padding-left: 50px;
      }
      
      .navigation ul li a{
        font-size: 20px;
   
      }
      .menubar {
          display: none;
      }
      .main-wrapp {
          width: 100%;
          background-color: #1b2362;
          padding-top: 10px;
      }
      .content-wrapp {
        max-width: 57%;
      
      }
      .left-content{
        width: 98%;
        height: 600px;
        padding-right: 2%;
      }
      .left-content img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 4px solid white;
        border-radius: 16px;
      }
      .right-content{
        width: 100%;
        height: 600px;
        border-radius: 16px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center;
        padding: 35px 30px 0 30px;
      }
     
      .img-logo {
        position: relative;
        width: 35%;
      }
    
    .inner-logo p{
        text-align: center;
        font-size: 20px;
        color: #464040;
        padding-top: 2px;
    }
    .edu-span{
        font-size: 22px;
        color: #1b2362;
        letter-spacing: .01px;
        font-weight: 600;
    }
    .login-input{
        width: 100%;
    
    }
    .login-input-text {
        width: 85%;
        margin: 0 auto;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        color: #a39f9f;
        padding: 0 30px;
        margin-bottom: 20px;
    }
    .login-input-field{
        width: 100%;
        /* text-align: center; */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .inputt{
        width: 100%;
        height: 48px;
        border: 1px solid grey;
        background-color: white;
        outline: none;
        
    } 
    
        .login-input .logininput {
            background: #323da6;
            width: 73%;
            height: 45px;
            border: 1px solid;
            outline: none;
            color: #ffffff;
            border-radius: 6px;
            font-size: 20px;
            cursor: pointer;
            text-align: center;
            padding-top: 3px;
            margin: 0 auto;
            padding: 3px 30px;
    }
    .login-input .logininput:hover {
        background: #0d1444;
        z-index: 1234;
    }
    .forgotpass {
        
            display: flex;
            justify-content: end;
            margin: 12px 0;
        
    }
    .signup-newuser p {
        color: white;
        background: #323da6;
        padding: 4px 9px;
        border-radius: 14px;
        
    }
    
    .login-social-list{
        margin-top: 10px;
        position: relative;
      }
      .login-social-list ul{
        list-style: none;
        font-size: 19px;
        display: flex;
        justify-content: center;
      }
      .login-social-list li{
        margin-left: 5px;
        width: 27px;
        height: 27px;
        text-align: center;
        line-height: 25px;
        border-radius: 50%;
        color: #2f313e;
        border: 1px solid #565151;
      }
      .login-social-list li:hover{
        background-color: rgb(24, 82, 158);
        cursor: pointer;
        color: black;
      }
      
      .login-social-list ul li a:hover{
        color: black;
      }
      .login-social-list ul li a{
        position: absolute;
        top: 15px;
        margin-left: -7px;
        color: white;
      }
    .package-img{
        max-width: 60%;
        margin: 0 auto;
    }
   
}

.forget-img-logo{
    position: relative;
    width: 15%;
    margin-bottom: 20px;
}
.forget-img-logo img {
    width: 100%;
    object-fit: cover;
}
.data-part{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.data-part p{
  font-size: 16px;
}
.process-step-manually{
    display: -webkit-flex;
    display: flex;
    grid-gap: 15px;
    gap: 42px;
    list-style: none;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
   padding: 27px 15px 35px 27px
}
.process-step-manually-2nd-page{
    display: -webkit-flex;
    display: flex;
    grid-gap: 15px;
    gap: 42px;
    list-style: none;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
   padding: 27px 15px 0 27px
}
.process-step-manually li{
    position: relative;
    border: 1px solid;
    padding: 1px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    text-align: center;
    background-color: white;
}
.process-step-manually li:after {
    content: "";
    height: 2px;
    width: 42px;
    background: rgb(238, 221, 221);
    position: absolute;
    left: 25px;
    top: 11px;
}
.process-step-manually li:last-child::after {
    display: none;
}
.activem{
    background-color: #1b2362 !important;
}
.activem i{
    font-size: 13px;
    color: white;
}
.activem:after {
    background: grey !important;
}
.m-input {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.m-input input{
    width: 100%;
    padding: 8px 0;
    border: 1px solid grey;
    outline: none;
    margin-bottom: 15px;
   
}
.last-span {
    margin-top: 0 !important;
    margin-bottom: 16px;
}
.m-login-input {
    width: 100%;
    background-color: #1b2362;
    padding: 6px 0;
    text-align: center;
    color: white;
    font-size: 20px;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 3px;
    margin-top: 10px;
}
.m-input span {
    font-size: 18px;
    color: #656b6c;
    margin-top: 10px;
}
.back-home-btn{
    position: relative;
    width: 44%;
    margin: 0 auto;
    padding: 8px 0;
    outline: none;
    text-align: center;
}
.back-home-btn a{
    font-size: 18px;
    color: #524949;
}
.back-home-btn a:hover{
    color: #1b2362;
}
.back-home-btn a::after{
    content: "";
    height: 1.9px;
    width: 81%;
    background: #524949;
    position: absolute;
    left: 14px;
    top: 30px;
}
.paymentStatus {
    padding: 10px 0px 4px 0px;
    text-align: center;
}

.paymentStatus h1 {
    color: white;
}

.successTop {
    color: white;
    background-color: #1890ff;
    padding: 25px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.successTop h3 {
    color: white;
}

.iconCentralized {
    line-height: 95px;
    max-width: 95px;
    max-height: 100px;
    border-radius: 50%;
    background-color: green;
    text-align: center;
    -webkit-align-items: center;
    align-items: center;
    margin: 0 auto;
    font-size: 50px;
}

.successPayment {
    background-color: #ffffff;
    text-align: center;
    max-width: 45%;
    margin: 0 auto;
    border: 1px solid #c7c3c3;
    border-radius: 7px;
}

.continueButton {
    outline: 0;
    padding: 20px 24px;
    background-color: #0d1444;
    color: white;
    font-weight: 600;
    border: none;
    border-radius: 10px;
}

button.continueButton:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.warningCentralize {
    line-height: 95px;
    max-width: 95px;
    max-height: 100px;
    border-radius: 50%;
    background-color: #fe6464;
    text-align: center;
    -webkit-align-items: center;
    align-items: center;
    margin: 0 auto;
    font-size: 50px;
}

.FailedCentralize {
    line-height: 95px;
    max-width: 95px;
    max-height: 100px;
    border-radius: 50%;
    background-color: red;
    text-align: center;
    -webkit-align-items: center;
    align-items: center;
    margin: 0 auto;
    font-size: 50px;
}



.Confirmation-Page {
    background-color: #f5f5f5;
    padding: 4px;
    font-size: 16px;
}

