.mp-call-info-dashboard{
    .p-panel{
        .p-panel-titlebar{
            background-color: #697CAB;
            padding: 0 0 0 10px;
            border-radius: 0px;
            border: none;
            text-align: left;
            font-size: 16px;
            font-weight: 300;
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 44px;
        }
    }

    .call-info-dashboard-panel{
        .hr-scroll-view{
            display: flex;
            justify-content: left;
            align-items: center;
            overflow-y: auto;

            .p-card{
                margin-right: 10px;

                .p-card-body{
                    width: 200px;
                }
                    
            }
        }
    }
}