@mixin unreadChatBoxBody {
    
    min-height: 73px;
    background-color: #ffffff;
    border: 1px solid #AAD7FF;
    border-radius: 8px;
    padding: 0;

    .p-inputgroup{
        height: 100%;
        display: flex;
        align-items: center;
        margin: 0 24px;
        padding: 10px 0;
        
        .p-inputgroup-addon{
            // width: 65%;
            background-color: transparent;
            height: 100%;
            min-width: unset;
            font-size: 16px;
            padding: 0;
            font-weight: bold;
            text-align: center;
            // color: #ffffff;
            border: unset;
            border-radius: 0;
            text-transform: unset;
            // display: flex;
            // align-items: center;
            // justify-content: center;

            &.img{
                img{
                    // height: 100%;
                    width: 50px;
                }
            }

            &.info{
                flex: 1 0 auto;
                margin: 0 15px;
                text-align: left;

                div{
                    font-size: 10px;
                    margin-bottom: 3px;
                    color: #727272;
                    letter-spacing: .5px;

                    // &::nth-child(2){
                    //     font-size: 14px;
                    // }
                }

                & div:nth-child(2){
                    font-size: 14px;
                    color: #000000;
                }
                
            }

        }

        a{
            display: none;
        }

        button{
            width: 65px !important;
            margin-right: 0px !important;
            margin-left: 0px !important;
            border-radius: 8px !important;
            // border-bottom-right-radius: 8px !important;
            background: #0099FF;
            border: 1px solid #AAD7FF;
            color: #ffffff;
            font-weight: 500;
            // font-size: 10px;
            height: 25px;
            border-left: 1px solid #D2EAFF !important;

            &:hover {
                width: 65px !important;
                background:#ffffff ;
                color: #0099FF;
                font-weight: 500;
            }

            >span{
                // font-size: 34px;
                font-weight: 500;
                padding: 0;
                // color: #0078FF;
            }
        }
    }
}



.nw-chatbox-view-main{
    background-color: #ECF6FF;
    border: 2px solid#0099FF20;
    padding: 20px 10px !important;
    

    .nw-chatbox-view{
        transition: all 1s;
        
        &.header{
            height: 73px;
            background-color: $primary-color;
            border: 1px solid #AAD7FF;
            border-radius: 8px;
            padding: 0;
            margin-bottom: 10px;
    
            .p-inputgroup{
                height: 100%;
                
                .p-inputgroup-addon{
                    width: 65%;
                    background-color: transparent;
                    height: 100%;
                    min-width: unset;
                    font-size: 16px;
                    padding: 0;
                    font-weight: bold;
                    text-align: center;
                    color: #ffffff;
                    border: unset;
                    border-radius: 0;
                    text-transform: unset;
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
                    i{
                        color: #EEFF00;
                        margin-right: 5px;
                        font-size: 25px;
                    }
                }
    
                button{
                    width: 35% !important;
                    margin-right: 0px !important;
                    margin-left: 0px !important;
                    border-top-right-radius: 8px !important;
                    border-bottom-right-radius: 8px !important;
                    background: #ffffff;
                    border: 1px solid #AAD7FF;
                    color: #0099FF;
                    font-weight: 700;
                    font-size: 10px;

    
                    &:hover {
                        width: 35% !important;
                        background:#ffffee ;
                        color: #0099FF;
                        font-weight: 700;
                    }
    
                    >span{
                        font-size: 34px;
                        font-weight: 600;
                        padding: 0;
                        color: #0078FF;
                    }
                }
            }
        }
    
        &.body{
            max-height: 55vh;
            overflow: auto;

            .inside{
                margin-bottom: 10px;

                @include unreadChatBoxBody();
            }
            
        }
    }

    &.topbar{
        background-color: #ECF6FF;
        border: none;
        max-height: 80vh;
        overflow-y: auto;
        position: relative;
        padding: 0px 0px !important;

        .nw-chatbox-view{
            margin-bottom: 0;
            border-radius: unset;
            border: none;
            border-bottom: 1px solid #C1D4E7;

            &.header{
                margin-bottom: 0;
            }

            &.body{
                .inside{
                    margin-bottom: 0px;
                    min-height: 73px;
                    background-color: #ffffff;
                    border: 0px solid #AAD7FF;
                    border-bottom: 1px solid #AAD7FF;
                    border-radius: 0px;
                    padding: 0;

                    &:nth-last-child(1){
                        border-bottom: 0px solid #AAD7FF;
                    }
                    
                    .p-inputgroup{
                        margin: 0 5px;
                        padding: 10px 10px;

                        .p-inputgroup-addon{
                            &.info{
                                margin: 0 5px 0 15px;
                            }
                        }

                        a{
                            display: block;
                            width: 40px !important;
                            height: 25px;
                            padding: 0;
                            text-align: center;
                            border-radius: 4px !important;
                        }

                        button{
                            display: none;
                        }
                    }
                }
                
            }  
        }
        .footer{
            margin: 0;
            padding: 0;
            position: sticky;
            bottom: 0;

            // div:first-child{
            //     .p-button{
            //         border-right: 1px solid #C1D4E7;
            //     }
            // }

            >div{
                padding: 0;

                &:first-child{
                    .p-button{
                        border-right: 1px solid #C1D4E7;
                    }
                }

                >.p-button{
                    min-width: unset !important;
                    width: 100% !important;
                    border: unset;
                    background-color: #ffffff !important;
                    box-shadow: unset;
                    display: flex !important;
                    align-items: baseline;
                    justify-content: center;
                    color: #0099FF;
                    border-radius: unset;
                    border-top: 1px solid #C1D4E7;
                    min-height: 46px;

                    .p-button-icon-right{
                        position: unset;
                    }

                    // &:first-child{
                    //     border-right: 1px solid #C1D4E7;
                    // }
                }
            }
        }
        
    }

    
}