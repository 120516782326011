.nw-datatable-scroller {
  .token-info-body {
    // background-color: #ECF6FF;
    padding: 0px;

    .p-grid {
      .token-info-body-inside {
        margin: 0;

        > div {
          > div:first-child {
            background-color: #ecf6ff;
            padding: 25px 18px;
          }
        }
      }
    }

    .blur-section {
      .token-info {
        .p-datascroller {
          .p-datascroller-header {
            background-color: #ffffff !important;
            padding: 8px;
            border-radius: 0px;
            border: none;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            color: #f27900;
            width: 120px;
            margin: 0 auto 5px;
            box-shadow: 0 3px 6px 0px #00000030;
          }

          .p-datascroller-content {
            padding: unset;
            border: unset;
            background-color: transparent;
            color: unset;

            .p-datascroller-list {
              li {
                .pending-list {
                  .p-grid {
                    background-color: #ffffff;
                    display: flex;
                    margin: 15px 0;
                    position: relative;

                    &::before {
                      position: absolute;
                      background: linear-gradient(#ffee00, #ffb300);
                      width: 16px;
                      height: 100%;
                      content: "";
                    }

                    // .first{
                    //     background: linear-gradient(#FFEE00, #FFB300 );
                    //     width: 10px;
                    // }

                    .second {
                      padding: 16px 36px;

                      .nw-search-view {
                        border-top: unset;
                        padding: unset;
                        margin: unset;
                        border-top-style: unset;

                        div {
                          div {
                            label {
                              h2 {
                                margin-left: 5px;
                              }
                            }
                          }
                        }
                      }
                    }

                    .third {
                      background-color: #ffc200;
                      // width: 90px;

                      center {
                        display: flex;
                        flex-wrap: wrap;
                        height: 100%;

                        .p-button {
                          background-color: inherit !important;
                          width: 100%;
                          font-size: 20px;
                          border: unset;
                        }
                      }
                    }
                  }
                }

                .processing-list {
                  .p-grid {
                    background-color: #ffffff;
                    display: flex;
                    margin: 15px 0;
                    position: relative;

                    &::before {
                      position: absolute;
                      background: linear-gradient(#0099ff, #0078ff);
                      width: 16px;
                      height: 100%;
                      content: "";
                    }

                    // .first{
                    //     background: linear-gradient(#0099FF,#0078FF );
                    //     width: 10px;

                    // }

                    .second {
                      padding: 16px 36px;

                      .nw-search-view {
                        border-top: unset;
                        padding: unset;
                        margin: unset;
                        border-top-style: unset;
                      }
                    }

                    .third {
                      background-color: #0078ff;
                      // width: 90px;

                      center {
                        display: flex;
                        flex-wrap: wrap;
                        height: 100%;

                        .p-button {
                          background-color: inherit !important;
                          width: 100%;
                          font-size: 20px;
                          border: unset;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.token-info {
  }
}
