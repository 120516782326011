.nw-data-table-tabview{
    .p-datatable{
        margin: 0px 0px;
    }
}

.nw-data-table{
    background: $bgColorWhite;
    margin: 10px 0;

    

    .nw-blank-table{
        .blank-table-anim{
            background-color: #fff;
            width: 100%;
            height: 100%;
            max-width: 250px;
            display: block;
            overflow: hidden;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            text-align: center;
            opacity: 1;
            position: relative;
            margin: auto;
        }
    }

    > div{
        > .p-paginator{
            .p-paginator-first{
                border-left: 1px solid #D2EAFF;
            }
            border: 1px solid $datatable-header-th-border;
            border-top: none;

            button{
                span{
                    // color: #B4C6D2 !important;
                    color: #000000 !important;
                }
            }

            button.p-disabled{
                opacity: .3;
            }

            .p-paginator-pages{
                .p-highlight{
                    background-color: #B4C6D2 !important;
                    // color: #ffffff;
                }
            }
            .p-dropdown{
                .p-dropdown-trigger{
                    background: transparent;
                }
                .p-dropdown-label{
                    color: #B4C6D2;
                    background: transparent;
                    border-left: 1px solid #D2EAFF;
                    border-right: 1px solid #D2EAFF;
                    border-radius: 0px;

                }
                .p-dropdown-trigger .p-dropdown-trigger-icon{
                    color: #B4C6D2;
                }
            }
        }
    }

    .p-datatable, .p-picklist, .p-dataview, .p-datascroller, .p-treetable{
        margin: 0px 0;
        font-size: 14px;

        .p-datatable-header, .p-picklist-caption, .p-dataview-header, .p-datascroller-header, .p-treetable-header{
            background-color: $datatable-details-headerBg !important;
            // height: 40px;
            padding: 8px;
            border-radius: 0px;
            border: none;
            text-align: left;
            font-size: 16px;
            font-weight: 500;
            color: $textColorWhite;

            div{
                line-height: normal !important;
                padding: 0 0 0 10px;
                text-align: left;
                // display: flex;
                // justify-content: space-between;
                // align-items: center;

                span, div{
                    font-size: 14px;
                    color: $textColorWhite !important;
                    // font-weight: 700;
                    // padding: 2px 10px;
                    // float: right !important;
                }

                // a{
                //     color: $bgColorWhite;
                //     display: flex;
                //     justify-content: space-around;
                //     align-items: center;
                //     float: right;
                //     background: #{$bgColorBlack}55;
                //     padding: 0px 22px 0 22px;
                //     // margin: -8px -7px 0px 0px;
                //     height: -webkit-fill-available;
                //     font-size: 14px;
                //     font-weight: 500;
                //     min-height: 44px;

                //     i{
                //         font-size: 20px !important;
                //         padding: 1px 0 0 10px;
                //     }
                // }
            }

            .header-title{
                text-align: left;
                font-size: 16px;
                font-weight: 300;
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-height: 44px;
                margin: -8px ;

                .header-title-left{
                    margin: 0 10px 0 0;
                    line-height: 20px;
                    font-size: 16px;
                    font-weight: 700;
                }


                .header-title-right{
                    // margin: -10px -10px 0px 0px !important;
                    margin: 0px 0px 0px 0px !important;
                    // height: 44px;
                    // height: inherit;
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    >span{
                        margin: 0 10px 0 0;
                    }

                    .p-inputgroup{
                        width: 200px;
                        height: 26px;
                        margin: 0 15px 0 0;

                        .p-inputgroup-addon{
                            border: none;
                            border-radius: 0;
                            background-color: transparent;
                            border-bottom: 1px solid white;
                            padding: 0px 0 0 0;
                            margin: -5px 0 0 0;
                            color: #ffffff;
                            min-width: 14px;

                            i{
                                font-size: 12px;
                            }
                        }
                        
                        .p-inputtext{
                            border: none;
                            border-radius: 0;
                            background-color: transparent;
                            border-bottom: 1px solid white;
                            padding: 0 4px;

                            &:enabled:focus{
                                border-color: #ffffff !important;
                            }
                        }
                        .p-inputtext::placeholder{
                            color: #000000aa;
                        }
                    
                    }

                    button{
                        color: #ffffff;
                        float: right;
                        // background-color: #3DD256 !important;
                        // margin: -10px -10px 0px 0px !important;
                        // height: 44px;
                        // height: 100%;
                        border: none;
                        display: flex !important;
                        align-items: center;
                        padding: 0 20px !important;
                        border-radius: unset;
                        box-shadow: unset;
                        // height: fit-content;

                        &.p-button-success{
                            background-color: #3DD256 !important;
                        }

                        &.p-button-dark{
                            background-color: #00000040 !important;
                        }

                        .p-button-icon-right{
                            position: relative;
                            margin: 0 0 0 10px;
                            line-height: 1.1;
                            top: unset;
                        }
                    }

                    a{
                        color: $bgColorWhite;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        float: right;
                        background: #{$bgColorBlack}55;
                        padding: 0px 22px 0 22px;
                        // margin: -8px -7px 0px 0px;
                        height: -webkit-fill-available;
                        font-size: 14px;
                        font-weight: 500;
                        height: 44px;
    
                        i{
                            font-size: 20px !important;
                            padding: 1px 0 0 10px;
                        }
                    }
                }
                
            }
        }
        .p-datatable-wrapper, .p-treetable-tablewrapper{
            table{
                .p-datatable-thead, .p-treetable-thead{
                    tr{
                        // th{
                        //     border: 1px solid $datatable-header-th-border;
                        //     text-align: left;
                        //     padding: 18px 8px 8px 8px;
                        //     position: relative;

                        //     .p-column-title{
                        //         // margin: 18px 0;
                        //         width: 95%;
                        //     }

                        //     .p-sortable-column-icon{
                        //         color: #B5CDDF;
                        //         float: right;
                        //         font-size: 12px;
                        //         margin: 3px 0;
                        //         position: absolute;
                        //         right: 8px;
                        //         border: 1px solid #D2EAFF;
                        //         border-radius: 2px;
                        //         background-color: #ffffff;
                        //     }
                        // }
                        // .p-column-filter{
                        //     background-color: $bgColorWhite !important;
                        //     height: 30px;
                        //     font-size: 13px !important;
                        //     padding: 4px 4px !important;
                        //     font-weight: normal !important;
                        //     text-align: left !important;
                        //     border: 1px solid $datatable-header-th-border !important;
                        //     border-radius: 0 !important;
                        //     margin: 18px 0 0 0;
                            
                        //     &active &focus{
                        //         border: 1px solid blue !important;
                        //     }
                        // }




                        th{
                            border: 1px solid $datatable-header-th-border;
                            text-align: left;
                            padding: 8px 8px 8px 8px;
                            position: relative;
                            background-color: transparent;
                            // outline: 1px solid $datatable-header-th-border;

                            &.table-datetime{
                                width: 110px;
                            }

                            .p-column-title{
                                // margin: 18px 0;
                                width: 95%;
                                min-height: 38px;
                                display: flex;
                                align-items: center;
                            }

                            .p-sortable-column-icon{
                                color: #B5CDDF;
                                float: right;
                                font-size: 12px;
                                margin: 3px 0;
                                position: absolute;
                                right: 5px;
                                // border: 1px solid #D2EAFF;
                                // border-radius: 2px;
                                // background-color: #ffffff;
                                top: 35%;
                            }
                        }
                        .p-column-filter{
                            background-color: $bgColorWhite !important;
                            height: 30px;
                            font-size: 13px !important;
                            padding: 4px 4px !important;
                            font-weight: normal !important;
                            text-align: left !important;
                            border: 1px solid $datatable-header-th-border !important;
                            border-radius: 0 !important;
                            margin: 5px 0 0 0;
                            
                            &active &focus{
                                border: 1px solid blue !important;
                            }
                        }
                        
                    }
                }

                .p-datatable-tbody, .p-picklist-list, .p-treetable-tbody{
                    tr{
                        height: 50px !important;
                        td{
                            border: 1px solid #D2EAFF;
                            // text-overflow: ellipsis;
                            // overflow: hidden;
                            padding: 5px 14px;
                            color: #0A4675;
                            font-size: 13px;
                            word-break: break-word;

                            // > span{
                            //     color: #0A4675;
                            // }

                            // &.table-datetime{
                            //     width: 100px;
                            // }

                            .status-enable{
                                @extend .nw-button;
                                @extend .nw-button-center;
                                margin: 2px auto !important;
                                padding: 8px 10px !important;
                                height: auto !important;
                            }

                            .status-disable{
                                @extend .nw-button;
                                @extend .nw-button-center;
                                margin: 2px auto !important;
                                padding: 8px 10px !important;
                                height: auto !important;
                            }

                            .p-button{
                                @extend .nw-button;
                                @extend .nw-button-center;
                                margin: 2px 1px !important;
                                height: 35px;
                                width: 35px !important;
                                min-width: auto !important;
                                padding: 0px !important;
                                display: inline-block !important;

                                .p-button-icon-left{
                                    opacity: 1;
                                }
                            }



                            .nw-action-button{
                                border: 1px solid #BBE4FF;
                                box-shadow: unset;
                                color: #0099FF;
                                background-color: #FFFFFF !important;
                                font-size: 16px;

                                &:hover{
                                    color: #FFFFFF;
                                    background-color:  #0099FF !important;
                                    transition: 0.5s;
                                }

                                &.p-button-success{
                                    background-color: $success-color !important;
                                }

                                &.p-button-danger{
                                    background-color: $danger-color !important;
                                }

                                &.p-button-info{
                                    background-color: $info-color !important;
                                }

                                &.p-button-warning{
                                    background-color: $warning-color !important;
                                }

                                &.note{
                                
                                }
    
                                &.info{
    
                                }

                                &.edit{
    
                                }

                                &.p-button-icon-only{
                                    .p-button-icon-left{
                                        margin-left: unset;
                                    }
                                    .p-button-text{
                                        display: none;
                                    }
                                }
    
                            } 

                            .pre-exam-info-datatable{
                                .p-datatable-wrapper{
                                    table{
                                        .p-datatable-thead{
                                            display: none;
                                        }

                                        tr:nth-child(even) {
                                            background: $bgColorWhite;
                                        }
                                        tr:nth-child(odd) {
                                            background: $bgColorWhite;
                                        }
                                    }
                                }
                            }
                            
                            

                        }

                        .col-number{
                            font-weight: bold;
                            // float: right;

                            &.text-left{
                                text-align: left;
                            }

                            &.text-right{
                                text-align: right;
                            }

                            &.text-center{
                                text-align: center;
                            }
                        }
                    }
                    tr:nth-child(even) {
                        background: $bgColorWhite;
                    }
                    tr:nth-child(odd) {
                        background: #E6F4FF;
                    }


                }

                .p-datatable-tfoot{
                    // display: grid;
                    // grid-template-columns: 2fr 1fr 1fr 1fr;

                    border: 1px solid #D2EAFF;
    
                    border-top: none;

                    tr{
                        background-color: #D8FFE8;

                        td{
                            font-weight: bold;
                            text-align: right;
                            color: $textColorPrimary;
                            border: 0px solid $datatable-header-th-border;
                            background-color: transparent;
                        }
                        .nw-tfoot-total-text{
                            text-align: right;
                        }
                    }
                    
                }
            }
        }

        // .p-datatable-footer{
        //     background-color: #ffffff;
        //     color: #050d13;
        //     
        //     border: 1px solid $datatable-header-th-border;
        //     border-top: none;
        // }

        .p-datatable-footer-cus{

            color: #050d13;
            display: grid;
            grid-template-columns: 2fr 1fr 1fr 1fr;
            border-top: none;
            background-color: #2196F3;
            z-index: 1234;
            font-weight: bold;
            align-items: start;
            text-align: start;
        }

        .p-paginator{
            button{
                span{
                    color: #000000 !important;
                }
            }

            // button.p-disabled{
            //     opacity: 1;
            // }
            
            // .p-paginator-pages{
            //     .p-paginator-page{
            //         color: #B4C6D2 !important; 
            //     }
            //     .p-highlight{
            //         background-color: #B4C6D2;
            //         color: #ffffff !important;
            //     }
            // }

            button.p-disabled{
                opacity: .3;
            }

            .p-paginator-pages{
                .p-highlight{
                    background-color: #B4C6D2 !important;
                    // color: #ffffff;
                }
            }
            .p-dropdown{
                .p-dropdown-trigger{
                    background: transparent;
                }
                .p-dropdown-label{
                    color: #000000;
                    background: transparent;
                    border-left: 1px solid #D2EAFF;
                    border-right: 1px solid #D2EAFF;
                    border-radius: 0px;

                }
                .p-dropdown-trigger .p-dropdown-trigger-icon{
                    color: #B4C6D2;
                }
            }
        }

        .p-picklist-list{

            .p-picklist-item{

                >div{
                    >div{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        min-height: 40px;
                    }
                }

                &.p-highlight{
                    background-color: #a1d6ff;
                    // color: white;
                    font-weight: 600;
                }
                .p-button{
                    // background-color: #3976bb ;
                    // border-radius: 0px;

                    @extend .nw-action-button;
                }
            }
            
            li:nth-child(even) {
                background: $bgColorWhite;
            }
            li:nth-child(odd) {
                background: #E6F4FF;
            }
        }

        .p-picklist-buttons{
            button{
                @extend .nw-button;
                @extend .nw-button-center;
                margin: 2px auto !important;
                background-color: #697CAB !important;
                border: none;
                .p-button-text{
                    display: none;
                    margin: 0 !important;
                }
                
            }
        }
    }
}


.nw-datatable-detail-view {
    background: $bgColorWhite;
    margin: 10px 0;

    
    
    .p-datatable{
        border: 1px solid #D2EAFF;

        .p-datatable-header{
            background-color: $datatable-details-headerBg;
            // height: 44px;
            padding: 0 0 0 10px;
            border-radius: 0px;
            border: none;

            .header-title{
                text-align: left;
                font-size: 16px;
                font-weight: 300;
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-height: 44px;

                .header-title-left{
                    margin: 0 10px 0 0;
                    line-height: 20px;
                    font-size: 16px;
                    font-weight: 700;
                }


                .header-title-right{
                    // margin: -10px -10px 0px 0px !important;
                    margin: 0px 0px 0px 0px !important;
                    // height: 44px;
                    // height: inherit;
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .p-inputgroup{
                        max-width: 210px;
                        height: 26px;
                        margin: 0 15px 0 0;

                        .p-inputgroup-addon{
                            border: none;
                            border-radius: 0;
                            background-color: transparent;
                            border-bottom: 1px solid white;
                            padding: 0px 0 0 0;
                            margin: 0px 0 0 0;
                            color: #ffffff;
                            min-width: 14px;
                            height: 26px;
                            font-size: 15px;
                        

                            i{
                                font-size: 12px;
                            }
                        }
                        
                        .p-inputtext{
                            border: none;
                            border-radius: 0;
                            background-color: transparent;
                            border-bottom: 1px solid white;
                            padding: 0 4px;
                            color: #ffffff;
                            height: auto;

                            &:enabled:focus{
                                border-color: #ffffff !important;
                            }

                            &::placeholder{
                                color: #ffffffaa !important;
                            }
                        }
                        
                    
                    }

                    button{
                        color: #ffffff;
                        float: right;
                        // background-color: #3DD256 !important;
                        // margin: -10px -10px 0px 0px !important;
                        // height: 44px;
                        // height: 100%;
                        border: none;
                        display: flex !important;
                        align-items: center;
                        padding: 0 20px !important;
                        border-radius: unset;
                        box-shadow: unset;
                        // height: fit-content;

                        &.p-button-success{
                            background-color: #3DD256 !important;
                        }

                        &.p-button-dark{
                            background-color: #00000040 !important;
                        }

                        .p-button-icon-right{
                            // margin: 2px 0 0 5px;
                            position: relative;
                            margin: 0 0 0 15px;
                            line-height: 1.1;
                            top: unset;
                        }
                    }



                    .total-found{
                        // padding-right: 5px;
                        // font-weight: 500;
                        color: #ffffff;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        float: right;
                        background: #00000055;
                        padding: 0px 22px 0 22px;
                        height: -webkit-fill-available;
                        font-size: 14px;
                        font-weight: 500;
                        height: 44px;
                    }
                }
                
            }



            
        }
        .p-datatable-wrapper{
            .p-datatable-thead{
                tr{
                    th{
                        border: 0px solid #e0e0e0;
                        border-bottom: 1px solid #D2EAFF;
                        text-align: left;
                    }
                }
            }
            .p-datatable-tbody{
                tr{
                    td{
                        border: 0px solid #e0e0e0;
                        border-bottom: 1px solid #D2EAFF;
                        text-align: left;
                        padding: 20px;
                        word-break: break-word;

                        .table-image{
                            height: 75px;
                            width: 75px;
                            background-color: #e2e2e2;

                            img{
                                height: inherit;
                                width: inherit;
                            }
                        }

                        // @include input-switch-button();

                        .input-radio-button{
                            div{
                                display: flex;
                                // align-items: center;
                                align-items: baseline;
                                margin-bottom: 5px;
                                
                                .p-radiobutton{
                                    width: 14px;
                                    height: 14px;

                                    .p-radiobutton-box {
                                        width: 14px;
                                        height: 14px;
                                    }
                                }
                                .circle-blue{
                                    background: #2E87CF;
                                    color: white;
                                    height: 18px;
                                    width: 18px;
                                    font-size: 12px;
                                    font-weight: bold;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border-radius: 50%;
                                }
            
                                .circle-green{
                                    @extend .circle-blue;
                                    background: #66BB6A;
                                }
                            }
                            

                            i{
                                color: #0099FF;
                            }

                            .p-radiobutton-label{
                                font-size: 14px;
                                color: #7D7D7D;
                                font-weight: 400;
                            }
                        }

                        .p-button{
                            // background-color: #ffffff00;
                            // border: 0px solid #2196F3;
                            // font-size: 14px;
                            // color: #949494;

                            // span{
                            //     font-size: 15px;
                            // }

                            @extend .nw-action-button;
                            // margin-right: 5px;
                            margin: 2px;
                        }

                        
                    }
                }

                
            

                tr:nth-child(even) {
                    background: $bgColorWhite;
                }
                tr:nth-child(odd) {
                    background: $bgColorWhite;
                }

            }
        }

        .p-paginator {
            border: none;
        }
    }



    &.due-bill-info-table{
        .p-datatable{
            border: unset;
        
            .p-datatable-header{
                background-color: $datatable-details-headerBg !important;
                // height: 40px;
                padding: 8px !important;
                border-radius: 0px;
                border: none;
                text-align: left;
                font-size: 16px;
                font-weight: 500;
                color: $textColorWhite;
                border: 4px solid $datatable-details-headerBg !important;
            }
            .p-datatable-wrapper{
                .p-datatable-thead{
                    display: none;
                }

                .p-datatable-tbody{
                    tr{
                        td:nth-child(odd) {
                            background: #E6F4FF;
                        }
                        td{
                            border: 4px solid $datatable-header-th-border !important;
                            line-height: 1.8;
                            padding: 20px 10px !important;

                            .year-month{
                                .year{
                                    font-size: 18px !important;
                                    font-weight: 700;
                                }
                            }

                            .table-middle-col{
                                display: flex;
                                justify-content: space-between;
                                flex-wrap: wrap;
                            }

                            .total-due{
                                .due{
                                    font-size: 18px !important;
                                    font-weight: 700;
                                }
                                div{
                                    font-size: 15px;
                                    font-weight: 700;
                                    position: relative;
                                    padding-left: 10px;

                                    .symbol-taka{
                                        color: #004BA0;
                                        font-family: arial, sans-serif;
                                        font-size: 12px;
                                        position: absolute;
                                        left: 0;
                                    }
                                }
                            }
                        }
                        .col-number{
                            font-weight: bold;
                            // float: right;
        
                            &.text-left{
                                text-align: left;
                            }
        
                            &.text-right{
                                text-align: right;
                            }
        
                            &.text-center{
                                text-align: center;
                            }
                        }
        
                        .required-field{
                            span{
                                color: #ff0000;
                            }
                        }

                    }
                    // tr:nth-child(even) {
                    //     background: $bgColorWhite;
                    // }
                    // tr:nth-child(odd) {
                    //     background: #E6F4FF;
                    // }
                }

                .p-datatable-tfoot{
                    tr{
                        td{
                            border: 4px solid #D2EAFF;
                        }
                    }
                }
            }
        }
    }

    &.parent-team-info-table{
        margin: 0px 0;

        .p-datatable{
            border: unset;
        }

        .p-datatable-header{
            background-color: $datatable-details-headerBg !important;
            // height: 40px;
            padding: 0 0 0 8px !important;
            border-radius: 0px;
            border: none;
            text-align: left;
            font-size: 16px;
            font-weight: 500;
            color: $textColorWhite;
            border: 0px solid $datatable-header-th-border !important;
        }
        .p-datatable-wrapper{
            .p-datatable-thead{
                display: none;
            }
            .p-datatable-tbody{
                tr{
                    background: unset !important;
                    
                    td{
                        border-left: 0px solid $datatable-header-th-border !important;
                        border-right: 1px solid $datatable-header-th-border !important;
                        border-top: 1px solid $datatable-header-th-border !important;
                        border-bottom: 1px solid $datatable-header-th-border !important;
                        line-height: 1.8;
                        padding: 20px 10px !important;
                        background-color: unset;
                        position: relative;

                        &:nth-child(odd) {
                            background: unset !important;
                        }

                        &::before {
                            position: absolute;
                            background-color: $datatable-details-headerBg;
                            content: '';
                            width: 5px;
                            height: 100%;
                            left: 0;
                            top: 0;
                            bottom: 0;
                        }

                        // @extend .nw-datatable-dataview-small-panel
                    }
                }
                // tr:nth-child(odd) {
                //     background: unset !important;
                // }

                tr:nth-child(even) {
                    background: $bgColorWhite !important;
                }
                tr:nth-child(odd) {
                    background: #E6F4FF !important;
                }
            }
        }

    }

    &.product-list-datatable-detail-view{

        .p-datatable{
            .header-title{
                .header-title-right{
                    .p-inputgroup{
                        .p-inputgroup-addon{
                            width: auto !important;
                        }
                    }
                }
            }

            .p-datatable-wrapper{
                .p-datatable-thead{
                    // display: none;
                }
                .p-datatable-tbody{
                    tr{
                        td{
                            line-height: 1.8;
                            padding: 20px 10px !important;

                            .table-middle-col{
                                display: flex;
                                justify-content: space-between;
                                flex-wrap: wrap;
                            }

                            
                        }
                        .col-number{
                            font-weight: bold;
                            // float: right;
        
                            &.text-left{
                                text-align: left;
                            }
        
                            &.text-right{
                                text-align: right;
                            }
        
                            &.text-center{
                                text-align: center;
                            }
                        }
        
                        .required-field{
                            span{
                                color: #ff0000;
                            }
                        }

                    }
                }
            }
        }
        
        
    }
}