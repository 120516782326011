.nw-dialog{
    // min-width: 50vw;
    // max-height: 90vh;
    // min-height: 50vh;
    // overflow: auto;
    // padding: 40px !important;
    // box-shadow: 0 26px 36px rgba(0, 0, 0, 0.16) !important;

    width: 50%;
    max-height: 70%;
    min-height: 40%;
    /* overflow: auto; */
    padding: 0px !important;
    box-shadow: 0 26px 36px rgba(0, 0, 0, 0.16) !important;
    height: 70%;

    &.chat-box-dialog{
        .p-dialog-titlebar{
            display: flex;
            justify-content: space-between;

            >span{
                flex: 1 0 auto;

                .header{
                    display: flex;
                    justify-content: start;
                    align-items: center;

                    img{
                        width: 35px;
                        margin-right: 10px;
                    }

                    .date{
                        color: #727272;
                        font-size: 10px;
                    }
                }
            }
        }

        .p-dialog-content {
            top: 90px !important;
            height: unset !important;
        }
    }

    &.p-dialog-maximized {
        top: 0 !important;
        left: 0 !important;
    }

    .p-dialog-titlebar{
        border: 0 none !important;
        background-color: #ffffff;
        color: #212121;
        padding: 25px 40px !important;
        font-weight: 700 !important;
        position: fixed !important;
        width: 100% !important;
        height: auto !important;
        border-bottom: 1px solid #dedede !important;
    }

    .p-dialog-content {
        background-color: #ffffff;
        color: #212121;
        border: 0 none !important;
        padding: 0 40px !important;
        position: relative !important;
        top: 75px !important;
        height: 100% !important;
        overflow: auto !important;
    }


    div{
        // display: flex;
        // flex-wrap: wrap;
        label{
            // background-color: $bgColorWhite
            padding: 0 0px;
            margin: 5px;
            border: 0px solid #D2EAFF;
            width: auto;
            height: 22px;
    
            // min-width: 300px;
            font-size: 13px;
            font-weight: bold;
            text-align: left;
            color: $textColorPrimary;
            border-radius: 0;

            span{
                color: red;
            }
        }

        input{
            widows: 85%;
        }

        .p-dropdown, .p-inputtext{
            background-color: $bgColorWhite;
            height: 44px;
            font-size: 13px;
            padding: 12px 18px;
            font-weight: normal;
            text-align: left;
            border: 1px solid #D2EAFF;
            border-radius: 0;
            margin: 0 0 0 -1px;
            width: 100%;

            &.custom-dropdown{
                .p-placeholder{
                    color: #180d0d !important;
                }
            }
    
            .p-dropdown-label{
                display: block;
                border: 0;
                white-space: nowrap;
                overflow: hidden;
                font-weight: normal;
                // width: 100%;
                padding: 0;
                font-size: 14px;
                color: #180d0d;
                background: #f5f5f500;
            }
    
            .p-dropdown-trigger{
                background-color: #ffffff00;
    
                .p-dropdown-trigger-icon{
                    color: #0E75F8;
                }
            }
    
            
        }
        textarea, .p-inputtextarea{
            // width: 100% !important;
            min-height: 80px !important;
        }
    
        .nw-form{
            border: none;
            padding: 0;

            .nw-dialog-button{
                min-width: 140px !important;
            }
        }

        .p-calendar{
            .p-inputtext{
                margin: 0 -7px 0 0px;
            }
            .p-button, .p-button-icon-only{
                width: auto !important;
                min-width: 35px !important;
                background: $bgColorWhite;
                color: $inputgroupAddonIconColor;
                border-top: 1px solid #D2EAFF !important;
                border-bottom: 1px solid #D2EAFF !important;
                border-right: 1px solid #D2EAFF !important;
                border-left: 0px solid #D2EAFF !important;
                // margin-left: -1px;
                margin-right: -1px !important;
                margin-left: -1px !important;
                border-radius: 0px; 
            }

            .p-button:last-child{
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
            }

            .p-button:hover, .p-button-icon-only:hover{
                width: auto !important;
                // min-width: 120px !important;
                background: $inputgroupAddonIconColor;
                color: $bgColorWhite;
                // border-top: 1px solid #D2EAFF !important;
                // border-bottom: 1px solid #D2EAFF !important;
                // border-right: 1px solid #D2EAFF !important;
                // border-left: 1px solid #D2EAFF !important;
            }
        }

        .p-radiobutton{
            .p-radiobutton-box{
                @extend .p-checkbox-box;
                border-radius: 50% !important;
            }
            
            .p-radiobutton-box.p-highlight {
                border-color: #2196F3 !important;
                // background-color: #2196F3 !important;
                color: #ffffff !important;

                .p-radiobutton-icon{
                    background-color: #2196F3 !important;
                }
            }
        }
    }

}