@font-face {
  font-family: SegoeUI;
  src: local("Segoe UI Light"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff2)
      format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff)
      format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.ttf)
      format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: SegoeUI;
  src: local("Segoe UI Semilight"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff2)
      format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff)
      format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.ttf)
      format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: SegoeUI;
  src: local("Segoe UI"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff2)
      format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff)
      format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.ttf)
      format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: SegoeUI;
  src: local("Segoe UI Bold"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff2)
      format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff)
      format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.ttf)
      format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: SegoeUI;
  src: local("Segoe UI Semibold"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff2)
      format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff)
      format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.ttf)
      format("truetype");
  font-weight: 700;
}

@import url("https://fonts.googleapis.com/css?family=Hind+Siliguri:300,400,500,600,700&display=swap&subset=bengali,latin-ext");

@font-face {
  font-family: SutonnyOMJ;
  src: local("SutonnyOMJ"), url(./SutonnyOMJ.ttf) format("truetype");
  font-weight: 400;
}

// @font-face {
//     font-family: SegoeUI;
//     src:
//         local("Segoe UI"),
//         url(./segoe-ui.ttf) format("truetype");
//     font-weight: 400;
// }

$primary-color-dark: #004ba0;
$primary-color: #0099ff;
// $primary-color-light:  #C5CAE9;
$primary-color-text: #ffffff;
$eduman-theme-color: #2e3192;
$accent-color: #e91e63;

$textColorPrimary: #0a4675;
$secondary-text-color: #757575;
$textColorBlack: #000000;
$textColorWhite: #ffffff;

$divider-color: #bdbdbd;

$success-color: #00d47f; //#CE125D #00b56c
$danger-color: #ce125d; //#CE125D
$warning-color: #fca800;
$info-color: $primary-color;

$text-success-color: #00b56c; //#CE125D #00b56c
$text-danger-color: #ce125d; //#CE125D
$text-warning-color: #ec9e00;

$bodyBgColor: #fcfcfc;
$bgColorWhite: #ffffff;
$bgColorBlack: #000000;

$mainMenuBeforeAfterBorderColor: #dedede;

$inputgroupAddonBGColor: #ecf6ff;
$inputgroupAddonBorder: 1px solid #d2eaff;
$inputgroupAddonIconColor: #459cff; /*#0E75F8;*/
$focusColor: #90ceff;

$datatable-headerBg: #697cab;
$datatable-header-th-border: #d2eaff;

$datatable-details-headerBg: $datatable-headerBg;

$tabviewHeader-a: transparent !important;
$borderColor: 1px solid #ebebeb !important;

$ulBorderColor: #565bed;

$bgPurple: #7a1aa2;
$bgPurpleLight: #9b59b6;
$bgsuccessDark: #01b16a;

$pad: 10px;

@import "./_login_nw4.scss";
@import "./_layout_inside_nw4.scss";
@import "./portal_style_hridoy";
@import "./pages/all";

// @mixin pending-bg {
//     background-color: ;
// }

body {
  background-color: $bodyBgColor;
  font-family: SegoeUI !important;

  .layout-menu-logo {
    background-color: #0078ff !important;

    button {
      img {
        height: 33px !important;
      }
    }
  }

  .p-link {
    font-family: SegoeUI !important;
  }
  .p-component {
    font-family: SegoeUI !important;
  }

  .p-inputtext:enabled:focus,
  .p-inputtext:enabled:focus ~ .p-button,
  .p-button:enabled:focus,
  .p-dropdown:not(.p-disabled).p-focus,
  .p-checkbox .p-checkbox-box:not(.p-disabled).p-focus,
  .p-accordion .p-accordion-header a:not(.p-disabled):focus {
    outline: none none;
    outline-offset: 0;
    -moz-box-shadow: inset 0 0 0px $focusColor;
    -webkit-box-shadow: inset 0 0 0px $focusColor;
    box-shadow: inset 0 0 0px $focusColor;
    border-color: $focusColor !important;
    // border-color: $textColorPrimary
  }

  .p-tabview.p-tabview-top .p-tabview-nav li a:not(.p-disabled):focus {
    box-shadow: inset 0 0 0px $focusColor;
  }

  .p-inputtext:enabled:hover {
    border: $inputgroupAddonBorder;
  }

  .primary-color-dark-bg {
    background-color: $primary-color-dark;
  }

  .primary-color-bg {
    background-color: $primary-color;
  }

  .bg-success {
    background-color: $success-color !important;

    .p-panel-titlebar {
      background-color: $success-color !important;
      border-color: $success-color;
    }
  }

  .bg-danger {
    background-color: $danger-color !important;

    .p-panel-titlebar {
      background-color: $danger-color !important;
      border-color: $danger-color;
    }
  }

  .bg-primary {
    background-color: $primary-color !important;

    .p-panel-titlebar {
      background-color: $primary-color !important;
      border-color: $primary-color;
    }
  }

  .bg-warning {
    background-color: $warning-color !important;

    .p-panel-titlebar {
      background-color: $warning-color !important;
      border-color: $warning-color;
    }
  }

  .text-success {
    color: $text-success-color !important;
  }

  .text-danger {
    color: $text-danger-color !important;
  }

  .text-info {
    color: $primary-color !important;
  }

  .text-warning {
    color: $text-warning-color !important;
  }

  .text-color-white {
    color: white !important;
  }

  .text-capitalize {
    text-transform: capitalize;
  }
  .text-uppercase {
    text-transform: uppercase;
  }

  // .bg-success{
  //     background-color: $success-color !important;
  // }

  // .bg-warning{
  //     background-color: $warning-color !important;
  // }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }

  .font-size-12 {
    font-size: 12px !important;
  }

  .nw-copyText {
    background: #ffffff !important;
    height: auto;
    // font-size: 14px;
    // padding: 3px;
    text-align: left;
    /* width: 100%; */
    // flex: 1 1 auto;
    color: #0e75f8;
    font-weight: 700;
    border: none;

    &:focus {
      outline: none;
    }
  }

  .comming-soon-anim {
    // background:#d0d0d0;

    $bg-color: #d0d0d0;
    $dot-color: #ffffff;

    // Dimensions
    $dot-size: 2px;
    $dot-space: 22px;

    background: linear-gradient(
          90deg,
          $bg-color ($dot-space - $dot-size),
          transparent 1%
        )
        center,
      linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
      $dot-color;
    background-size: $dot-space $dot-space;

    .comming-soon-anim-inside {
      top: 0;
      left: 0;

      h1 {
        cursor: default;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 300px;
        margin: auto;
        display: block;
        text-align: center;
      }

      h1 span {
        position: relative;
        top: 20px;
        display: inline-block;
        animation: bounce 0.3s ease infinite alternate;
        font-size: 80px;
        color: #ffffff;
        text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
          0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent,
          0 8px 0 transparent, 0 9px 0 transparent,
          0 10px 10px rgba(0, 0, 0, 0.4);
      }

      h1 span:nth-child(2) {
        animation-delay: 0.1s;
      }

      h1 span:nth-child(3) {
        animation-delay: 0.2s;
      }

      h1 span:nth-child(4) {
        animation-delay: 0.3s;
      }

      h1 span:nth-child(5) {
        animation-delay: 0.4s;
      }

      h1 span:nth-child(6) {
        animation-delay: 0.5s;
      }

      h1 span:nth-child(7) {
        animation-delay: 0.6s;
      }

      h1 span:nth-child(8) {
        animation-delay: 0.2s;
      }

      h1 span:nth-child(9) {
        animation-delay: 0.3s;
      }

      h1 span:nth-child(10) {
        animation-delay: 0.4s;
      }

      h1 span:nth-child(11) {
        animation-delay: 0.5s;
      }

      h1 span:nth-child(12) {
        animation-delay: 0.6s;
      }

      h1 span:nth-child(13) {
        animation-delay: 0.7s;
      }

      h1 span:nth-child(14) {
        animation-delay: 0.8s;
      }

      h1 span:nth-child(15) {
        animation-delay: 0.9s;
      }

      h1 span:nth-child(16) {
        animation-delay: 0.2s;
      }

      h1 span:nth-child(17) {
        animation-delay: 0.3s;
      }

      h1 span:nth-child(18) {
        animation-delay: 0.4s;
      }

      h1 span:nth-child(19) {
        animation-delay: 0.5s;
      }

      h1 span:nth-child(20) {
        animation-delay: 0.6s;
      }

      h1 span:nth-child(21) {
        animation-delay: 0.7s;
      }

      h1 span:nth-child(22) {
        animation-delay: 0.8s;
      }

      h1 span:nth-child(23) {
        animation-delay: 0.9s;
      }

      h1 span:nth-child(24) {
        animation-delay: 0.2s;
      }

      /* ANIMATION */
      @keyframes bounce {
        100% {
          top: -20px;
          text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
            0 5px 0 #ccc, 0 6px 0 #ccc, 0 7px 0 #ccc, 0 8px 0 #ccc, 0 9px 0 #ccc,
            0 50px 25px rgba(0, 0, 0, 0.2);
        }
      }
    }

    /* JUMP */
  }

  @mixin trackingHoverAndActive($bg-color, $text-color) {
    background-color: $bg-color;

    .info-icon {
      &.icon-bg-1 {
        color: $text-color;
      }
    }

    .info-text {
      p {
        color: $text-color;
      }
    }

    .info-button {
      color: $text-color;
    }
  }

  .tracking-progress-view {
    display: flex;
    justify-content: center;
    align-items: center;

    .progress-view {
      background-color: #f1f1f1;
      height: 100px;
      width: 100px;
      margin: 0 10px;
      position: relative;
      box-shadow: 0px 3px 6px 0px #0000001f;
      border-radius: 5px;
      min-height: unset;
      transition: all 0.5s;
      padding: 20px 0px;

      &.step-1:hover,
      &.step-2:hover,
      &.step-3:hover,
      &.step-4:hover,
      &.step-5:hover {
        cursor: pointer;
        // background: #0099FF;
        -webkit-box-shadow: -1px 1px 29px -8px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: -1px 1px 29px -8px rgba(0, 0, 0, 0.75);
        box-shadow: -1px 1px 29px -8px rgba(0, 0, 0, 0.75);

        @include trackingHoverAndActive(#0099ff, #ffffff);
      }

      &.active + .next {
        div {
          background-color: $primary-color-dark;
          animation: pulse 0.5s infinite;

          @keyframes pulse {
            0% {
              background-color: $primary-color-dark;
            }
            100% {
              background-color: $warning-color;
            }
          }
        }
      }

      &.step-1 {
        &.active {
          @include trackingHoverAndActive($info-color, #ffffff);
        }

        .info-icon {
          &.icon-bg-1 {
            color: $info-color;
          }
        }

        .info-button {
          color: $info-color;
        }
      }

      &.step-2 {
        &.active {
          @include trackingHoverAndActive($warning-color, #ffffff);
        }

        .info-icon {
          &.icon-bg-1 {
            color: $warning-color;
          }
        }

        .info-button {
          color: $warning-color;
        }
      }

      &.step-3 {
        &.active {
          @include trackingHoverAndActive(#b08af7, #ffffff);
        }

        .info-icon {
          &.icon-bg-1 {
            color: #b08af7;
          }
        }

        .info-button {
          color: #b08af7;
        }
      }

      &.step-4 {
        &.active {
          @include trackingHoverAndActive($success-color, #ffffff);
        }

        .info-icon {
          &.icon-bg-1 {
            color: $success-color;
          }
        }

        .info-button {
          color: $success-color;
        }
      }

      &.step-5 {
        &.active {
          @include trackingHoverAndActive(#56bcae, #ffffff);
        }

        .info-icon {
          &.icon-bg-1 {
            color: #56bcae;
          }
        }

        .info-button {
          color: #56bcae;
        }
      }

      .info-icon {
        height: unset;
        width: unset;
        border-radius: unset;
        font-size: 36px;

        &.icon-bg-1 {
          background: unset;
        }
      }

      .info-text {
        margin-top: 10px;

        p {
          margin-top: 15px;
          font-size: 12px;
          font-weight: 700;
          color: #000000;
        }
      }

      .info-button {
        font-size: 10px;
        position: absolute;
        top: 2px;
        left: 2px;
      }
    }

    .next {
      filter: drop-shadow(0px 2px 2px rgba(50, 50, 0, 0.15));

      div {
        background-color: #f1f1f1;
        height: 14px;
        width: 14px;
        -webkit-clip-path: polygon(0 0, 0% 100%, 100% 50%);
        clip-path: polygon(0 0, 0% 100%, 100% 50%);
      }
    }
  }

  .mbp-style {
    // background-color: #E91E6320 !important;
  }

  .zbp-style {
    background-color: #bbf7fb !important;
  }

  .bdp-style {
    background-color: #e6ccff !important;
  }

  .bep-style {
    background-color: #f4fde3 !important;
  }

  .chart-text-view {
    .text-view-wrapper {
      font-weight: bold;
      position: relative;

      .task-badge {
        position: absolute;
        left: 0;
        margin: 0;
        top: 5px;
      }

      .title {
        margin-left: 20px;
        font-size: 12px;
      }
    }
  }

  .complete-process-bar {
    margin: 0 4px;
    width: auto;

    &.gray-bg {
      background: #f1f1f1;
    }

    .panel-box {
      margin-top: 5px;

      @mixin progressBarColor($color) {
        .p-progressbar {
          .p-progressbar-value {
            background-color: #{$color} !important;
          }
        }
      }

      &.process-bar-step-20 {
        @include progressBarColor("#eb346b");
      }
      &.process-bar-step-40 {
        @include progressBarColor("#ba34eb");
      }
      &.process-bar-step-60 {
        @include progressBarColor("#34ebb1");
      }
      &.process-bar-step-80 {
        @include progressBarColor("#30d140");
      }
      &.process-bar-step-100 {
        @include progressBarColor("transparent");
      }

      .p-progressbar {
        height: 12px;

        .p-progressbar-label {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
  }
}

// *::-webkit-scrollbar, .nw-layout-content::-webkit-scrollbar {
//   width: 5px;
//   transition: all 1s;
// }

// *::-webkit-scrollbar-track, .nw-layout-content::-webkit-scrollbar-track{
//   box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
// }

// *::-webkit-scrollbar-thumb, .nw-layout-content::-webkit-scrollbar-thumb {
//   background-color: #AEDBFF;
// //   outline: 1px solid #ef5350;
// }

*::-webkit-scrollbar,
.nw-layout-content::-webkit-scrollbar {
  width: 14px !important;
  transition: all 1s;
}

*::-webkit-scrollbar-track,
.nw-layout-content::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  box-shadow: inset 0 0 14px 14px transparent;
  border: solid 4px transparent;
}

*::-webkit-scrollbar-thumb,
.nw-layout-content::-webkit-scrollbar-thumb {
  // background-color: #AEDBFF;
  box-shadow: inset 0 0 14px 14px #b1ddff;
  border: solid 4px transparent;
  border-radius: 14px;
  //   outline: 1px solid #ef5350;
}

*::placeholder,
.p-placeholder {
  color: #7d7d7d !important;
  text-transform: lowercase !important ;
}

a[tooltip] {
  position: relative;

  &:hover {
    &:before {
      content: "";
      position: absolute;
      // top: 45px;
      // left: 8px;
      background: #333333;
      padding: 0px;
      height: 10px;
      width: 10px;
      transform: rotate(45deg);
      opacity: 1;
      top: 22px;
      left: 2px;
    }
    &:after {
      // content: attr(phone);
      // position: absolute;
      // top: 50px;
      // left: 0;
      // background: red;
      // padding: 5px 10px;
      // border-radius: 5px;
      // opacity: 1;
      // min-width: 60px;
      content: attr(tooltip);
      position: absolute;
      background: #333333;
      padding: 5px 5px;
      height: 20px;
      width: max-content;
      opacity: 1;
      border-radius: 5px;
      color: #ffffff;
      left: -4px;
      top: 25px;
    }
  }
}

.nw-signup-hr {
  border: 1px solid #afe5ff69;
}

.text-bold {
  font-weight: bold;
}

.institute-image-view {
  border: 1px solid #d2eaff;
  width: auto;
  height: 275px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  //   overflow: auto;
  padding: 5px;

  img {
    max-height: 250px;
    width: auto;
    width: -webkit-fill-available;
  }
}

@mixin input-switch-button() {
  .input-switch-button {
    display: flex;
    align-items: center;

    i {
      font-size: 20px;
      margin: 0 10px;
      height: 19px;
      // color: #0099FF;
    }

    .switch-button-no {
      color: #7d7d7d;
    }

    .switch-button-yes {
      color: #0099ff;
    }

    .p-inputswitch {
      margin: 0 10px;
      width: 23px;
      height: 15px;
    }

    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
      background-color: #0099ff;
      border: 2px solid #0099ff;
      height: 16px;
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
      background-color: #ffffff;
      height: 10px;
      width: 10px;
      /* bottom: -1px; */
      top: -1px;
      left: -11px;
      border: 2px solid #0099ff;
    }

    .p-inputswitch .p-inputswitch-slider {
      background-color: #ffffff;
      border: 2px solid #b1b1b1;
      height: 16px;
    }

    .p-inputswitch .p-inputswitch-slider:before {
      background-color: #ffffff;
      height: 10px;
      width: 10px;
      /* bottom: -1px; */
      top: -1px;
      left: -1px;
      border: 2px solid #b1b1b1;
    }
  }
}

/*Dashboard Start Here*/

@import "./_dashboard_nw4.scss";

/*Dashboard End Here*/

@import "./_default_common_elements.scss";

@import "./_nw_tabview.scss";

@import "./_nw_form.scss";

@import "./_nw_search_view.scss";

@import "./_nw_dialog.scss";

@import "./_nw_button.scss";

@import "./_nw_wallet.scss";

@import "./_nw_data_table.scss";

@import "./_nw_datatable_scroller.scss";

@import "./class_config_card.scss";

@import "./_nw_bijoy_point.scss"; //_nw_bijoy_point.scss

@import "./_nw_accrodion_view.scss";

@import "./_goto_protal_meraj";

@import "./_nw_my_profile.scss";

@import "./_nw_universal_notice";

@import "./_nw_chatbox";

@import "./_nw_token";

@import "./_nw_crm_dashboard";

@import "./_nw_crm_message";

@import "./nw_image_cropper";

@import "./_nw_others_dashboard";

@import "./_nw_main_dashboard";

@import "./_event_calendar";

@import "./_nw_dws_admisia";

/*************   COMMON CLASS START  ***************/

@include input-switch-button();

/*************   COMMON CLASS END  ***************/

.fieldset-box-parent {
  display: flex;
  position: relative;
  height: 350px;

  .p-fieldset-content {
    > div {
      > div {
        height: 110px;
        img {
          max-height: 100px;
        }
      }
    }
  }

  .fieldset-box-content {
    top: 17px;
    right: 0px;
    position: absolute;

    .p-button {
      @extend .nw-button;
      width: 50px !important;
      min-width: auto !important;
      position: relative;
      background-color: #ffffff !important;
      border: unset;
      color: currentColor;
      box-shadow: unset;

      &:hover {
        color: #459cff;
      }

      span {
        position: absolute !important;
      }
    }
  }

  p {
    height: 120px;
    overflow: auto;
    word-break: break-word;
  }
}

.main-section {
  background-color: #ffffff;
  margin: -1px 4px 10px 4px !important;
  border: 1px solid #ebebeb;
  padding: 20px;
  min-height: 89vh;

  .nw-form {
    // background-color: #ffffff;
    margin: 0px !important;
    border: none !important;
    padding: 0px !important;
  }

  .nw-data-table {
    margin: 10px 7px;
  }
}

//styling for menu config start

.p-tree {
  width: 100% !important;
  border: none !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  // color: #000000;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border: 1px solid #e4dede !important;
  margin: 3px 0;
  padding: 10px 0 !important;
  background-color: #42a5f52e !important;
}

.p-tree .p-treenode-children {
  margin: 20px 0 20px 20px;
  padding: 0 0 0 1em;

  & > .p-treenode {
    & > .p-treenode-content {
      background-color: transparent !important;
    }
  }
}
.p-treenode-label {
  font-size: 16px;
  font-weight: 600;
}
//styling for menu config end

.p-calendar {
  width: 100%;
  margin-right: 33px;
  font-family: SegoeUI;

  // .p-button{
  //     // margin: 0 0 0 -35px;
  // }

  .p-datepicker {
    // background-color: red;
    padding: 40px 40px;
    border-radius: 20px;
    // left: -150px !important;
    top: 130% !important;
    z-index: 996 !important;

    &:before,
    &:after {
      bottom: 100%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      left: 25%;
    }
    &:before {
      border-color: rgba(216, 216, 220, 0);
      border-bottom-color: #e0e0e0;
      border-width: 10px;
      margin-left: -10px;
    }

    &:after {
      border-color: rgba(244, 244, 249, 0);
      border-bottom-color: #ffffff;
      border-width: 10px;
      margin-left: -10px;
      bottom: 99.7%;
    }

    .p-datepicker-group {
      .p-datepicker-header {
        padding: 0;

        .p-datepicker-prev,
        .p-datepicker-next {
          color: #002171;
          font-size: 12px;
          // top: auto;
          // margin-top: 0px !important;

          // .p-datepicker-prev-icon, .p-datepicker-next-icon{
          //     // margin-top: -3px !important;
          // }
        }
        .p-datepicker-title {
          line-height: unset;

          .p-datepicker-month,
          .p-datepicker-year {
            border: 1px solid #d2eaff !important;
            color: #0099ff;
            // border: none;
            font-size: 16px;
            font-family: SegoeUI;
            padding: 0 6px;
            font-weight: 800;

            option {
              // font-weight: bold !important;
              color: #585858 !important;
              font-size: 13px;
              padding: 5px 10px;
              // background: #bc0000 !important;
              // text-transform: uppercase;
            }
          }
        }
      }

      .p-datepicker-calendar-container {
        .p-datepicker-calendar {
          thead {
            tr {
              th {
                padding: 1px;

                span {
                  color: rgba(0, 75, 160, 0.7);
                  border-bottom: 1px solid #004ba0;
                  padding: 3px 4px;
                  margin: 0px 6px;
                  font-family: SegoeUI;
                }
              }
            }
          }
          tbody {
            tr {
              height: 38px;

              td {
                // &:hover{
                //     // background-color: transparent;
                //     // border: 1px solid #004BA0;
                // }

                span {
                  // width: 32px;
                  color: #002171;
                  // font-size: 20px;
                  // width: 40px;
                  // padding: 3px 0 5px;
                  font-family: SegoeUI;

                  &:hover {
                    background-color: transparent;
                    border: 1px solid #0099ff;
                    color: #0099ff;
                  }
                }

                &.p-datepicker-today {
                  span {
                    background-color: #0099ff;
                    color: #ffffff;
                  }
                }
              }
            }
          }
        }
      }
    }

    .p-datepicker-buttonbar {
      .p-button {
        border-top: 1px solid #d2eaff !important;
        border-bottom: 1px solid #d2eaff !important;
        border-right: 1px solid #d2eaff !important;
        border-left: 1px solid #d2eaff !important;
      }
    }
  }
}

.discount-percent {
  background: linear-gradient(
    135deg,
    rgba(14, 154, 248, 1) 0%,
    rgba(77, 58, 243, 1) 45%,
    rgba(163, 14, 248, 1) 100%
  );
  text-align: center;
  // display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  color: white;
  transition: all 1s cubic-bezier(0.87, -1.76, 0, 2.89);
  overflow: hidden;
  height: 410px;
  margin-top: 7px;

  .discount-percent-number {
    // font-size: 50px;
    position: absolute;
    top: 35%;
    z-index: 996;
    span {
      width: 100%;
      font-size: 18px;
    }
  }
  .discount-percent-wave {
    position: absolute;
    bottom: 0;
    height: 30px;
    width: 95%;
    transition: all 2s ease;
  }
  // .discount-percent-wave-one{
  //     // background: $textColorPrimary;
  //     // border-top-left-radius: 63%;
  //     // border-top-right-radius: 100%;
  //     // border-top: 1px solid burlywood;
  // }
  // .discount-percent-wave-two{
  //     /* background: $focusColor; */
  //     // border-bottom-left-radius: 63%;
  //     // border-bottom-right-radius: 100%;
  //     // border-bottom: 1px solid burlywood;
  //     // right: 0;
  // }
  .discount-percent-seventy {
    left: 0;
    width: 100%;
    height: 130px;
    bottom: -130px;
    z-index: 999;
  }
}

.discount-percent-bg {
  background: linear-gradient(
    135deg,
    rgba(163, 14, 248, 1) 0%,
    rgba(77, 58, 243, 1) 45%,
    rgba(14, 154, 248, 1) 100%
  );
}

// .layout-wrapper{

// }
.layout-wrapper {
  .layout-menu-container {
    .layout-menu-wrapper {
      border-right: none !important;
    }
    .p-scrollpanel {
      background-color: $bgColorWhite;

      .p-scrollpanel-bar {
        background-color: #b1ddff;
        opacity: 1;
        width: 6px;
      }
    }
  }

  .layout-main {
    padding-top: 60px;
    padding-bottom: 33px;

    .layout-content {
      padding: 0px 16px 10px 16px;
      margin: 0 0 -40px 0;
    }
  }
}
// .topbar-item-name{
//     display: block !important;
// }

.layout-profile {
  // background: linear-gradient(90deg, rgba(33, 150, 243, 0.0) 20%, rgba(255, 255, 255, 0.0) 40%, $bodyBgColor 70%);
  border-bottom: 0px !important;
  .layout-profile-button {
    padding: 5px 12px !important;
    height: 50px;
    background-color: #f3f3f3;

    .layout-profile-userinfo {
      margin-left: 0px !important;

      .layout-profile-name {
        font-size: 16px;
        color: #00178b !important;
        font-weight: bold !important;
        text-transform: capitalize;
        letter-spacing: 0.25px;
        display: flex !important;
        align-items: center;
      }
    }

    .layout-profile-icon {
      background-color: $bgColorWhite;
    }
  }
  .layout-profile-button {
    .layout-profile-role {
      color: #42a5f5 !important;
      margin: 0 0 0 30px;
      font-size: 12px;
      letter-spacing: 1px;
    }

    .layout-profile-icon {
      border: 1px solid #b4c6d2;
      border-radius: 3px;
    }
  }
  .layout-profile-button:hover {
    background-color: #e6f4ff !important;
    border-radius: 0px;
  }

  .nw-profile-popup {
    button:hover {
      background-color: #fdfdfd !important;
      color: $inputgroupAddonIconColor !important;
    }

    button:hover i {
      color: #459cff !important;
      // background-color: #fdfdfd !important;
    }
  }
}

.nw-topbar {
  background: rgba(0, 153, 255, 1) !important;
  background: -moz-linear-gradient(
    left,
    rgba(0, 153, 255, 1) 0%,
    rgba(90, 118, 255, 1) 100%
  ) !important;
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(0, 153, 255, 1)),
    color-stop(100%, rgba(90, 118, 255, 1))
  ) !important;
  background: -webkit-linear-gradient(
    left,
    rgba(0, 153, 255, 1) 0%,
    rgba(90, 118, 255, 1) 100%
  ) !important;
  background: -o-linear-gradient(
    left,
    rgba(0, 153, 255, 1) 0%,
    rgba(90, 118, 255, 1) 100%
  ) !important;
  background: -ms-linear-gradient(
    left,
    rgba(0, 153, 255, 1) 0%,
    rgba(90, 118, 255, 1) 100%
  ) !important;
  background: linear-gradient(
    to right,
    rgba(0, 153, 255, 1) 0%,
    rgba(90, 118, 255, 1) 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0099ff', endColorstr='#5a76ff', GradientType=1 ) !important;
  padding: 0 !important;
  height: 60px !important;
  box-shadow: none !important;

  .p-fluid {
    display: flex;
    // justify-content: center;
    // align-items: baseline;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .nw-topbar-left-menu {
    // display: grid;
    height: auto;
    // padding: 0 20px;
    .nw-topbar-button {
      // height: 45px;

      .layout-menu-button {
        margin: 10px 0 5px 12px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 5px;
        color: $textColorPrimary;
        position: absolute;
        left: -60px;
        top: 4px;

        i {
          // text-shadow: 0px 0px 3px #fff;
          font-size: 14px;
          color: #ffffff;
        }
      }
    }
    .nw-breadcrumb {
      // background: #ffffff;
      // height: 30px;
      font-size: 22px;
      font-weight: 100;

      .route-bar {
        // background: linear-gradient(90deg, $bodyBgColor 20%, rgba(255, 255, 255, 0.0) 70%, rgba(33, 150, 243, 0.0) 100%);
        background: rgba(0, 0, 0, 0);
        border-bottom: none !important;
        height: auto;
        min-height: auto;
        padding: 0 0 2px 0;

        .route-bar-breadcrumb {
          padding: 0 10px 0px 35px !important;
          ul li {
            color: #ffffff;
          }
        }
      }
    }
  }
  .nw-topbar-right-menu {
    // margin-top: 13px;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    // padding: 0 0 0 10px;

    .topbar-search {
      .p-inputgroup {
        position: relative;
        // border: 2px solid #00000010;
        border-radius: 3px;

        .p-inputtext {
          background-color: rgba(0, 0, 0, 0.1);
          border: 2px solid rgba(0, 0, 0, 0.08) !important;
          color: #ffffff;
          border-top-left-radius: 2px !important;
          border-bottom-left-radius: 2px !important;
          border-top-right-radius: 2px !important;
          border-bottom-right-radius: 2px !important;
        }

        .p-inputtext::placeholder {
          color: #ffffffcc;
        }

        .p-button {
          background-color: transparent;
          position: absolute;
          right: 0;
          border: 0px;
        }
      }
    }

    .topbar-menu {
      // background-color:#00000022;
      // height: auto;
      // padding: 7px 10px 0px 0;
      // min-width: 200px;

      // background-color: #00000022;
      // height: auto;
      // padding: 18px 10px 0px 0;
      // min-width: 200px;
      // height: 100%;
      // width: -webkit-fill-available;
      min-width: 225px;
      margin-right: 12px;

      li {
        // margin-left: 10px;
        margin: 0px 6px !important;

        // .p-button{
        //     i{
        //         color: #000000;
        //     }

        // }

        button {
          .topbar-badge {
            // border-radius: 8px;
            // width: auto;
            // padding: 1px 5px;
            // left: 17px;
            // right: auto;
            // top: 0px;

            background-color: #ff5eb4;
            border-radius: 8px;
            width: auto;
            padding: 1px 6px;
            left: 20px;
            right: unset;
            top: -10px;
            font-size: 11px;
          }
        }

        &.active-topmenuitem {
          .fadeInDown {
            &::before {
              right: 23px;
              left: unset;
            }

            &::after {
              right: 23px;
              left: unset;
            }
          }
        }
      }
    }

    .topbar-turorial-link {
      // position: absolute;
      bottom: 5px;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      margin: 0 0 5px 0;
    }

    // ..........ToolTip...............

    button {
      .tooltip {
        // display: none;
        left: -25%;
        position: absolute;
        top: 60%;
        /* margin-top: 10px; */
        padding: 5px;
        font-size: 1rem;
        font-weight: 500;
        white-space: nowrap;
        color: $textColorPrimary;
        border-radius: 2px;
        background-color: #ffffff;
        box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1),
          0 5px 15px rgba(0, 0, 0, 0.07);
        pointer-events: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        opacity: 0;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }
      .tooltip:before {
        bottom: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        left: 40%;
        border-color: rgba(216, 216, 220, 0);
        border-bottom-color: #ffffff;
        border-width: 10px;
        margin-left: -10px;
      }

      &:hover {
        .tooltip {
          top: 125%;
          opacity: 0.9;
          // display: block;
        }
      }
    }
  }
}

.layout-wrapper .layout-topbar .topbar-menu > li > button {
  cursor: pointer;
  position: relative;
  display: block;
  width: 30px;
  height: auto;
  line-height: normal;
  text-align: center;
  border-radius: 5%;
  // padding: 8px 5px;
  // margin: 2px;
  color: #000000 !important;

  &:hover,
  &:hover .topbar-icon {
    background-color: #64b5f600;
    color: #000000;
  }
}

.layout-wrapper .layout-topbar .topbar-menu > li > button .topbar-icon {
  font-size: 20px !important;
  color: $textColorWhite;
}

.layout-wrapper .layout-topbar .topbar-menu > li > button .topbar-item-name {
  display: block;
  color: $bgColorWhite;
}
.layout-wrapper .layout-topbar .topbar-menu > li:nth-child(5) {
  // content: "";
  // border-right: 2px solid #f5f5f542;
  // height: 46px;
  // padding: 10px 0px;
  // margin: 10px 0px;
  // border-right: 2px solid #000000;
  // height: 20px;
  /* padding: 20px 0px; */
  // margin: 10px 0 !important;

  // height: 24px;
  // padding: 0px 0px;
  // margin: 0 11px 0px 0px !important;
  // width: 2px;
  // background-color: #00000050;

  padding: 0 0px 0 0 !important;
  height: 20px;
  padding: 0px 0px;
  margin: 2px 5px 0px 5px !important;
  /* width: 1px; */
  border: 1px solid #00000040;
  background-color: #0000001f;
}

.nw-profile-popup {
  position: relative;
  border: none;
  // top: 60px;
  // right: -18px;
  // display: none;
  min-width: 220px;
  width: 245px;
  background-color: $bgColorWhite !important;
  -webkit-animation-duration: 0.3s;
  -moz-animation-duration: 0.3s;
  animation-duration: 0.3s;
  list-style-type: none;
  padding: 0px 5px 0 5px !important;
  // -moz-border-radius: 20px;
  // -webkit-border-radius: 20px;
  // border-radius: 20px;
  // -webkit-box-shadow: 0 2px 10px 0 rgba(0, 3, 6, 0.16);
  // -moz-box-shadow: 0 2px 10px 0 rgba(0, 3, 6, 0.16);
  // box-shadow: 0 2px 10px 0 rgba(0, 3, 6, 0.16);
  z-index: 1000 !important;
  margin: 0px !important;

  center {
    img {
      width: 80px;
      margin-top: 15px;
      border-radius: 50%;
      max-height: 100px;
    }

    h2 {
      margin: 10px 0 20px 0 !important;
      color: #0078ff;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .nw-profile-inside-double-border {
    margin: 10px -1px 0 -5px;
    // border-top: 1px solid #C9C9C9;
    // border-bottom: 1px solid #C9C9C9;
    border-top: 1px solid rgba($color: #000000, $alpha: 0.06);
    border-left: 0;
    border-right: 0;
    // height: 5px;
    // border-style: dashed;
  }

  li {
    // border-bottom: 1px solid #C9C9C9;
    margin: 0 5px;
    a {
      .p-link {
        color: $textColorBlack !important;

        i {
          color: #b4c6d2 !important;
          width: 30px;
          margin-right: 0px !important;
        }
      }
    }
  }
}

.nw-profile-popup::before,
.nw-profile-popup::after {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  left: 210px;
}
.nw-profile-popup::before {
  border-color: rgba(216, 216, 220, 0);
  border-bottom-color: red;
  border-width: 10px;
  margin-left: -10px;
}
.nw-profile-popup::after {
  border-color: rgba(244, 244, 249, 0);
  border-bottom-color: $bgColorWhite;
  border-width: 9px;
  margin-left: -9px;
}

.layout-wrapper .layout-menu-container {
  z-index: 5;

  .layout-menu {
    padding: 0 0px 0 0px;

    > li {
      margin-bottom: 0px;
    }
  }
}

.layout-menu {
  padding: 0 12px 0 12px;

  > li {
    margin-bottom: 0px;
  }
}

.layout-menu {
  background-color: $bgColorWhite !important;
  // padding: 0 12px 0 12px;
  li {
    ul {
      background-color: $bgColorWhite !important;
      li {
        // padding: 0 0 0 15px;

        a {
          padding: 8px 0 8px 15px !important;

          i {
            color: #b4c6d2 !important;
            width: 30px;
            right: 10px !important;
          }
          .layout-menuitem-text {
            color: #000000 !important;
            letter-spacing: 0.36px;
          }
        }
        .active-route {
          span {
            // color: #004BA0 !important;
            color: #459cff !important;
          }
        }
        ul {
          background-color: #f1f1f1 !important;
          padding: 0 0 0 15px !important;
        }
      }

      li.reportCls a .layout-menuitem-text {
        font-family: "SegoeUI";
        color: #ffffff !important;
        letter-spacing: 0.36px;
        font-size: 13px;
        font-weight: 500 !important;
      }
    }
    a:hover {
      background-color: transparent !important;
    }
    a:hover i,
    a:hover .layout-menuitem-text {
      color: #459cff !important;
      // background-color: #fdfdfd !important;
    }
  }
}

.layout-wrapper.layout-menu-dark
  .layout-menu-container
  .layout-menu
  li
  a.active-route {
  // background-color:#fdfdfd !important;
  color: #004ba0 !important;
}

// .layout-menu li > a:hover i{
//     color: $fontColorHover !important;
// }

.layout-wrapper.layout-menu-dark
  .layout-menu-container
  .layout-menu
  li
  a:hover {
  background-color: none !important;
  color: $inputgroupAddonIconColor !important;
}

.active-menuitem,
.active-menuitem > ul,
li.active-menuitem > ul li.active-menuitem ul {
  position: relative;
}

// .layout-menu > .active-menuitem::before {
//     height: 10px;
//     width: 10px;
//     background:  $basicBGColor;
//     position: absolute;
//     top: 38px;
//     right: 49px;
//     content: '';
//     transform: rotate(45deg);
//     z-index: 1;
// }

.active-menuitem > ul::before {
  position: absolute;
  left: 24px;
  bottom: 17px;
  content: "";
  height: 100%;
  width: 1px;
  background: $mainMenuBeforeAfterBorderColor;
}

.layout-root-menuitem {
  > ul {
    > li {
      > ul {
        > li {
          > a {
            .layout-menuitem-text {
              font-family: "SegoeUI";
              font-size: 13px;
              font-weight: 500 !important;
            }
          }

          > ul {
            > li {
              > a {
                .layout-menuitem-text {
                  font-family: "SegoeUI";
                  font-size: 13px;
                  font-weight: 500 !important;
                }
              }
            }
          }
        }
      }

      .reportCls {
        // background-color: white;
        height: 25px;
        text-transform: uppercase;

        a {
          padding: 0 !important;
          z-index: inherit;

          &:before {
            position: absolute;
            left: 10px;
            top: 50%;
            content: "";
            height: 0;
            width: 0;
          }

          span {
            background-color: #97aebe;
            border-radius: 3px;
            padding: 0px 7px 2px 7px;
            color: #ffffff !important;
          }
        }

        &:before {
          position: absolute;
          left: 9px;
          // bottom: 17px;
          content: "";
          height: 25px;
          width: 1px;
          // background: white;
        }

        // &::after{
        //     position: absolute;
        //     left: 10px;
        //     // top: 50%;
        //     content: '';
        //     height: 1px;
        //     width: 5px;
        //     background: red;
        // }
      }
    }
  }
}

.layout-menu > .layout-root-menuitem > ul > .active-menuitem > ul::after {
  content: "";
  position: absolute;
  left: 20px;
  top: 0px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #fff;
  clear: both;
}

.active-menuitem > ul .active-menuitem ul::before {
  position: absolute;
  left: 9px;
  top: 0px;
  content: "";
  height: 100%;
  width: 1px;
  background: $mainMenuBeforeAfterBorderColor;
}

.active-menuitem > ul .active-menuitem ul::after {
  position: absolute;
  left: 24px;
  // top: 19px;
  bottom: 17px;
  content: "";
  height: 100%;
  width: 1px;
  background: $mainMenuBeforeAfterBorderColor;
}

.active-menuitem > ul > li > a::before {
  position: absolute;
  left: 10px;
  top: 50%;
  content: "";
  height: 1px;
  width: 5px;
  background: $mainMenuBeforeAfterBorderColor;
}

.menuLastChild hr {
  border: 0.5px solid #9fd5ff;
  opacity: 0.2;
  box-shadow: 0 1px 0px #000379;
}

.layout-wrapper
  .layout-main
  .layout-topbar
  .layout-topbar-menu-wrapper
  .topbar-menu
  > li
  > ul
  a {
  text-align: left !important;
}
.menu-item-hide {
  display: none !important;
}
@media (max-width: 1024px) {
  .menu-item-hide {
    display: block !important;
  }
  .mobile-logo {
    display: none !important;
  }
  // .layout-topbar{
  //     padding: 15px 10px !important;
  // }
  .layout-topbar-menu-wrapper .topbar-menu > li:first-child::before,
  .layout-topbar-menu-wrapper .topbar-menu > li:first-child::after {
    display: none;
  }
  .layout-topbar-menu-wrapper .topbar-menu > li {
    height: auto;
    width: auto;
  }
  .layout-topbar-menu-wrapper a .topbar-badge {
    margin-left: 10px;
  }
  .layout-wrapper
    .layout-main
    .layout-topbar
    .layout-topbar-menu-wrapper
    .topbar-menu:before {
    border-bottom-color: $ulBorderColor !important;
  }
  .layout-wrapper
    .layout-main
    .layout-topbar
    .layout-topbar-menu-wrapper
    .topbar-menu.topbar-menu-active {
    border-color: $ulBorderColor !important;
  }
  .layout-wrapper
    .layout-main
    .layout-topbar
    .layout-topbar-menu-wrapper
    .topbar-menu
    > li
    > ul {
    width: 100%;
    height: 100%;
  }
}

.nw-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  border-top: 1px solid #d9efff;
  z-index: 4;
  padding: 2px;

  div {
    display: flex;

    .nw-footer-left {
      display: flex;
      align-items: center;
      //    border: 1px solid #2E3192;
      flex-wrap: nowrap;
      border-radius: 0 0 0 8px;
      overflow: hidden;
      height: 30px;
      //    color: $textColorPrimary;
      color: #7caace;

      img {
        margin-top: 0px;
        border-radius: 0 8px 0 0;
      }

      span {
        padding: 7px 10px;
        font-size: 10px;
        letter-spacing: 0px;
      }
    }

    .footer-icons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 !important;

      span {
        margin: 0 20px 0px 0px;
      }

      button {
        /* color: #727272; */
        font-size: 22px;
        margin: 0 3px;
        display: inline-block;
        /* margin-top: 6px; */
        -webkit-transition: color 0.3s;
        transition: color 0.3s;

        &:hover i {
          color: #0099ff;
        }

        i {
          color: #b4c6d2;
        }
      }
    }
  }
}

.nw-circle-panel {
  display: flex;
  // flex: 1 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border: 1px solid #ececec;
  padding: 0px 0 10px 0;
  color: #ffffff;

  .circle-panel {
    // width: 120px;  /* Or whatever */
    // height: 120px; /* Or whatever */
    // margin: 25px auto;  /* Magic! */
    // text-align: center;
    // // line-height: 100px;       /* The same as your div height */
    // // border: 1px solid #3CBEEF;
    // border-radius: 50%;
    // padding: 35px 0 0 0;
    width: 120px;
    height: 120px;
    margin: 30px 15px;
    text-align: center;
    border-radius: 50%;
    padding: 28px 0 0 0;
    font-size: 18px;
    // font-weight: bold;
    font-family: arial, sans-serif;
    background: #b4c6d2;
  }

  // .circle-panel-first {
  //     // background: #54CBF8;
  // }
  // .circle-panel-second {
  //     // background: #B9EBA8;
  // }
  // .circle-panel-third {
  //     // background: #FECDE3;
  // }
  .circle-panel-fourth {
    // background: #1ED178;
    background: #0099ff;
  }

  // .circle-panel-hr{
  //     height: 120px;
  //     background: $bgColorWhite;
  //     margin-top: -50px;
  // }
  // .circle-panel-hr::before{
  //     width: 7px;
  //     height: 180px;
  //     content: "";
  //     position: absolute;
  //     border-right: 1px solid #ececec;
  // }

  // .circle-panel-hr::after{
  //     border-right: 1px solid #ececec;
  //     border-bottom: 1px solid #ececec;
  //     content: "";
  //     display: block;
  //     height: 15px;
  //     width: 15px;
  //     position: relative;
  //     transform: rotate(-45deg);
  //     top: 65%;
  //     background: $bgColorWhite;
  // }

  .circle-panel-fifth {
    border-radius: 0%;
    border-top: 0px solid #3cbeef;
    border-right: 0px solid #3cbeef;
    border-bottom: 0px solid #3cbeef;
    border-left: 0px solid #3cbeef;
    // color: #1ED178;
    // margin-top: 44px;
    width: 100%;
    background: #ffffff;
    height: 91px;
    margin: 0 0 0px 0;

    .p-button {
      background: $bgColorWhite;
      // height: 120px;
      // margin-top: -33px;
      // border: 1px solid #ececec;
      // color: #1ED178
    }

    .p-button:hover .circle-panel-label {
      // background: #ececec;
      color: white;
    }
    .circle-panel-label {
      height: auto;
      margin: 0 0 0 0;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .circle-panel-label {
    height: auto;
    margin: 50px 0 0 0;
    font-size: 14px;
    font-weight: bold;
    color: #002171;
  }
}

.nw-dataview-small-panel {
  margin: 40px 0 0 0;

  div {
    display: flex;
    // flex: 1 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    // border: 1px solid #ececec;
    padding: 0px 0 10px 0;
    max-width: 500px;
    margin: auto;

    div {
      // width: 120px;  /* Or whatever */
      // height: 120px; /* Or whatever */
      // margin: 25px auto;  /* Magic! */
      // text-align: center;
      // // line-height: 100px;       /* The same as your div height */
      // // border: 1px solid #3CBEEF;
      // border-radius: 50%;
      // padding: 35px 0 0 0;
      width: 33%;
      height: auto;
      margin: 0 auto;
      // text-align: center;
      color: $textColorPrimary;
      /* border-radius: 50%; */
      /* padding: 28px 0 0 0; */
      /* font-size: 18px; */
      /* font-weight: bold; */
    }

    div:nth-child(1) {
      justify-content: flex-start;
      font-weight: bold;
    }

    div:nth-child(2) {
      width: auto;
      font-weight: bold;
      // background: #54CBF8;
    }

    div:nth-child(3) {
      justify-content: flex-start;
    }
  }
}

.nw-datatable-dataview-small-panel {
  margin: 0px 0 0 0;

  &.productInfo,
  &.ownerInfo {
    div {
      :first-child {
        width: 60px;
      }

      .badge {
        div {
          font-weight: 400;
          .task-badge {
            margin: 0 6px;
            top: 2px;
          }
        }
      }
    }
  }

  &.priceInfo {
    h2 {
      font-size: 18px;
      color: #0096fb;

      .symbol-taka {
        font-size: 12px;
        font-family: arial, sans-serif;
      }
    }
    div {
      :first-child {
        width: 130px;
      }
    }
  }

  &.flex-number-right {
    div {
      align-items: unset;
      margin: unset;
    }
    div:nth-child(1) {
      width: 80%;
    }

    div:nth-child(2) {
      width: 20px;
    }

    div:nth-child(3) {
      width: auto;
    }
  }

  div {
    display: flex;
    // flex: 1 0;
    flex-wrap: wrap;
    align-items: center;
    // justify-content: center;
    // border: 1px solid #ececec;
    padding: 0px 0 0px 0;
    max-width: 500px;
    margin: auto;

    div {
      width: 33%;
      height: auto;
      margin: 0 auto;
      color: $textColorPrimary;

      .task-badge {
        &.online {
          width: 12px;
          height: 12px;
          margin: 0;
        }

        &.reject {
          background-color: #e91e63;
          width: 12px;
          height: 12px;
          margin: 0;
        }

        &.pending {
          background-color: #ff9800;
          width: 12px;
          height: 12px;
          margin: 0;
        }

        &.waiting {
          background-color: #ffff00;
          width: 12px;
          height: 12px;
          margin: 0;
        }
      }
    }

    div:nth-child(1) {
      justify-content: flex-start;
      font-weight: bold;
      width: 108px;
      margin-right: 0;
      margin-left: 0;
    }

    div:nth-child(2) {
      width: auto;
      font-weight: bold;
      margin-right: 0;
      margin-left: 0;
    }

    div:nth-child(3) {
      justify-content: flex-start;
      margin-left: 6px;
      width: auto;
    }
  }
}

.box {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // width: 300px;
  // height: 300px;
  // background: #111845a6;
  // box-sizing: border-box;
  // overflow: hidden;
  // box-shadow: 0 20px 50px rgb(23, 32, 90);
  // border: 2px solid #2a3cad;
  // color: $bgColorWhite;
  // padding: 20px;

  position: relative;
  /* top: 50%; */
  /* left: 50%; */
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  width: 100%;
  /* height: 300px; */
  // background: #111845a6;
  box-sizing: border-box;
  overflow: hidden;
  /* box-shadow: 0 20px 50px #17205a; */
  /* border: 2px solid #2a3cad; */
  color: $bgColorWhite;
  padding: 20px;
  right: 0;
}

.box:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  transition: 0.5s;
  pointer-events: none;
}

.box:hover:before {
  left: -50%;
  transform: skewX(-5deg);
}

.box .content {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border: 1px solid #f0a591;
  padding: 20px;
  text-align: center;
  box-shadow: 0 5px 10px rgba(9, 0, 0, 0.5);
}

.box span {
  // position: absolute;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  // display: block;
  // box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  display: block;
  box-sizing: border-box;
}

.box span:nth-child(1) {
  transform: rotate(0deg);
}

.box span:nth-child(2) {
  transform: rotate(90deg);
}

.box span:nth-child(3) {
  transform: rotate(180deg);
}

.box span:nth-child(4) {
  transform: rotate(270deg);
}

.box span:nth-child(2) {
  position: absolute;
  top: 0;
  left: 95.9%;
  width: 40px;
  height: 40px;
  display: block;
  box-sizing: border-box;
}

.box span:nth-child(4) {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 100%;
  display: block;
  box-sizing: border-box;
}

.box span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #50dfdb;
  animation: animate 4s linear infinite;
}

@keyframes animate {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  50% {
    transform: scaleX(1);
    transform-origin: left;
  }
  50.1% {
    transform: scaleX(1);
    transform-origin: right;
  }

  100% {
    transform: scaleX(0);
    transform-origin: right;
  }
}

$bgColor: #020438;
$boxColor: #020438;
$waterFColor: #4d6de3;
$waterBColor: #c7eeff;

// body {
//   background:$bgColor;
//   font: 14px/1 'Open Sans', helvetica, sans-serif;
//   -webkit-font-smoothing: antialiased;
// }

.box {
  height: 280px;
  width: 280px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //   background: $boxColor;
  overflow: hidden;
  .percent {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 64px;
  }
  .water {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    transform: translate(0, 100%);
    background: $waterFColor;
    transition: all 0.3s;
    &_wave {
      width: 200%;
      position: absolute;
      bottom: 100%;
      &_back {
        right: 0;
        fill: $waterBColor;
        animation: wave-back 1.4s infinite linear;
      }
      &_front {
        left: 0;
        fill: $waterFColor;
        margin-bottom: -1px;
        animation: wave-front 0.7s infinite linear;
      }
    }
  }
}
@keyframes wave-front {
  100% {
    transform: translate(-50%, 0);
  }
}

@keyframes wave-back {
  100% {
    transform: translate(50%, 0);
  }
}

.wave {
  // position: relative;
  // width: 200px;
  // height: 50px;
  // margin: 12.5px;
  // overflow: hidden;

  // font-size: 10px;
  // color: $bgColorWhite;
  //     text-decoration: none;
  //     letter-spacing: 5px;
  // box-shadow: 0px 5px 10px rgba(0,0,0,.4);
  // background: #177E72;
  // transition: .15s ease;
  position: absolute;
  width: 100%;
  /* height: 150px; */
  /* margin: 12.5px; */
  /* overflow: hidden; */
  font-size: 10px;
  color: $bgColorWhite;
  text-decoration: none;
  letter-spacing: 5px;
  /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4); */
  /* background: #177E72; */
  -webkit-transition: 2s ease;
  transition: 2s ease;
  bottom: -12px;
  left: -12px;

  span {
    position: absolute;
    z-index: 100;
    background: #1b998b;
    border-radius: 40%;
    animation: liquid-1 4s linear infinite;
  }
  /* BUTTON 1 */
  .liquid-1 {
    width: 500px;
    height: 500px;
    top: 15%;
    left: calc(50% - 250px);
  }
  /* BUTTON 2 */
  .liquid-2 {
    width: 600px;
    height: 600px;
    top: 80%;
    left: calc(50% - 250px);
    animation: liquid-1 4s ease-in-out infinite reverse;
  }
  .liquid-2:nth-of-type(2) {
    top: 40%;
    animation: liquid-1 4s ease-in infinite reverse;
  }
  /* BUTTON 3 */
  .liquid-3 {
    width: 300px;
    height: 300px;
    top: 50%;
    left: calc(50% - 150px);
    animation: liquid-1 4s linear infinite reverse;
  }
  .liquid-3:nth-of-type(2) {
    animation: liquid-1 2s ease-in infinite reverse;
  }
  .liquid-3:nth-of-type(3) {
    animation: liquid-1 2s ease-out infinite reverse;
  }
  /* BUTTON 4 */
  .liquid-4 {
    width: 600px;
    height: 600px;
    top: 50%;
    left: calc(50% - 300px);
    animation: liquid-2 4s linear infinite 0.25s;
    opacity: 0.3;
    // background: rgb(47, 120, 162, .7);
    background: linear-gradient(
      0deg,
      #ffffff -20%,
      #ffffff00 30%,
      #ffffff00 30%,
      #ffffff 120%
    );
  }
  .liquid-4:nth-of-type(2) {
    animation: liquid-2 4s linear infinite 0.5s;
    // background: rgb(68, 87, 171,.7);
  }
  .liquid-4:nth-of-type(3) {
    animation: liquid-2 4s linear infinite 0.75s;
    // background: rgb(116, 89, 180, .7);
  }
  .liquid-4:nth-of-type(4) {
    animation: liquid-2 4s linear infinite 1s;
    // background: rgb(93, 105, 211, .7);
    opacity: 0.2;
  }

  /* BUTTON 5 */
  .liquid-5 {
    width: 200px;
    height: 200px;
    top: 0%;
    left: -40%;
    animation: liquid-2 2s linear infinite alternate 0.25s;
  }
  /* ANIMATIONS */
  @keyframes liquid-1 {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(359deg);
    }
  }
  @keyframes liquid-2 {
    0% {
      transform: rotateZ(0deg) translateX(-10%);
    }
    100% {
      transform: rotateZ(359deg) translateX(10%);
    }
  }
}
.wave:hover {
  transform: scale(1.055);
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.5);
}
.wave::after {
  content: "";
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 500;
}

/*.................. Topbar animated circle Start.................. */

.animated-circle {
  position: absolute;
  // background-color: rgba(0, 0, 0, 0.07);
  // border: 1px solid black;
  width: 200px;
  height: 60px;
  left: 50px;
  overflow: hidden;

  .circle-first {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.07);
    width: 90px;
    height: 90px;
    border-radius: 50%;
    top: -90px;
    // animation: slide-top-to-bottom 2s ease-out both;
    -webkit-transform: translateY(105px);
    transform: translateY(105px);
  }
  .circle-second {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.07);
    width: 35px;
    height: 35px;
    border-radius: 50%;
    top: -75px;
    left: 35px;
    // animation: slide-top-to-bottom-delayed 2s ease-out .3s both;
    -webkit-transform: translateY(115px);
    transform: translateY(115px);
  }
  .circle-third {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.07);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    top: 60px;
    left: 55px;
    // animation: slide-bottom-to-top 2s ease-out both;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }

  .circle-first-anim {
    animation: slide-top-to-bottom 2s ease-out both;
  }
  .circle-second-anim {
    animation: slide-top-to-bottom-delayed 2s ease-out 0.3s both;
  }
  .circle-third-anim {
    animation: slide-bottom-to-top 2s ease-out both;
  }

  @keyframes slide-top-to-bottom {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(105px);
      transform: translateY(105px);
    }
  }

  @keyframes slide-top-to-bottom-delayed {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(115px);
      transform: translateY(115px);
    }
  }

  @keyframes slide-bottom-to-top {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-40px);
      transform: translateY(-40px);
    }
  }
}

/*.................. Topbar animated circle End.................. */

.ocean {
  height: 0%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #015871;
}

.wave {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg)
    repeat-x;
  position: absolute;
  top: -198px;
  width: 6400px;
  height: 198px;
  animation: wave 3s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: -175px;
  animation: wave 3s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 3s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

.event-count {
  div {
    .event-0,
    .event-1,
    .event-2,
    .event-3 {
      background: rgb(255, 222, 222);
      background: linear-gradient(
        312deg,
        rgba(61, 210, 86, 0.4) 0%,
        rgba(61, 210, 86, 0.1) 25%,
        rgba(219, 228, 255, 1) 25%,
        rgba(255, 255, 255, 1) 100%
      );
      padding: 10px;
      border: 1px solid rgb(219, 228, 255);
      // color: #ffffff;
    }
  }
}

@import "_globalStyle_nw4_responsive.scss";
