
.nw-tablebody-area{
    
    .p-datatable{
        height: 106px;
       
        .p-datatable-wrapper{
            height: 100%;
            table{
                height: 100%;
                .p-datatable-tbody{
                   
                    tr{
                        td{
                            padding: 5px;

                            .p-inputtext{
                                padding: 10px;
                            }

                            
                        }

                    }
                }

                .p-datatable-thead{
                    padding: 0px;

                    tr{
                        th{
                            padding: 0px;
                        }
                    }
                }
            }

        }
    }

}


.nw-data-table-new{
    .p-datatable{
        margin: 0;
        font-size: 14px;
        .p-datatable-header{
            background-color: #68B4F1;
            height: 40px;
            padding: 6px;
            border-radius: 0px;
            div{
                a{
                    color: white;
                    display: flex;
                    float: right;
                    background: #E13685;
                    padding: 7px 0 0 16px;
                    margin: -7px -7px 0px 0px;
                    height: 40px;
                }
            }
        }
        .p-datatable-wrapper{
            table{
                .p-datatable-tbody{
                    tr{
                        height: 50px !important;
                        td{
                            border: 1px solid #D2EAFF
                        }
                    }
                    tr:nth-child(even) {
                        background: #ffffff;
                    }
                    tr:nth-child(odd) {
                        background: #E6F4FF;
                    }
                }
            }
        }
    }
}


.nw-form{
    div{
        .nw-form-body{
            div{
                .p-inputgroup{
                    .text-label{
                        padding:5px 14px;
                    }
    
                }
            }
            

        }
    }
    
}

.nw-form{
    div{
        .nw-form-body{
            div{
                div{
                    div{
                        div{
                            .text-label{
                                padding:5px 14px;
                            }
                        }
                    }
                }
            }
            

        }
    }
    
}

.nw-form{
    div{
        .nw-form-body{
            div{
                div{
                    div{
                        .text-label{
                            padding:5px 14px;
                         }
                    }
                }
            }
            

        }
    }
    
}

.p-grid{
    .nw-tabview{
        .p-tabview-top{
            .p-tabview-panels{
                div{
                    .p-fluid{
                        .p-grid{
                            div{
                                .nw-form{
                                    .no-padding-top-bottom{
                                        div{
                                            .text-label{
                                                padding:5px 14px;
                                             }
                                        }

                                    }

                                }
                            }

                        }
                    }
                }
            }
        }
    }
}




.nw-card-view{
                 
         text-align:center;

    .p-card-header{
        .img-size{
            width:62%;
            height:100%
        }
        

    }
    .p-card-body{
        .p-card-content{
            .center-block{
                .img-size{
                    width:60%;
                    height:100%
                }
            }

        }
    }

}


.nw-tabview-new{
    .p-tabview{
        .p-tabview-panels{
            padding-top: 10px !important;
        }

    }

}