$dashboard-card-theme-one-color:   #009485;
.crm-dashboard{

    .dashboard-card-view{
        
        // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

        .p-card{
            background-color: transparent;
            box-shadow: none;
            background: #E8F0FD;
            border-radius: 10px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            // padding: 0;
            // text-align: center;
            position: relative;
            height: fit-content;
            // min-height: 206px;
            overflow: hidden;

            .p-card-header{
                padding: 10px;
                font-size: 16px;
                font-weight: 700;
                color: #ffffff;
                background-color: #0099ff
                
            }
            
            &.bg-pending{
                .p-card-header{
                    background-color: $warning-color;
                }
            }

            &.bg-success{
                .p-card-header{
                    background-color: $success-color;
                }
            }

            &.bg-danger{
                .p-card-header{
                    background-color: $danger-color;
                }
            }

            &.bg-eduman{
                .p-card-header{
                    background-color: $eduman-theme-color;
                }
            }
            &.bg-purple{
                .p-card-header{
                    background-color: $bgPurple;
                }
            }
            &.bg-purple-light{
                .p-card-header{
                    background: $bgPurpleLight;
                }
            }
            &.bg-success-dark{
                .p-card-header{
                    background: $bgsuccessDark;
                }
            }

            .p-card-body{
                padding: 0;

                .p-card-content{
                    .body{
                        border-radius: 10px;
                        background-color: #{$dashboard-card-theme-one-color}20;
                    }

                    .nw-data-table, .nw-datatable-detail-view{
                        margin: 0;

                        .p-datatable{
                            .p-datatable-wrapper{
                                table{
                                    .p-datatable-thead{
                                        display: none;
                                    }

                                    .p-datatable-tbody{
                                        .circle-panel {
                                            width: 60px;
                                            height: 60px;
                                            margin: 0 0 0 auto;
                                            text-align: center;
                                            border-radius: 50%;
                                            // padding: 10px 0 0 0;
                                            font-size: 18px;
                                            font-family: arial, sans-serif;
                                            // background: #B4C6D2;
                                            display: flex;
                                            justify-content: center;
                                            color: #ffffff;
                                        }

                                        .rectangle-panel{
                                            width: 80px;
                                            height: 40px;
                                            margin: 0 0 0 auto;
                                            text-align: center;
                                            border-radius: 5px;
                                            // padding: 10px 0 0 0;
                                            font-size: 18px;
                                            font-family: arial, sans-serif;
                                            background: #2E87CF;
                                            display: flex;
                                            justify-content: center;
                                            color: #ffffff;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .nw-datatable-detail-view{
                        .p-datatable{
                            max-height: 400px;
                            overflow: auto;
                        }
                    }

                    .info-box-wrapper{

                    }

                    .netiWorld-DashBoard-wrapper{
                        margin: 0;
                    }

                    
                }
            }

            // &.theme-one{
            //     .p-card-header{
            //         background-color: $dashboard-card-theme-one-color;
            //     }
            // }


        }
    }
}