.accrodion-section{
    background-color: #ffffff;
    margin: -1px 4px 10px 4px !important;
    border: 1px solid #EBEBEB;
    padding: 20px;
    min-height: 89vh;

    .p-accordion{
        .p-accordion-tab{
            .p-accordion-header{
                height: 44px;
                
                a{
                    background-color: $datatable-headerBg;
                    height: 100%;
                    padding: 0px;
                    border-radius: 0px;
                    border: none;
                    text-align: left;
                    font-size: 16px;
                    font-weight: 500;
                    color: $textColorWhite;
                    border: none;
                    border-radius: unset;
                    display: flex;
                    align-items: center;
                    // transition: all .5s ease-in-out;

                    .p-accordion-toggle-icon{
                        color: $textColorWhite !important;
                        position: absolute;
                        right: 10px;
                    }

                    .p-accordion-header-text{
                        margin-left: 20px;
                    }
                }

                &:not(.p-disabled).p-highlight a {
                    background-color: $datatable-headerBg;
                    border: unset;
                    color: $textColorWhite;
                }

                &:not(.p-highlight):not(.p-disabled):hover a {
                    font-weight: 700;
                }
                
            }

        }
    }

    .main-section{
        background-color: #ffffff;
        margin: 0px !important;
        border: none;
        padding: 0;
        min-height: 0;
    }

    .nw-form {
        // background-color: #ffffff;
        margin: 0px !important;
        border: none !important;
        padding: 0px !important;
    }

    .nw-data-table {
        margin: 10px 7px;
    }


}