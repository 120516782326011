    /**Center div**/
    
    * {
        margin: 0;
        padding: 0;
    }
    
    .custom-layout-wrapper {
        display: table;
        overflow: hidden;
        margin: 0px auto;
    }
    
    *:first-child+html .custom-layout-wrapper {
        position: relative;
    }
    /*ie7*/
    
    * html .custom-layout-wrapper {
        position: relative;
    }
    /*ie6*/
    
    .layout-content {
        display: table-cell;
        vertical-align: middle;
    }
    
    *:first-child+html .layout-content {
        position: absolute;
        top: 50%;
    }
    /*ie7*/
    
    * html .layout-content {
        position: absolute;
        top: 50%;
    }
    /*ie6*/
    
    *:first-child+html #content {
        position: relative;
        top: -50%;
    }
    /*ie7*/
    
    * html .custom-login-box {
        position: relative;
        top: -50%;
    }
    /*ie6*/
    
    html,
    body {
        height: 100%;
    }
    
    .custom-layout-wrapper {
        height: 100%;
        width: 465px;
    }
    /**Center div**/
    
    .custom-layout-wrapper {
        background: #54eadc;
        /* Old browsers */
        background: -moz-linear-gradient(left, #54eadc 0%, #64b5f6 99%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(left, #54eadc 0%, #64b5f6 99%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, #54eadc 0%, #64b5f6 99%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#54eadc', endColorstr='#64b5f6', GradientType=1);
        /* IE6-9 */
        width: 100%;
        height: 100%;
        margin: 0;
        overflow-x: hidden;
    }
    
    .custom-layout-wrapper {
        position: absolute;
        margin: auto;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        min-height: 600px;
    }
    
    .custom-login-box {
        border-radius: 8px;
        box-shadow: 0 0 5px;
        background: #ffffff8d;
        margin-top: 20px;
    }
    
    .custom-login-box .p-inputtext,
    .p-inputgroup-addon,
    button {
        font-size: 22px;
    }
    
    .login-a {
        color: rgb(32, 143, 42) !important;
        font-weight: bold;
    }
    
    .custom-login-button>span {
        font-size: 18px;
    }
    
    .forget-password {
        margin: 10px;
        padding: 20px;
        box-shadow: #ffffff 0 0 5px;
    }
    
    .custom-label {
        left: 1em !important;
        font-size: 20px;
        color: #6b6b6b;
    }
    
    .custom-login-box .p-inputtext,
    .custom-login-box .p-inputgroup-addon,
    .custom-login-box button {
        font-size: 16px;
    }
    
    .custom-inner-field .p-inputtext,
    .custom-inner-field .p-inputgroup-addon,
    .custom-inner-field button {
        font-size: 16px;
    }
    
    .custom-field-control.p-inputtext,
    .custom-field-control .p-inputtext {
        font-size: 16px !important;
    }
    
    .custom-inner-field calendar.p-calendar-w-btn .p-inputtext {
        width: calc(100% - 1.9em);
    }
    
    .color-red {
        color: #EF5350;
    }
    
    .color-white {
        color: #ffffff;
    }
    
    .custom-field-control {
        width: 100%;
        border: none;
        border-radius: 3px 0px 0px 3px;
        font-size: 16px;
        padding-left: .5em;
    }
    
    .p-button-secondary-custom {
        color: #212121 !important;
        background-color: #eeeeee !important;
        border: 1px solid #E0E0E0 !important;
    }
    
    .custom-error-field {
        border: 3px solid #EF5350 !important;
    }
    
    .custom-error-field:enabled:focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2em #EF5350 !important;
        -moz-box-shadow: 0 0 0 0.2em #EF5350 !important;
        box-shadow: 0 0 0 0.2em #EF5350 !important;
        border-color: #EF5350 !important;
    }
    
    .p-message-error {
        margin-bottom: 5px !important;
    }
    
    .forget-password-link {
        widows: 100%;
        text-align: right;
    }
    
    .forget-password-link a {
        widows: 100%;
        color: rgb(22, 119, 27);
        font-weight: bold
    }
    
    .custom-overview-subinfo {
        font-size: 16px !important;
    }
    
    .custom-overview-subinfo>b {
        font-size: 20px;
    }
    
    .custom-user-card-content img {
        border: 3px solid white;
        border-radius: 50%;
    }
    
    .user-card-id {
        font-size: 20px;
        color: #6b6b6b;
        position: relative;
        /* top: -120px;
    margin-left: 100px; */
        font-weight: 700;
        white-space: nowrap;
    }
    
    .statusBGclassActive {
        color: #00c177 !important;
        font-weight: bold;
    }
    
    .statusBGclassDisActive {
        color: #f90000 !important;
        font-weight: bold;
    }
    
    .layout-content {
        display: block;
    }
    
    .layout-dashboard .user-card .user-card-content .p-button {
        z-index: 1;
    }
    
    .text-capitalize-transform {
        text-transform: capitalize;
    }
    
    .layout-wrapper .route-bar .route-bar-breadcrumb {
        padding: 6px 18px !important;
        display: inline-block;
    }
    
    .route-bar-breadcrumb li a {
        color: #727272;
    }
    
    .custom-calender-input {
        width: 95%;
    }
    
    .custom-calender-input .p-inputtext {
        width: 100%;
        font-size: 16px;
    }
    
    .p-message {
        width: 100%;
    }
    
    .text-center {
        text-align: center;
    }
    
    body .p-message.p-message-success {
        margin-bottom: 5px;
    }
    /***Specing***/
    
    .m-b-none {
        margin-bottom: 0px !important;
    }
    
    .statusBGclassActive {
        color: #00c177 !important;
        font-weight: bold;
    }
    
    /* .custom-p-inputgroup-addon {
        width: 13em;
        font-size: 13px;
        padding: 6px !important;
        font-weight: bold;
    } */
    
    .p-dropdown-filter-container {
        display: block !important;
    }
    
    .layout-dashboard .user-card .user-card-content .p-button {
        z-index: 1;
    }
    
    .-custom-p-grid {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -0.5em;
        margin-left: -0.5em;
        margin-top: -0.5em;
    }
    
    .custom-p-grid>ul>li {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    
    .align-center {
        text-align: center;
    }
    
    .custom-image-shadow {
        box-shadow: gray 0 0 5px 1px;
    }
    
    .pull-right {
        float: right;
    }
    
    .pull-left {
        float: left;
    }
    
    .custom-add-button {
        height: 60px !important;
        width: 60px !important;
        border-radius: 50% !important;
        border: none !important;
        top: 20px !important;
        font-size: 35px !important;
    }
    
    .customDialogWidth {
        width: 40%;
    }
    
    @media screen and (max-width: 1024px) {
        .custom-calender-input .p-inputtext {
            width: 95%;
        }
    }
    
    @media screen and (max-width: 767px) {
        .customDialogWidth {
            width: 95%;
        }
    }
    
    .no-padding-top-bottom {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    /* for main content background color */
    /* .layout-wrapper .layout-main .layout-content{
    background-color: #ffffff !important;
  } */
    
    /* .fieldset-box-parent {
        display: flex;
        position: relative;
        height: 250px;
    }
    
    .fieldset-box-content {
        top: 13px;
        right: 0;
        position: absolute;
    } */
    
    .padding-top-medium {
        padding-top: 20px;
    }
    
    .layout-root-menuitem>div {
        color: wheat !important;
    }
    
    .custom-loader {
        position: absolute;
        margin: auto;
        width: 20px;
        left: 0;
        right: 0;
        z-index: 1004;
    }
    
    #login-left-anim {
        position: absolute;
        margin: auto;
        width: 400px;
        bottom: -5px;
        left: 0;
        z-index: 1004;
        /* transform: scaleX(-1) */
    }
    
    .custom-center {
        position: relative;
        width: 250px;
        /* height: 100px; */
        top: 40%;
        /* bottom: 50%; */
        margin: auto;
    }
    
    .custom-center>i {
        font-size: 55px
    }
    
    .admisia-table tr td {
        width: 210px;
    }
    
    .admisia-table tr td:nth-child(1) {
        text-align: right;
        font-weight: bold
    }
    
    .admisia-table tr td:nth-child(2) {
        text-align: center;
        font-weight: bold;
        width: 50px;
    }
    
    .tooltip {
        position: relative;
        display: inline-block;
        border-bottom: 1px dotted black;
    }
    
    .tooltip .tooltiptext {
        visibility: hidden;
        min-width: 278px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        bottom: 100%;
        left: 50%;
        margin-left: -60px;
    }
    
    .tooltip:hover .tooltiptext {
        visibility: visible;
    }
    
    @media screen and (max-width: 1200px) {
        .custom-center {
            position: unset;
        }
        .custom-center>i {
            transform: rotate(90deg);
        }
    }

    .fileUpload-input-field{
        margin: 0;
        color: #ffffff;
        background-color: #2196F3;
        border: 1px solid #2196F3;
        font-size: 14px;
        -moz-transition: background-color 0.3s, box-shadow 0.3s;
        -o-transition: background-color 0.3s, box-shadow 0.3s;
        -webkit-transition: background-color 0.3s, box-shadow 0.3s;
        transition: background-color 0.3s, box-shadow 0.3s;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        padding: 7px;

    }

    .wordwrap { 
        word-break:break-all;
    }

    .dataScroller-no-border .p-datascroller-content {
        border:0 !important;
        padding:0 !important;
    }
    /* .reportCls{
        background: #64b5f6;
    } */
    .role-dropdown {
        height: 45px;
        width: 100%;
        color: #000;
        border: 1px solid #e7e7e7;
        outline: none;
        padding: inherit;
        font-size: 14px;
    
        background: linear-gradient(
          180deg,
          #ffffff75 0%,
          #ffffff30 40%,
          transparent
        );
    
    }
    .dropdown{
        /* border: 2px solid #e7e7e7;
        border-radius: 2px;
        position: absolute;
        display: inline-block;
        right: 7px;
        top: 0;
        cursor: pointer; */
        /* border: 3px solid #e7e7e7; */
        border-radius: 2px;
        position: absolute;
        display: inline-block;
        right: -5px;
        top: -12px;
        cursor: pointer;
        width: 90px;
        height: 45px;
        text-align: center;
    }
    .dropdown .dropbtn{
        font-size: 17px;
        border: none;
        outline: none;
        color: white;
        padding: 10px 12px;
        background-color: inherit;
        font-family: inherit;
        margin: 0;
        cursor: pointer;
    }
    .dropdown-content {
        display: none;
        position: absolute;
        right: 33px;
        background-color: #fcfcfc;
        font-family: SegoeUI!important;    
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        transition:all;
    }
    .dropdown:hover .dropdown-content{
        display: block;
        transition:all .4s ease-in-out
    }

    .dropdown-content a {
        color: black;
        padding: 10px 0;
        text-decoration: none;
        display: block;
        /* text-align: left; */
      }
      .dropdown-content a:hover {
        background-color: #2196F3;
        color: black;
      }

      
      

 