/*........... default common element start ...........*/

.change-pos {
  left: 0;
}

.form-fixed-width-none {
  max-width: none !important;
}

.p-checkbox-box {
  background: $inputgroupAddonBGColor !important;
  border: $inputgroupAddonBorder !important;
  border-radius: 0 !important;
}

.p-checkbox-box.p-highlight {
  color: $textColorPrimary !important;
}

.p-checkbox-label {
  padding-top: 0px !important;
}

.task-badge {
  // position: absolute;
  // /* right: 12px; */
  // /* top: 50%; */
  // margin-top: 4px;
  // width: 12px;
  // height: 12px;
  // border-radius: 50%;
  // left: 3px;
  position: relative;
  /* right: 12px; */
  /* top: 50%; */
  /* margin-top: 4px; */
  width: 12px;
  height: 12px;
  border-radius: 50%;
  left: 0px;
  margin: 0 10px 0 8px;
}

.task-badge.online {
  background-color: #00e38f;
}

.task-badge.offline {
  background-color: rgb(182, 182, 182);
}

.task-badge.found {
  background-color: #c4e5ff;
}

.seperator-vr {
  // background-color: #67edff21;
  /* width: 3px !important; */
  display: flex;
  justify-content: center;
  align-items: center;

  .seperator-vr-inside {
    height: 100%;
    width: 9px;
    border-left: 2px dashed #d2eaff;
    border-right: 2px dashed #d2eaff;
  }
}

.seprator {
  position: relative;

  .seprator-inside {
    & > div {
      padding-right: 54px;
    }
  }

  .seprator-inside::after {
    width: 5px;
    border-left: 1px dashed #d2eaff;
    /* border-width: 2px; */
    border-right: 1px dashed #d2eaff;
    content: " ";
    height: 95%;
    position: absolute;
    pointer-events: none;
    right: 0;
    /* margin-right: 5px; */
    top: 8px;
    // bottom: 0;
    margin: auto 20px;
  }

  //for diff seperator style

  .seprator-insidediff {
    & > div {
      padding-right: 54px;
    }
  }

  .seprator-insidediff::after {
    width: 5px;
    border-left: 1px dashed #d2eaff;
    /* border-width: 2px; */
    border-right: 1px dashed #d2eaff;
    content: " ";
    height: 107%;
    position: absolute;
    pointer-events: none;
    right: 0;
    /* margin-right: 5px; */
    top: 8px;
    bottom: 0;
    margin: auto 20px;
  }
}

.p-component-overlay {
  // background: url(../../assets/images/header-bg.png);
  // filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.dialog-blur-effect {
  filter: blur(5px);
  // layout-menu-container, .nw-form:not(.p-dialog-visible)
}

.p-tabview.p-tabview-top .p-tabview-nav li a:not(.p-disabled):focus {
  box-shadow: inset 0 0 0px #90ceff;
}

/*........... default common element end ...........*/
