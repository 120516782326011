//set division color according to the number of division
$division-color-list: #EB5463 #5E9CEA #9ED26A #4FC0E8 #FA6C51 #46CEAC #AC92EA #FDCD56;

.portal-menu{
    position: absolute;
    // right: 40px;
    // top: 90px;
    right: 0;
    top: 0;
    z-index: 5 !important;
    padding: 5px;
    background:transparent;
    
    .portal-menu-button {
        font-size: 18px;
        font-weight: 600;
    }
    
    .p-button {
        background: transparent;
        border:none;
    }
    
    .p-button:enabled:hover, .p-button:enabled:active,.p-button:enabled:focus{
        @extend .p-button;
    }

}

@mixin portal-menu-wrapper ($menu_wrapper_class_name,$menu-icon-color) {
    
    .#{$menu_wrapper_class_name} {
        z-index: 900 !important;
        
        .p-menuitem-link {
            .p-menuitem-icon {
                color: #D1D1D1 !important;
                margin-right: 1em !important;
            }
            
            &:hover {
                .p-menuitem-icon {
                    color: $menu-icon-color !important;
                }
            }
        }
        
        .menu-item-active {
            .p-menuitem-icon {
                color: $menu-icon-color !important;
            }
        }
    }
}

@include portal-menu-wrapper('portal-menu-component',#42a5f5);
@include portal-menu-wrapper('portal-menu-component-teacher',#66BB6A);


.portal-dashboard {
    background-color: #42a5f5;
    margin: -25px -25px 25px -25px;
    padding: 52px 65px;

    .overview-box {
        border: solid 3px #ffffff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12) !important;
        padding: 10px 20px !important;

        .overview-title {
            font-size: 16px;
            font-weight: normal;
        }
        .class-config-text {
            font-size: 24px;
            font-weight: 600;
            text-transform: uppercase;
            //for text-wrapping
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
        }
    }
}

.right-sided-circle-pannel {

        padding-bottom: 30px !important;

        .circle-panel {
            width: 120px !important;
            height: 120px !important;
            padding: 50px 0 35px 0 !important;
        }

    .circle-panel-label {
        margin-top:64px !important;
    }
}

.full-width-circle-pannel {
    padding-bottom: 30px !important;

        .circle-panel {
            width: 120px !important;
            height: 120px !important;
            padding: 50px 0 35px 0 !important;
            margin: 25px 35px !important;
        }

    .circle-panel-label {
        margin-top:64px !important;
    }
}

.layout-wrapper .layout-topbar .topbar-menu > li > ul button .topbar-submenuitem-badge {
    background-color: #42a5f5;
    min-width: 18px;
    height: 18px;
}

.edit-icon-wrapper .pi-chevron-down::before {
    font-family: "font-awesome" !important;
    content: "" !important;
  }


  //admin-Point styles
  .nw-dialogue-data-table {
    @extend .nw-data-table;
 
    .p-datatable .p-datatable-header {
        background-color:#fff !important;
        color: #83A4B9 !important;
        font-size: 18px !important;
        font-weight: 600 !important;
        border: none !important;
        text-align: left !important;
        margin-left: 0 !important;
        padding-left: 0 !important;

        div {
            padding-left: 0 !important;
        }
     
    }

    .p-datatable .p-datatable-header div span{
        float: right;
        font-size: 14px;
        color: #004BA0 !important;
    }
    
    .header-nested-span{
        color: #FF8800 !important;
    }

    .p-datatable-thead{
        background: #E6F4FF !important;
        th {
            color: #002171 !important;
        }
    }
    
    .p-datatable-tbody tr{
            background: #fff !important;
        }
    
    .p-datatable-footer {
        background: #E6F4FF !important;
    }

    .p-datatable-tfoot{
        font-size: 18px !important;
        font-weight: 600 !important;
    }
   
  }

  .nw-search-view div div .p-inputgroup .p-inputgroup-info {
    float:right;
    font-weight: 200;
    margin-right: 15%;
    color: rgba(125,125,125,0.8);
    font-size: 13px;

    .p-inputgroup-info-value {
        font-weight: 700 !important;
        opacity: 1 !important;
        font-size: 14px;
    }
  }

  .p-inputgroup .p-inputgroup-info-value-color1{
      color: #0E75F8 !important;
    }
    .p-inputgroup .p-inputgroup-info-value-color2{
        color: #FF8800 !important;
    }
    .p-inputgroup .p-inputgroup-info-value-color3{
        color: #00AFD4 !important;
    }
    .p-inputgroup .p-inputgroup-info-value-color4{
        color: #7D7D7D !important;
  }


  //level revenue data table 
  .nw-box-data-table {
    .p-datatable {


        @at-root {

            @at-root .box-first-table{
                .p-datatable-header{
                background-color:#5C6D96 !important;
                }

                .p-datatable-thead {
                
                    th {
                        background-color:#5C6D96 !important;
                        border-right: 1px solid #697cab !important
                        }
                }
                .calculation-box-wrapper {
                    border: 1px solid #DBFFE1;
                    
                    .calculation-box-field input {
                        border-color: #DBFFE1 !important;
                        font-weight: 600;
                        opacity: .85;
                    }
                    .calculation-box-amount{
                        color: #3DD256 !important;

                        &::before{
                            filter: hue-rotate(280deg);
                        }
                    }

                    .percentSign::after {
                        right: 13px !important;
                    }
                }
            }
            
            @at-root .box-second-table{
                
                .p-datatable-header{
                    background-color:#697CAB !important;
                }
                
                .p-datatable-thead {
                    
                    
                    th {
                        position: relative;
                        background-color:#697CAB !important;
                        border-color: #697CAB;
                        border-right: 1px solid #697CAB !important;
                        
                    }
                    
                }
                .p-datatable-wrapper{
                    overflow: auto;
                    border-right: 1px solid #d2eaff !important;
                    border-left: 1px solid #d2eaff !important;
                }

                .p-datatable-tbody{
                    border-right: none !important;
                    border-left: none !important;
                }

                .calculation-box-wrapper {
                    border: 1px solid #D2EAFF;
                    
                    .calculation-box-field input {
                        font-weight: 600;
                        opacity: .85;
                    }

                    .calculation-box-amount{
                      
                    }

                    .percentSign::after {
                        right: 13px !important;
                    }
                }
            }
        }

        .p-datatable-header{
            color: #9DC8EE !important;
            font-size: 18px !important;
            font-weight: 600 !important;
            border: none !important;
            border-radius: 0px !important;
            text-align: left !important;
            padding: 10px 10px;

            &::after{
                    content: "";
                    z-index: 2;
                    box-shadow: 0px -10px 2px -10px rgba(0,0,0,.5) inset;
                    height: 32px;
                    position: absolute;
                    background: transparent;
                    width: 100%;
                    left: 0;
                }
            
        }
        
        
        .p-datatable-thead {

                th{
                    color: #fff !important;
                    border: none !important;
                    font-weight: 600 !important;
                    }
                }


        .p-datatable-tbody{
            border-left: 1px solid #D2EAFF;
            border-right: 1px solid #D2EAFF;

            tr {
                background: #fff !important;
                border-bottom: 1px solid #D2EAFF;

                td{
                    border: none !important;
                }
            }

            .noteView-wrapper{
                color: rgba(0,151,253,.8);
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                
                .p-checkbox {
                    .p-checkbox-box{
                        background: #fff !important;
                        border: 2px solid rgba(10,70,117,.8) !important;
                        border-radius: 2px !important;
                    }
                    .p-checkbox-icon{
                        background: #fff !important;
                        color:rgba(0,151,253,.8);
                        font-weight: 600;
                        font-size: 16px;
                    }
                }
                    .p-checkbox-label{
                        font-weight: 600;
                    }

                .noteView {
                    position: absolute;
                    left: 0;
                    margin-top: 30px;
                    padding: 0 14px;
                    opacity: 0.7;
                    font-weight: normal;

                    .view-note{
                        cursor: pointer;

                        &:hover{
                            color: rgb(0,151,253);
                        }
                    }
                    .view-note-icon{
                        color:rgb(0,151,253);
                        cursor: pointer;
                    }
                    .add-note{
                        color:rgba(10,70,117,.8);
                        cursor: text;

                        &:hover {
                            color:rgb(10,70,117);
                        }
                    }
                    .add-note-icon{
                        color:rgba(10,70,117, 1);
                        cursor: text;
                    }

                }
            }

            .calculation-box-amount{
                padding-top: 15px !important;

                &::before{
                    top:15px !important;
                }
            }
        }
        .p-datatable-footer{

        }
        .p-datatable-tfoot{

        }
            
        }
  }

  .noteView-op {
        // top: 445px !important;
        //left: 414px !important;
        padding: 10px 6px !important;
        z-index: 900 !important;

        .op-header {
            display: block;
            padding-bottom: 5px;
            font-weight: 600;
            color: rgba(0,151,253,.8);
        }
        .overlay-textArea {
            height: 112px;
            background: rgb(255, 255, 255);
            border: 1px solid #D2EAFF;
            overflow-wrap: normal !important;
            overflow: auto !important;
        }
        .op-button{
            width: 100% !important;
            margin-top: 5px !important;
        }
        .p-overlaypanel-close{
            background: rgba(249,24,24,.75) !important;
            
            &:hover{
                background: rgb(249,24,24) !important;
            }

        }

    }



    //ObserverPoint attendence Data table 
    .nw-attendence-data-table {
        .p-datatable {

            .p-datatable-header{
                background-image: linear-gradient(to left, #4472ef, #7767db);
                color: #fff !important;
                font-size: 18px !important;
                font-weight: 600 !important;
                border: none !important;
                border-radius: 0px !important;
                text-align: left !important;
                padding: 10px 10px;
    
                // &::after{
                //         content: "";
                //         z-index: 2;
                //         box-shadow: 0px -10px 2px -10px rgba(0,0,0,.5) inset;
                //         height: 32px;
                //         position: absolute;
                //         background: transparent;
                //         width: 100%;
                //         left: 0;
                //     }
                
            }
            
            
            .p-datatable-thead {
    
                    th{
                        background-color: #E3E3E3 !important;
                        font-size: 1.03rem;
                        border: none !important;
                        font-weight: 800 !important;
                        text-align: left !important;
                        padding: 0.3em .8em !important;
                        width: 100%;
                    }
            }
    
    
            .p-datatable-tbody{
                border-left: 1px solid #D2EAFF;
                border-right: 1px solid #D2EAFF;
    
                tr {
                    background: #fff !important;
                    border-bottom: 12px solid #E3E3E3;
                    min-height:120px;
                    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    
                    td{
                        border: none !important;
                        padding:14px !important;
                    }
                }
    

                .data-value-wrapper {
                    line-height: 1.5rem;
                    padding: auto;

                    .data-value {
                        width: 60px;
                        display: inline-block;
                    }
                    div .data-value:nth-child(1n){
                        margin-right: 8px;
                    }
                    .totalValue{
                        font-weight: 600;
                        font-size: 16px;
                    }
                    .male-icon{
                        color:#4A88DA;
                        padding: 0 8px 0 0;
                    }
                    .female-icon{
                        color:#D94452;
                        padding: 0 8px 0 0;
                    }

                }

                .total-institute-value{
                    font-size: 16px;
                    font-weight: 600;
                }
                    
                .division-wrapper{

                    display: flex;
                    justify-content: center;
                    cursor: pointer;

                    .division-text {
                        color:#fff;
                        font-weight: 600;
                        border-radius: 25px;
                        width: 120px;
                        padding: 8px;
                        text-align: center;
                    }

                    @at-root{
                        @each $current-color in $division-color-list {
                            $i: index($division-color-list, $current-color);
                            .division-text-color-#{$i} { 
                                background-color: $current-color;
                            }
                        }
                    }
                    
                }
    
            }

            .p-datatable-footer{
    
            }

            .p-datatable-tfoot tr{
                background-color: rgba(61,210,86,.9) !important;
                min-height:84px;

                .footer-head{
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 1.36;
                    letter-spacing: 0.35px;
                    text-align: left;
                    color: rgba(0, 0, 0, 0.8);
                    display: block;
                }
                .footer-value{
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 1.8;
                    letter-spacing: 0.5px;
                    text-align: left;
                    display: block;
                    color: #ffffff;
                }
            }
                
            }
        
    }