.nw-search-view{
    border-top: solid #E8E8E8;
    padding: 25px 0 25px 0;
    margin: 10px 0px 0px 0;
    border-top-style: double;

    div{
        div{
            padding: 0;

            .p-inputgroup{
                display: flex;
                justify-content: baseline;
                align-items: center;
                overflow: auto;

                &.nw-inputgroup-desc-parent{
                    align-items: baseline;

                    .nw-inputgroup-desc{
                        background-color: #E6F4FF !important;
                        color: #727272;
                        font-weight: 400;
                        padding: 5px 10px;

                        .nw-inputtexteditor{
                            &.only-view{
                                .p-editor-toolbar{
                                    display: none;
                                }
        
                                .p-editor-content{
                                    border: 0px solid;
        
                                    .ql-editor{
                                        padding: 5px 0;
                                    }
                                }
        
                            }
                        }
                            
                    }
                }

                .task-badge{
                    position: unset;
                    flex: 0 1 auto;
                    // width: 18px;
                    padding: 5px;
                    margin-top: 4px;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    left: 3px;
                }

                .p-inputgroup-addon{
                    // flex: 0 1 auto;
                    // background-color: #ffffff;
                    // height: 20px;
                    // min-width: 150px;
                    // font-size: 13px;
                    // margin: 5px 15px;
                    // font-weight: bold;
                    // text-align: left;
                    // color: #0A4675;
                    // border: none;
                    // padding: 2px;
                    // border-radius: 0;
                    flex: 0 1 auto;
                    background-color: #ffffff;
                    height: auto;
                    min-width: 140px;
                    font-size: 13px;
                    margin: 5px 5px;
                    font-weight: bold;
                    text-align: left;
                    color: #0A4675;
                    border: none;
                    padding: 2px;
                    border-radius: 0;
                }

                .p-inputgroup-colon{
                    flex: 0 1 auto;
                    font-size: 14px;
                    font-weight: bold;
                    color: #0A4675;
                }

                .nw-inputgroup-desc {
                    background: #ffffff !important;
                    height: auto;
                    font-size: 14px;
                    padding: 3px;
                    text-align: left;
                    /* width: 100%; */
                    flex: 1 1 auto;
                    color: #0E75F8;
                    margin: 5px 15px;
                    font-weight: 700;;

                    .p-button{
                        margin-left: 10px !important;
                        border-top: 0px solid #D2EAFF !important;
                        border-bottom: 0px solid #D2EAFF !important;
                        border-right: 0px solid #D2EAFF !important;
                        border-left: 0px solid #D2EAFF !important;
                    }

                    &.attachment-download-button{
                        .download-file-button{
                            .p-button{
                                margin-right: 5px !important;
                                margin-left: 0px !important;
                                border-bottom-right-radius: 3px;
                                border-top-right-radius: 3px;

                                .pi-download{
                                    font-size: 30px;
                                }
                            }
                        }

                        .download-button-text{
                            font-size: 12px;
                            color: #000000;
                        }
                    }
                }
            }
        }
    }
}