$login-pbg: #017a90;

$mobile: "only screen and (max-width : 600px)";
$tab: "only screen and (max-width : 990px)";
$desktop: "only screen and (max-width : 1366px)";

.gradiant-btn {
  background: linear-gradient(
    270deg,
    rgba(4, 116, 205, 1) 0%,
    rgba(10, 123, 213, 1) 35%,
    rgba(30, 147, 240, 1) 100%
  );
  width: 100%;
  padding: 8px 0px !important;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
  color: #fff;
  border: none;
  display: inline-block;
  font-size: 13px;
}

.content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-left {
  display: flex;
  align-items: center;
  justify-content: left;
}

.login-mobile-menu {
  display: none;
  @media #{$tab} {
    display: block;
  }
  ul.mobile-login-menu {
    list-style: none;
    display: flex;
    align-items: center;
    margin-right: 45px;
    li {
      float: left;
      a {
        display: flex;
        margin-right: 45px;
        font-size: 14px;
        color: #000;
        font-weight: 600;
        align-items: center;
        img,
        i {
          margin-right: 10px;
        }
        i {
          margin-top: 0px;
        }
      }
    }
  }
}

.box-shadow {
  box-shadow: 0px 11px 15px rgba(181, 181, 181, 0.5);
}

.mr-25 {
  margin-right: 25px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.nw-login-layout-wrapper {
  background: url(../images/login/global_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 75%;
  height: 100%;
  width: 100%;
  overflow: hidden;
  .nw-login-banner {
    height: 100vh;
    width: 100%;
    position: relative;
    background: url(../images/login/left-banner.jpg);
    background-size: cover;
    margin-top: 0;
    margin-left: 0;
    p.text-bottom {
      position: absolute;
      bottom: 0px;
      width: 100%;
      left: 0;
      color: #fff;
      font-size: 24px;
      font-weight: 500;
      background: url(../images/login/black_overlay.png);
      padding: 30px;
      background-size: cover;
      canvas {
        height: 6px;
        width: 52px;
        background: white;
        display: inherit;
        margin-bottom: 10px;
      }
    }
  }
  .login-menu {
    position: absolute;
    top: 0;
    z-index: 99;
    height: 170px;
    width: 49.7%;
    // background: url(../images/login/white_overlay.png);
    background: linear-gradient(
      180deg,
      #ffffff75 0%,
      #ffffff30 40%,
      transparent
    );
    padding-top: 15px;
    padding-left: 25px;
    ul {
      list-style: none;
      display: flex;
      align-items: center;
      // margin-right: 45px;
      li {
        margin: 0 16px;

        a {
          display: flex;
          // margin-right: 45px;
          font-size: 14px;
          color: #000;
          font-weight: 600;
          align-items: center;
          position: relative;
          width: 100%;
          // transition: all 1s ease;

          img,
          i {
            margin-right: 10px;
          }
          i {
            color: #000000;
            // margin-top: 7px;
          }

          &:hover:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 5px;
            background-color: #ff5656;
            top: -16px;
            // transition: all 1s ease;
          }

          &:hover {
            color: #ffffff;
          }
        }
      }
    }
  }

  .nw-login-layout {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .nw-login-form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    @media #{$tab} {
      position: fixed;
    }
    .login-form-layout {
      padding: 35px 60px;
      background: #fff;
      margin-left: -21px;
      position: relative;
      text-align: center;
      width: 532px;
      .p-col-12 {
        padding-top: 0;
      }
      @media #{$tab} {
        margin: 0 auto;
      }
      @media #{$mobile} {
        margin: 0 auto;
        max-width: 90%;
        padding: 40px 20px 0px 20px;
        height: auto;
        margin-top: 30px;
      }
      &::after {
        content: "";
        position: absolute;
        width: 15px;
        height: 100%;
        background: #ff5656;
        left: -15px;
        top: 0;
        @media #{$mobile} {
          display: none;
        }
      }
      h3 {
        text-transform: uppercase;
        letter-spacing: 4px;
        margin: 30px 0px 20px 0px;
        @media #{$mobile} {
          margin: 10px 0px 20px 0px;
        }
      }
      p {
      }
    }
  }

  .login-form-wrapper {
    width: 78%;
    margin: 20px auto 0px;
    @media #{$mobile} {
      width: 100%;
    }
    .p-inputgroup {
      position: relative;
      @media #{$tab} {
        display: flex;
        justify-content: center;
      }
    }
    .p-inputgroup .p-inputtext {
      height: 45px;
      width: 100%;
      background: transparent;
      border: 1px solid #e7e7e7;
    }
    .p-inputgroup .p-inputgroup-addon {
      background-color: transparent;
      border-right: none;
      padding: inherit;
      span {
        color: #000;
        padding-top: 11px;
        display: inline-block;
        font-size: 14px;
        font-weight: 300;
      }
    }
    .required-icon {
      // position: absolute;
      // right: 6px;
      // top: 15px;
      // color: #B1B1B1;
      position: absolute;
      right: 0px;
      color: #b1b1b1;
      font-size: 20px;
      padding: 10px 10px;
    }
  }

  .nw-forget-password {
    border-bottom: 1px solid #dfdfdf;
    padding: 15px 0px;
    b {
      font-size: 13px;
    }
    u {
      text-decoration: none;
      color: #0080ff;
      font-size: 13px;
    }
  }
  .nw-signup {
    p {
      font-size: 13px;
    }
  }
  .login-footer {
    position: relative;
    top: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media #{$mobile} {
      top: 55px;
      display: inline-block;
    }
    img {
      margin-right: 15px;
      @media #{$mobile} {
        display: block;
        margin: 0 auto;
      }
    }
    span {
      font-size: 9px;
      letter-spacing: 5px;
    }
  }

  .language-change {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: -35px;
    ul {
      list-style: none;
    }
    .language {
      background-color: #017a90;
      padding: 5px;
      border-radius: 4px 4px 0px 0px;
      .p-button {
        background-color: #017a90;
        border: 0px solid #e0e0e0;
        color: #fff;
        outline: 0;
        outline: 0;
        font-size: 11px;
        font-weight: 600;
      }
      .p-button.p-component.p-button-text-only.p-highlight {
        background-color: #fff !important;
        color: $login-pbg;
        border-radius: 4px;
      }
    }
  }
}

.nw-global-background {
  background: url(../images/login/global_bg.jpg);
  background-size: cover;
  overflow-x: hidden;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: left;

  &.otp-layout {
    background: none !important;
    height: auto !important;

    .nw-layout-content {
      .global-login-form {
        p {
          font-size: 14px !important;
        }
        .p-float-label {
          h3 {
            color: #2196f3;
            font-size: 16px;
          }
        }
      }
    }
  }
  .nw-layout-content {
    width: 700px;
    background: #fff;
    min-height: 476px;
    position: relative;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    @media #{$mobile} {
      height: auto;
      background-position: 15% 0;
      width: 90%;
    }
    .login-welcome-text {
      padding: 200px 0px 0px 300px;
      @media #{$desktop} {
        padding: 100px 0px 0px 150px;
      }
      @media #{$tab} {
        padding: 150px 0px 0px 0px;
        text-align: center;
      }
      @media #{$mobile} {
        padding: 60px 0px 0px 0px;
      }
      .overview-subinfo {
        font-size: 26px;
        font-weight: 300;
        margin-bottom: 40px;
        @media #{$desktop} {
          margin-bottom: 30px;
        }
        @media #{$tab} {
          margin-bottom: 20px;
        }
        @media #{$mobile} {
          margin-bottom: 10px;
          font-size: 20px;
        }
      }
    }
    .copyright {
      display: flex;
      position: relative;
      // bottom: -36px;
      align-items: center;
      // top: 35px;
      padding: 40px 0;
      @media #{$desktop} {
        width: 100%;
      }
      @media #{$mobile} {
        display: initial;
        text-align: center;
        position: relative;
        bottom: -20px;
        padding-bottom: 15px;
        left: 0;
      }
      img {
        margin-right: 5px;
        @media #{$mobile} {
          display: block;
          margin: 0 auto;
        }
      }
      span {
        font-size: 9px;
        letter-spacing: 0.33em;
        color: #122d80;
      }
    }
    .global-login-form {
      padding: 60px 0;
      width: 305px;
      @media #{$desktop} {
        // padding-top: 60px;
      }
      @media #{$mobile} {
        padding: 50px 0px;
        width: 100%;
        // padding-top: 60px;
      }
      img#layout-menu-logo {
        margin-bottom: 30px;
      }
      h3 {
        font-size: 16px;
        letter-spacing: 0.2em;
        margin-bottom: 10px;
      }
      p {
        margin-bottom: 15px;
        font-size: 11px;
      }
      .p-float-label {
        width: 100%;
        margin: 0 auto;
        .verify-code {
          .p-inputtext {
            width: 40px;
            height: 40px;
            margin: 0 3px 20px 0;
          }
        }
        .p-inputtext,
        .p-dropdown,
        .p-calendar {
          background-color: #ffffff;
          width: 100%;
          height: 40px;
          padding: 10px 15px 10px 10px;
        }
        .p-calendar {
          // margin-left: -25px;
          padding: 0;

          .p-inputtext {
            // width: 92%;
            // float: left;
          }

          .p-button {
            margin-left: -2.143em;
          }
        }
        .gradiant-btn {
          width: 100%;
          padding: 8px 0px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          span.p-button-text.p-c {
            font-size: 13px;
          }
        }
        span.required-icon {
          // position: absolute;
          // right: 0px;
          // color: #B1B1B1;
          // font-size: 20px;
          // padding: 10px 10px;
          position: absolute;
          right: 7px;
          color: #b1b1b1;
          font-size: 20px;
          padding: 6px 0px;
          width: 30px;
          color: red;
        }
      }
      .user-info {
        width: 100%;
        margin: 10px 0px;
        position: relative;
        .p-card {
          box-shadow: none;
        }
        .p-card-content {
          box-shadow: none;
          span {
            display: inline-block;
            text-align: left;
            padding: 5px 0px;
            min-width: 95px;
          }
        }
        .p-button.p-button-text-icon-left .p-button-text {
          text-align: center;
          font-size: 13px;
        }
        .p-button-icon-only .p-button-icon-left,
        .p-button-text-icon-left .p-button-icon-left,
        .p-button-text-icon-right .p-button-icon-right {
          left: 14%;
          margin-top: -13px;
        }
      }
      .process-step {
        li {
          list-style: none;
          margin: 10px 20px 10px;
          height: 32px;
          width: 32px;
          line-height: 32px;
          border-radius: 20px;
          border: 1px solid #ebebeb;
          background: transparent;
          color: #000;
          font-size: 16px;
          position: relative;
          display: inline-block;

          &::after {
            content: "";
            position: absolute;
            width: 40px;
            height: 1px;
            background: #f2f2f2;
            top: 14px;
            left: 31px;
          }
        }
        li:last-child {
          &::after {
            height: 0;
          }
        }
        li.active {
          background: #17e7ff;
        }
        li.finish {
          background: #17e7ff;
          color: #ffff;
        }
      }
    }
  }
}

.verify-code .mobile-last-three-digit {
  display: flex;
  margin-bottom: 10px;
  input {
    // margin-right: 10px;
    // text-align: center;

    margin: 0px 5px;
    text-align: center;
    width: 40px !important;
    height: 40px !important;
    font-weight: bold !important;
  }
  h3 {
    font-size: 14px;
  }
}
.mobile-last-three-digit {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 0 4px !important;
  height: 24px;

  input {
    margin: 0px 2px;
    width: 14px !important;
    height: 20px !important;
    padding: 0;
    text-align: center;
    background: #ffffff;
    border: unset;
    border-radius: unset;
    font-weight: 700;

    &:enabled:hover {
      border: unset !important;
    }
  }
}
.success-message {
  i {
    font-size: 40px;
    color: #17e7ff;
  }
}

.resend-code {
  margin-top: 10px;
  button {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
    background: transparent;
    border: none;
    margin-left: 5px;
    letter-spacing: 0.1em;
    color: #ff569f;
    a {
      color: #ff569f;
    }
  }
}

.gradiant-btn.content-center {
  display: flex;
  align-items: center;
  justify-content: center;
  span.pi.pi-arrow-right.p-c.p-button-icon-left {
    position: inherit;
    margin: 0;
  }
  .p-button.p-button-text-icon-left .p-button-text {
    font-size: 13px;
  }
  span.p-button-text.p-c {
    padding: 4px 7px;
    font-size: 13px;
  }
}
.password-condition {
  ul {
    list-style: none;
    text-align: left;
    padding: 10px 0px;
    display: inline-block;
    li {
      width: 50%;
      float: left;
      padding: 10px 10px 0px 0px;
      font-size: 12px;
      i {
        color: #e6e6e6;
        float: right;
      }
    }
  }
}

@media #{$tab} {
  .nw-hidden {
    display: none;
  }
  .login-mobile-menu {
    ul.mobile-login-menu {
      display: block;
      margin-top: 20px;
      margin-left: 10px;
    }
  }
}

@media screen and (max-width: 480) {
  .nw-login-layout-wrapper .nw-login-form .login-form-layout {
    width: 100%;
    padding: 60px 0px;
  }
}

// Sign-up Section Start

// .signup-hidden-section{
//     display: none;
// }

.content-signup {
  background: #ffffff;
  width: 70vw !important;
  height: 65vh;
  // overflow-y: auto;
  // overflow-x: hidden;
  padding: 50px;

  @media #{$mobile} {
    height: auto;
    background-position: 15% 0;
    width: 90%;
  }

  .p-float-label {
    position: relative;
  }
  .p-dropdown,
  .p-calendar {
    width: 100%;
  }
}

.nw-layout-content {
  // width: 40vw !important;
  max-height: 95vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  max-width: 90vw;

  .global-login-form {
    .p-float-label {
      label {
        position: relative;
        padding: 5px;
      }
      .p-dropdown {
        height: 40px !important;
        .p-dropdown-label {
          padding: 0;
          height: 25px;
        }
      }
      .p-radiobutton-label {
        position: relative;
        top: 3px;
      }
    }
  }
}

.signup-form-wrapper {
  width: 78%;
  margin: 20px auto 0px;
  @media #{$mobile} {
    width: 100%;
  }
  .p-inputgroup {
    position: relative;
    @media #{$tab} {
      display: flex;
      justify-content: center;
    }
  }
  .p-inputgroup .p-inputtext {
    height: 45px;
    width: 100%;
    background: transparent;
    border: 1px solid #e7e7e7;
  }
  .p-inputgroup .p-inputgroup-addon {
    background-color: transparent;
    border-right: none;
    padding: inherit;
    span {
      color: #000;
      padding-top: 11px;
      display: inline-block;
      font-size: 14px;
      font-weight: 300;
    }
  }
  .required-icon {
    // position: absolute;
    // right: 6px;
    // top: 15px;
    // color: #B1B1B1;
    position: absolute;
    right: 0px;
    color: #b1b1b1;
    font-size: 20px;
    padding: 10px 10px;
  }
}
