@mixin unreadChatBoxBody {
    
    min-height: 73px;
    background-color: #ffffff;
    border-bottom: 1px solid #C1D4E7;
    // border-radius: 8px;
    padding: 20px 0;

    >.p-inputgroup{
        height: 100%;
        display: flex;
        align-items: center;
        margin: 0 24px;
        padding: 10px 0;
        
        >.p-inputgroup-addon{
            // width: 65%;
            background-color: transparent;
            height: 100%;
            min-width: unset;
            font-size: 16px;
            padding: 0;
            font-weight: bold;
            text-align: center;
            // color: #ffffff;
            border: unset;
            border-radius: 0;
            text-transform: unset;
            // display: flex;
            // align-items: center;
            // justify-content: center;

            // &.img{
            //     img{
            //         // height: 100%;
            //         width: 50px;
            //     }
            // }

            &.info{
                flex: 1 0 auto;
                margin: 0 10px;
                text-align: left;

                div{
                    font-size: 10px;
                    margin-bottom: 3px;
                    color: #83A4B9;
                    letter-spacing: .5px;

                    // &::nth-child(2){
                    //     font-size: 14px;
                    // }
                }

                & div:nth-child(1){
                    font-size: 14px;
                    color: #2E87CF;
                }
                
            }

        }

        a{
            display: none;
        }

        >button{
            display: block;
            width: 65px !important;
            margin-right: 0px !important;
            margin-left: 0px !important;
            border-radius: 4px !important;
            // border-bottom-right-radius: 8px !important;
            // background: #0099FF;
            border: unset !important;
            color: #ffffff;
            font-weight: 500;
            // font-size: 10px;
            height: 25px;

            &.bg-success{
                background-color: $success-color !important;
            }

            &.bg-warning{
                background-color: $warning-color !important;
            }
           

            &:hover{
                width: 65px !important;
                background:#ffffff !important;
                font-weight: 500;
                border: 1px solid #AAD7FF !important; 
                border-left: 1px solid #AAD7FF !important;

                >span{
                    color:#0A4675 !important;
                }
            }

            >span{
                // font-size: 34px;
                font-weight: 500;
                padding: 0;
                color: #ffffff;
            }
        }
    }
}



.nw-token-view-main{
    background-color: #F9F9F9;
    // border: 2px solid#0099FF20;
    padding: 20px 20px !important;
    

    .title-bar{
        span{
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            width: inherit;

            a{
                font-weight: 700;
            }
        }
    }

    .nw-token-view{
        // margin-bottom: 10px;

        &.header{
            height: 85px;
            // background-color: $warning-color;
            // border: 1px solid #AAD7FF;
            // border-radius: 8px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            padding: 0;
            overflow: hidden;
    
            .p-inputgroup{
                height: 100%;
                
                .p-inputgroup-addon{
                    width: 50%;
                    background-color: transparent;
                    height: 100%;
                    min-width: unset;
                    font-size: 16px;
                    padding: 0;
                    font-weight: bold;
                    text-align: center;
                    color: #ffffff;
                    border: unset;
                    border-radius: 0;
                    text-transform: unset;
                    display: grid;
                    align-items: center;
                    padding: 14px 0;

                    &:nth-child(1){
                        background-color: $warning-color;

                        div:nth-child(2){
                            font-size: 25px;
                        }
                    }

                    &:nth-child(2){
                        background-color: $success-color;

                        div:nth-child(2){
                            font-size: 25px;
                        }
                    }

                }
            }
        }
    
        &.body{
            max-height: 55vh;
            overflow: auto;
            
            .inside{
                @include unreadChatBoxBody();

                .token-submit-button{
                    width: 132px !important;
                    min-width: unset !important;
                    background: #ffffff;
                    color: #ffffff;
                    border-top: 1px solid #D2EAFF !important;
                    border-bottom: 1px solid #D2EAFF !important;
                    border-right: 1px solid #D2EAFF !important;
                    border-left: 1px solid #D2EAFF !important;
                    margin-right: unset !important;
                    margin-left: unset !important;
                    border-radius: 5px;
                }
            }
        }
    }

    &.topbar{
        background-color: #ECF6FF;
        border: none;
        max-height: 80vh;
        overflow-y: auto;
        position: relative;
        padding: 0px 0px !important;

        .pending-header, .solved-header{
            background-color: $bgColorWhite;
            padding: 10px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title{
                margin: unset;
                height: unset;
                font-size: 10px;
                color: $bgColorWhite;
                padding: 2px 6px;
                letter-spacing: .5px;
                font-weight: 700;
                border-radius: 3px;
            }

            .count{
                font-size: 12px;
                color: $bgColorWhite;
                padding: 2px 6px;
                letter-spacing: .5px;
                font-weight: 700;
                border-radius: 10px;
                padding: 0 10px;
            }
        }

        .pending-header{
            .title{
                background-color: $warning-color;
            }

            .count{
                background-color: $warning-color;
            }
        }

        .solved-header{
            .title{
                background-color: $success-color;
            }

            .count{
                background-color: $success-color;
            }
        }



        .nw-token-view{
            margin-bottom: 0;
            border-radius: unset;
            border: none;
            border-bottom: 1px solid #C1D4E7;

            &.body{
                .inside{
                    .p-inputgroup{
                        margin: 0 5px;
                        padding: 10px 10px;

                        .p-inputgroup-addon{
                            &.info{
                                margin: 0 5px;
                            }
                        }

                        a{
                            display: block;
                            width: 40px !important;
                            height: 25px;
                            padding: 0;
                            text-align: center;
                            border-radius: 4px !important;
                        }

                        button{
                            display: none;
                        }
                    }
                }
                
            }  
        }
        .footer{
            margin: 0;
            padding: 0;
            position: sticky;
            bottom: 0;

            // div:first-child{
            //     .p-button{
            //         border-right: 1px solid #C1D4E7;
            //     }
            // }

            >div{
                padding: 0;

                &:first-child{
                    .p-button{
                        border-right: 1px solid #C1D4E7;
                    }
                }

                >.p-button{
                    min-width: unset !important;
                    width: 100% !important;
                    border: unset;
                    background-color: #ffffff !important;
                    box-shadow: unset;
                    display: flex !important;
                    align-items: baseline;
                    justify-content: center;
                    color: #0099FF;
                    border-radius: unset;
                    min-height: 46px;
                    // border-top: 1px solid #C1D4E7;

                    .p-button-icon-right{
                        position: unset;
                    }

                    // &:first-child{
                    //     border-right: 1px solid #C1D4E7;
                    // }
                }
            }
        }
        
    }

    
}