@mixin messageBody {
    
    min-height: 73px;
    background-color: #ffffff;
    border: 1px solid #AAD7FF;
    border-radius: 8px;
    padding: 0;

    .p-inputgroup{
        height: 100%;
        display: flex;
        align-items: center;
        margin: 0 24px;
        padding: 10px 0;
        
        .p-inputgroup-addon{
            // width: 65%;
            background-color: transparent;
            height: 100%;
            min-width: unset;
            font-size: 16px;
            padding: 0;
            font-weight: bold;
            text-align: center;
            // color: #ffffff;
            border: unset;
            border-radius: 0;
            text-transform: unset;
            // display: flex;
            // align-items: center;
            // justify-content: center;

            &.img{
                img{
                    // height: 100%;
                    width: 50px;
                }
            }

            &.info{
                flex: 1 0 auto;
                margin: 0 15px;
                text-align: left;

                div{
                    font-size: 10px;
                    margin-bottom: 3px;
                    color: #727272;
                    letter-spacing: .5px;

                    // &::nth-child(2){
                    //     font-size: 14px;
                    // }
                }

                &div:nth-child(2){
                    font-size: 14px;
                    color: #000000;
                }
                
            }

        }

        a{
            display: none;
        }

        button{
            width: 65px !important;
            margin-right: 0px !important;
            margin-left: 0px !important;
            border-radius: 8px !important;
            // border-bottom-right-radius: 8px !important;
            background: #0099FF;
            border: 1px solid #AAD7FF;
            color: #ffffff;
            font-weight: 500;
            // font-size: 10px;
            height: 25px;
            border-left: 1px solid #D2EAFF !important;

            &:hover {
                width: 65px !important;
                background:#ffffff ;
                color: #0099FF;
                font-weight: 500;
            }

            >span{
                // font-size: 34px;
                font-weight: 500;
                padding: 0;
                // color: #0078FF;
            }
        }
    }
}



.nw-message-view-main{
    background-color: transparent;
    // border: 2px solid#0099FF20;
    // padding: 20px 10px !important;
    

    .nw-message-view{
        transition: all 1s;
        
        &.header{
            height: 80px;
            background-color: $primary-color;
            // border: 1px solid #AAD7FF;
            // border-radius: 8px;
            padding: 0;
            margin-bottom: 20px;

            @media (max-width: 1199px){
                .p-inputgroup {
                    flex-wrap: nowrap;
                }
            }
            
    
            .p-inputgroup{
                height: 100%;
                
                .p-inputgroup-addon{
                    width: 65%;
                    background-color: transparent;
                    height: 100%;
                    min-width: unset;
                    font-size: 27px;
                    padding: 0;
                    font-weight: bold;
                    text-align: left;
                    color: #ffffff;
                    border: unset;
                    border-radius: 0;
                    text-transform: unset;
                    display: flex;
                    align-items: center;
                    // justify-content: center;
                    margin: 0 0 0 20px;
                }
    
                button{
                    width: 50% !important;
                    margin-right: 0px !important;
                    margin-left: 0px !important;
                    /* border-top-right-radius: 8px !important; */
                    /* border-bottom-right-radius: 8px !important; */
                    background: transparent;
                    color: #ffffff;
                    font-weight: 700;
                    font-size: 10px;
                    margin: 0;
                    border-top: 0px solid #D2EAFF !important;
                    border-bottom: 0px solid #D2EAFF !important;
                    border-right: 0px solid #D2EAFF !important;
                    border-left: 0px solid #D2EAFF !important;
                    text-align: right;
                    padding: 0 20px 0 0;
    
                    &:hover {
                        width: 50% !important;
                    }
    
                    >span{
                        font-size: 48px;
                        font-weight: 600;
                        padding: 0;
                        color: #EEFF00;
                    }
                }
            }
        }
    
        &.body{
            max-height: 75vh;
            overflow: auto;

            &.nw-tabview{
                .p-tabview-top{
                    background-color: transparent;
                    margin: 0 !important;
                    border: unset;
                    padding: unset;

                    .p-tabview-nav{
                        display: inline-block;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        li{
                            width: auto;

                            a{
                                width: 100%;
                                text-align: center;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 2px 20px 15px 20px;
                            }
                        }
                    }

                    .p-tabview-selected{
                        a {
                            position: relative;
                            border-bottom: unset !important;

                            &::before{
                                position: absolute;
                                background-color: #0099FF;
                                content: '';
                                width: 100%;
                                height: 7px;
                                left: 0;
                                bottom: 0;
                                border-radius: 3px;
                            }
                        }
                    }

                    .p-unselectable-text:hover a {
                            position: relative;
                            border-bottom: unset !important;

                            &::before{
                                position: absolute;
                                background-color: #0099FF;
                                content: '';
                                width: 100%;
                                height: 7px;
                                left: 0;
                                bottom: 0;
                                border-radius: 3px;
                            }
                    }

                    .p-tabview-panels{
                        border: unset !important;
                        min-height: unset;
                        background-color: #F9F9F9;

                        .p-tabview-panel{
                            .p-col-12{
                                .p-inputgroup{
                                    flex-wrap: wrap;

                                    .p-inputgroup-addon{
                                        height: auto;
                                        padding: 7px 0px;
                                        border: unset;
                                        text-transform: uppercase;
                                        flex: 1 0 auto;
                                        background-color: unset;

                                        &.multiple-value{
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;
                                            font-size: 13px;
                                            font-weight: bold;
                                            text-align: left;
                                            color: #0A4675;
                                            border-radius: 0;
                                            text-transform: uppercase;

                                            span{
                                                margin-left: 2px;
                                                flex: 1;
                                                color: red;
                                            }
                                        }
                                    }

                                    .p-inputtextarea {
                                        min-height: 350px !important;
                                        background-color: #ffffff;
                                        font-size: 13px;
                                        padding: 12px 18px;
                                        font-weight: normal;
                                        text-align: left;
                                        border: 1px solid #D2EAFF;
                                        border-radius: 0;
                                        width: 100%;
                                        min-width: 0;
                                    }

                                    .p-editor-container {
                                        min-height: 300px;
                                        background-color: #ffffff;

                                        .p-editor-content {
                                            border: none;
                                            min-height: 220px;
                                            max-height: unset;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }

            .inside{
                margin-bottom: 10px;

                @include messageBody();
            }
            
        }
    }    
}