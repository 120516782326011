$whiteColor:#FFFFFF;
$grayBorder:#EBEBEB;
$grayColor:#F1F1F1;
$blackColor:#000000;
$grayFont:#555555;
$lightBlue:#0099FF;
@mixin gradient($gradientColorOne, $gradientColorSecond, $gradientOperationOne, $gradientOperationTwo){
  background: $gradientColorOne; /* Old browsers */
  background: -moz-linear-gradient($gradientOperationOne, $gradientColorOne 0%, $gradientColorSecond 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient($gradientOperationOne, $gradientColorOne 0%,$gradientColorSecond 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to $gradientOperationTwo, $gradientColorOne 0%,$gradientColorSecond 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
//code for payment status
.paymentStatus {
    padding: 10px 0px 4px 0px;
    text-align: center;
}

.paymentStatus h1 {
    color: white;
}
.successPayment {
    background-color: #ffffff;
    text-align: center;
    max-width: 45%;
    margin: 0 auto;
    border: 1px solid #c7c3c3;
    border-radius: 7px;
}
.successTop {
    color: white;
    background-color: #1890ff;
    padding: 25px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.successTop h3 {
    color: white;
}

.warningCentralize {
    line-height: 95px;
    max-width: 95px;
    max-height: 100px;
    border-radius: 50%;
    background-color: #fe6464;
    text-align: center;
    -webkit-align-items: center;
    align-items: center;
    margin: 0 auto;
    font-size: 50px;
}
.p-fluid.blur-section {
    background: #1b2362;
}
@mixin boxHeight($height){
  height: $height;
  width: 100%;
}
@mixin boxMinHeight($height){
  min-height: $height;
  width: 100%;
}
@mixin boxWidth($width){
  width: $width;
}
*::-webkit-scrollbar, .nw-layout-content::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.netiWorld-DashBoard-wrapper{
    margin: -10px -16px;
    padding: 20px;
    .custom-p-grid{
        margin-top: 0px;
    }
    .m-b-20{
        margin-bottom: 20px;
    }
    .m-t-20{
        margin-top: 20px;
    }
    .m-b-25{
        margin-bottom: 25px;
    }
    .m-t-25{
        margin-top: 25px;
    }
    .seprator-inside{
        padding-right: 0px;
        position: relative;
        padding-right: 54px !important;
        position: relative;
    }
    .seprator-inside::after{
        width: 5px;
        border-left: 1px dashed #D2EAFF;
        /* border-width: 2px; */
        border-right: 1px dashed #D2EAFF;
        content: " ";
        height: 100%;
        position: absolute;
        pointer-events: none;
        right: 0;
        /* margin-right: 5px; */
        top: 0px;
        /* bottom: 0; */
        margin: auto 23px;
    }
    
    .p-0{
        padding: 0px !important;
    }
    .p-10{
        padding: 10px !important;
    }
    .p-l-0{
        padding-left: 0px !important;
    }
    .p-t-0{
        padding-top: 0px !important;
    }
    .p-r-0{
        padding-right: 0px !important;
    }
    .p-b-0{
        padding-bottom: 0px !important;
    }
    .p-b-20{
        padding-bottom: 20px !important;
    }
    .p-t-05{
        padding-top:0.5em !important;
    }
    .p-t-20{
        padding-top: 20px !important;
    }
    .p-t-45{
        padding-top: 45px !important;
    }
    .m-t-0{
        margin-top: 0px !important;
    }
    .m-b-0{
        margin-bottom: 0px !important;
    }
    .m-b-30{
        margin-bottom: 30px !important;
    }
    .m-0{
        margin: 0px !important;
    }
    .netiWorld-DashBoard{
        // padding: 30px;
        background-color: $whiteColor;
        // border: 1px solid $grayBorder;
    }
    .gradient-color-dark-blue{
        @include gradient(#85C2FF, #004BA0, top, bottom);
    }
    .gradient-color-light-cyan{
        @include gradient(#65EEE1, #009485, top, bottom);
    }
    .gradient-color-light-pink{
        @include gradient(#FF92BE, #CE125D, top, bottom);
    }
    .gradient-color-light-purple{
        @include gradient(#C081FF, #5000A0, top, bottom);
    }
    .gradient-color-dark-purple{
        @include gradient(#833BFF, #5D00FF, top, bottom);
    }
    .gradient-color-orange{
        @include gradient(#FFC165, #FF9D65, top, bottom);
    }
    .gradient-color-dark-orange{
        @include gradient(#FFC165, #FF9D65, top, bottom);
    }
    .gradient-color-gray{
        @include gradient(#88A2BE, #4C6782, top, bottom);
    }
    .gradient-color-light-puple{
        @include gradient(#7178AC, #4C5382, top, bottom);
    }
    .gradient-color-light-blue{
        @include gradient(#8DC4FF, #568FC8, top, bottom);
    }
    .gradient-color-light-blue-2{
        @include gradient(#85EAFF, #00BAE1, top, bottom);
    }
    .gradient-color-red{
        @include gradient( #FF9191, #FF2424, top, bottom);
    }
    .gradient-color-light-orange{
        @include gradient( #FF9F75, #FF6724, top, bottom);
    }
    .gradient-color-light-yellow{
        @include gradient( #FFD86D, #DCA200, top, bottom);
    }
    .gradient-color-dark-gray{
        @include gradient( #959595, #565656, top, bottom);
    }
    //for new home dashboard
    .gradient-color-navy-blue{
        @include gradient(#5521E9, #D596F6, top, bottom);
    }

    .gradient-color-feroza-lighy-feroza{
        @include gradient(#006BFF, #18B5FF, top, bottom);
    }

    .gradient-color-deep-red{
        @include gradient(#FE1D2E, #FC3478, top, bottom);
    }

    .gradient-color-light-green{
        @include gradient(#0166F3, #10CF81, top, bottom);
    }

    .gradient-color-deep-orange{
        @include gradient(#F96713, #F9B21E, top, bottom);
    }

    .gradient-color-light-red{
        @include gradient(#FF2817, #FE7673, top, bottom);
    }

    .gradient-color-sky-blue{
        @include gradient(#707AF0, #DB3B7B, left, right);
    }

    .gradient-color-light-purple2{
        @include gradient(#AA0DFC, #11E7EF, top, bottom);
    }
    
    .b-r-10{
        border-radius: 10px;
    }

    .boxHeight-284{
        @include boxHeight(166.13px);
    }
    .boxHeight-400{
        @include boxHeight(400px);
    }
    .boxHeight-174{
        @include boxHeight(174.2px);
    }
    .boxWidth-174{
        @include boxWidth(174.2px);
    }
    .boxHeight-385{
        @include boxHeight(385px);
    }
    .boxHeight-165{
        @include boxHeight(165.7px);
    }
    .boxHeight-245{
        @include boxHeight(240px);
    }

    .boxWidth-235{
        @include boxWidth(235.51px);
    }
    .boxHeight-141{
        @include boxHeight(141.05px);
    }

    .color-white{
        color: $whiteColor !important;
    }
    .font-16{
        font-size: 15px;
    }
    .font-18{
        font-size: 18px;
    }
    .font-20{
        font-size: 20px !important;
    }
    .font-weight-normal{
        font-weight: normal !important;
    }
    .text-left{
        text-align: left;
    }
    .text-white{
        color: #ffffff !important;
    }
    .text-yellow{
        color: #F5A300 !important;
    }
    .full-width-block{
        width: 100%;
    }
    .notify-box-wrapper-bg-image{
        background-image: url('../images/box-shadow.png');
        background-repeat: no-repeat;
        background-position: top right;
        position: relative;
    }
    .notify-box{
        padding: 20px;
    }
    .notify-btn span{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        cursor: pointer;
        text-transform: uppercase;
    }
    .notify-btn i{
        margin-left: 10px;
    }
    .wallet-icon {
        background-image: url('../images/wallet-icon.png');
        height: 30px;
        width: 30px;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .gurdiansGroup-icon {
        background-image: url('../images/guardienGroup.png');
        height: 38px;
        width: 60px;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0.5;
    }
    .teacherGroup-icon {
        background-image: url('../images/teacherGroup.png');
        height: 38px;
        width: 60px;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0.5;
    }
    .userGroup-icon {
        background-image: url('../images/userGroup.png');
        height: 38px;
        width: 60px;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0.5;
    }
    .msg-icon{
        background-image: url('../images/msg-icon.png');
        height: 34px;
        width: 40px;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .user-icon{
        background-image: url('../images/user-icon.png');
        height: 42px;
        width: 36px;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .today-icon{
        background-image: url('../images/today-icon.png');
        height: 42px;
        width: 36px;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .count-icon{
        background-image: url('../images/click-icon.png');
        height: 42px;
        width: 36px;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .window-icon{
        background-image: url('../images/widow-icon.png');
        height: 26px;
        width: 54px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-bottom: 30px !important;
    }
    .map-icon{
        background-image: url('../images/map-icon.png');
        height: 42px;
        width: 36px;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 10px !important;
    }
    .callCenter-icon{
        background-image: url('../images/callCenter.png');
        height: 42px;
        width: 36px;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .unUseCode-icon{
        background-image: url('../images/unUseCode.png');
        height: 35px;
        width: 50px;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .msgInfo-icon{
        background-image: url('../images/msg-info.png');
        height: 35px;
        width: 50px;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .arcrive-icon{
        background-image: url('../images/arcrive-icon.png');
        height: 35px;
        width: 50px;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .visitor-icon{
        background-image: url('../images/visitor-icon.png');
        height: 35px;
        width: 50px;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .notify-title-position-l-b{
        position: absolute;
        left:20px;
        bottom: 10px;
        width: 100%;
        padding-right: 45px;
    }
    .bottom-position{
        bottom: 20px !important;
    }
    .notify-box-title h1{
        font-weight: 200;
        margin-top: 15px;
        margin-bottom: 10px;
        position: relative;
    }
    .notify-subtitle h3{
        font-size: 14px;
        text-align: left;
        color: rgba(255,255,255,0.5);
        font-weight: 400;
        margin-top: 20px;
    }
    .notify-box-title hr{
        border: 1px solid rgba(255,255,255,0.25);
        margin-bottom: 10px;
    }
    .notify-currency p{
        font-weight: 700;
    }
    .notify-flex-title{
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    .notify-flex-title span{
        border: 1px solid #fff;
        font-size: 20px;
        padding: 0px 5px;
        border-radius: 5px;
        margin-right: 10px;
    }
    .notify-button {
        padding: 10px;
        border: 1px solid #fff;
        color: #fff;
        font-size: 12px;
        margin-top: 20px;
        cursor: pointer;
    }
    .tk-sign, .arrow-sign, .active-sign, .up-sign, .down-sign{
        position: relative;
        padding-left: 20px;
    }
    
    .tk-sign::before{
        background-image: url('../images/bd-money.png');
         height: 24px;
         width: 12px;
         background-repeat: no-repeat;
         content: "";
         position: absolute;
         left: 0px;
         top: 3px;
    }
    .tksign{
        position: relative;
    }
    .tksign::before{
        background-image: url('../images/takaSign.png');
        height: 24px;
        width: 12px;
        background-repeat: no-repeat;
        content: "";
        position: absolute;
        left: -14px;
        top: -3px;
        background-size: 10px;
    }
    .arrow-sign::before{
        background-image: url('../images/arrow-sign.png');
        height: 24px;
        width: 20px;
        background-repeat: no-repeat;
        content: "";
        position: absolute;
        left: 0px;
        top: 7px;
    }
    .active-sign::before{
        background-image: url('../images/active-sign.png');
        height: 20px;
        width: 16px;
        background-repeat: no-repeat;
        content: "";
        position: absolute;
        left: 0px;
        top: 7px;
    }
    .up-sign::after{
        background-image: url('../images/up-sign.png');
        height: 20px;
        width: 16px;
        background-repeat: no-repeat;
        content: "";
        position: absolute;
        right: 0px;
        top: 7px;
    }
    .down-sign::after{
        background-image: url('../images/down-sign.png');
        height: 20px;
        width: 16px;
        background-repeat: no-repeat;
        content: "";
        position: absolute;
        right: 0px;
        top: 7px;
    }
    .inline-block{
        display: inline-block;
    }
    .notify-border p{
        border: 1px solid rgba(0,0,0,0.10) !important;
    }
    .gray-bg{
        background:$grayColor;
    }
    .panel-box{
        padding:20px;
    }
    .panel-box-header{
        position: relative;
    }
    .panel-box-header h1{
        color: $blackColor;
        font-size: 26px;
        font-weight: 200;
        margin-bottom: 20px;
    }
    .panel-box-header sub{
        position: absolute;
        bottom: 3px;
        margin-left: 10px;
    }
    .notice {
        padding: 20px;
        background: #fff;
        border: 1px solid #f7f7f7;
        border-radius: 10px;
        position: relative;
        margin-bottom: 15px;
    }
    .notice-list {
        height: 415px;
        overflow-y: auto;
        padding-right: 5px;
    }
    .dot-notice-list{
        height: 883px;
        overflow-y: auto;
        padding-right: 5px;
    }
    .notice-tooltips {
        position: absolute;
        padding: 3px;
        border: 1px solid #111;
        border-radius: 5px;
        font-weight: 600;
        right: 20px;
        font-size: 9px;
    }
    .notice-title{
        margin-top: 20px;
        position: relative;
    }
    .notice-title::before{
        content: "";
        height: 2px;
        width: 60px;
        background: #000000;
        position: absolute;
        top: -11px;
        left: 2px;
    }
    .notice-title-dot {
        position: relative;;
    }
    .notice-title-dot h3{
        font-size: 16px;
        font-weight: 700;
        line-height:1.8;
        color: $blackColor;
    }
    .dot-notice{
        padding: 25px 15px;
    }
    
    .success-dot-icon{
        color:#22CF68;
        position: absolute;
        top: 12px;
        right: 12px;
        font-size: 18px;
    }
    .notice-title h3{
        font-size: 16px;
        font-weight: 700;
        line-height:1.8;
        color: $blackColor;
    }
    .success-dot{
        color: #22CF68 !important;
        position: relative;
    }
    .unSuccess-dot{
        color: #FF5EB4 !important;
        position: relative;
    }
    .notice-text p{
        color: $grayFont;
        font-size: 14px;
    }
    .notice-success{
        .notice-title::before{
            content: "";
            height: 2px;
            width: 60px;
            background: #00D47F;
            position: absolute;
            top: -11px;
            left: 2px;
        }
        .notice-tooltips {
            position: absolute;
            padding: 3px;
            border: 1px solid  #00D47F;
            border-radius: 5px;
            font-weight: 600;
            right: 20px;
            font-size: 9px;
            color: #00D47F;
        }
    }
    .notice-danger{
        .notice-title::before{
            content: "";
            height: 2px;
            width: 60px;
            background: #FF4600;
            position: absolute;
            top: -11px;
            left: 2px;
        }
        .notice-tooltips {
            position: absolute;
            padding: 3px;
            border: 1px solid  #FF4600;
            border-radius: 5px;
            font-weight: 600;
            right: 20px;
            font-size: 9px;
            color: #FF4600;
        }
    }
    .notice-warning{
        .notice-title::before{
            content: "";
            height: 2px;
            width: 60px;
            background: #DD50FF;
            position: absolute;
            top: -11px;
            left: 2px;
        }
        .notice-tooltips {
            position: absolute;
            padding: 3px;
            border: 1px solid  #DD50FF;
            border-radius: 5px;
            font-weight: 600;
            right: 20px;
            font-size: 9px;
            color: #DD50FF;
        }
    }
    .browser-info-wrapper{
        margin-bottom: 15px;
    }
    .browser-info-title h3 {
        font-size: 12px;
        color: $lightBlue;
    }
    .browser-info p {
        font-size: 16px;
        font-weight: 600;
        padding: 5px;
        border: 1px solid #C8C8C8;
        margin-top: 10px;
    }
    .browser-info-table table{
        width: 100%;
    }
    .browser-info-table td{
        width: 50%;
        font-weight: 700;
        color: #8A8A8A;
        font-size: 16px;
        margin-bottom: 5px;
        display: inline-block;
    }
    .tootips{
        text-align: center;
    }
    .tootips p {
        background: #0099FF;
        padding: 5px 10px;
        color: #FFFFFF;
        display: inline-block;
        border-radius: 10px;
        font-weight: 700;
    }
    .profile-image img{
        height: 85px;
        width: 85px;
        border-radius: 50%;
        border: 3px solid #fff;
        padding: 2px;
    }
    .full-profile{
        text-align: center;
    }
    .full-profile img{
        height: 124px;
        width: 124px;
        margin-bottom: 35px;
    }
    .profile-name{
        margin-top: 10px;
    }
    .profile-name p{
        font-size: 16px;
        font-weight: 700;
        color: #000;
    }
    .profile-rating {
        text-align: center;
        font-size: 14px;
        color: #fff;
    }
    .profile-rating .rating-word{
        font-size: 24px;
        margin-left: 10px;
    }
    .profile-designation{
        margin-top: 10px;
    }
    .profile-designation p{
        font-size: 14px;
        color: $grayFont;
    }
    .profile-name hr{
        border-color: rgba(255,255,255,0.25);
    }
    .profile-table table{
        width: 100%;
    }
    .profile-table tbody tr{
        margin-bottom: 10px;
        display: block;
    }
    .profile-table.profile-border-table tr{
        padding-top:10px;
        padding-bottom: 10px;
        margin-bottom: 0px;
        border-bottom: 1px solid  #FFFFFF;
    }
    .profile-table .profile-border-table tbody td{
        width: 50%;
    }
    .profile-table tbody td{
        font-size: 14px;
        color: $blackColor;
        width: 50%;
        display: inline-block;
    }
    .profile-table tbody td p{
        word-break: break-all;
    }
    .profile-table td:first-child{
        font-weight: 700;
        position: relative;
        padding-left: 15px;
    }
    .profile-table td:last-child{
        position: relative;
        padding-left: 5px;
    }
    .profile-table td:first-child::before{
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #DBDBDB;
        position: absolute;
        left: 0px;
        top: 7px;
        content: "";
    }
    .profile-table td:last-child p::before{
        position: absolute;
        left: 0px;
        top: 0px;
        content: ":";
    }
    .panel-box-header-btn {
        position: absolute;
        right: 0;
        top: 5px;
        font-size: 21px;
        color: #979797;
    }
    .scrollable-flex-wrapper {
        display: inline-flex;
        width: 100%;
        overflow: auto;
        height: 205px;
        scroll-behavior: smooth;
    }
    .flex-box{
        padding: 0 0.49em;
    }
    .flex-box:first-child{
        margin-left: -7px;
    }
    .flex-box-title-opacity{
        padding-top: 20px;
        padding-bottom: 20px;
        background: rgba(0,0,0,0.2);
        padding-left: 20px;
        padding-right: 20px;
    }
    .flip-card {
        @include gradient(#88A2BE, #4C6782, top, bottom);
        perspective: 1000px;
    }

    .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;
    }

    .flip-card:hover .flip-card-inner {
        transform: rotateY(180deg);
    }

    .flip-card-front, .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
    }

    .flip-card-front {
        color: black;
    }

    .flip-card-back {
        color: white;
        transform: rotateY(180deg);
    }
    .flip-card{
        .notify-box{
            padding: 20px;
        }
        .date-counter{
            float: left;
            list-style: none;
            width: 100%;
            margin-top: 18px;
            display: flex;
            justify-content: space-between;
        }
        .date-counter li{
            float: left;
        }
        .date-counter li:nth-child(2){
            margin: 0 19px;
        }
        .date-counter li .date-counter-title{
            color: #002C4D;
            font-size: 9px;
            text-align: center;
            margin-bottom: 5px;
        }
        .date-count{
            font-size: 16px;
            color: #fff;
            border: 1px solid #fff;
            padding: 5px;
        }
        .date-wrapper{
            margin-top: 10px;
        }
        .date-list{
            margin-bottom: 20px;
        }
        .date-list p{
            color: rgba(255,255,255,0.5);
            font-size: 14px;
            font-weight: 200;
            line-height: 1.8;
        }
        .date-list span{
            color: rgb(255,255,255);
            font-size: 18px;
            font-weight: 400;
        }
        
    }
    .dashBoard-tabs{
        .p-tabview.p-tabview-top .p-tabview-nav{
            background: transparent;
        }
        .p-tabview .p-tabview-panels{
            background: transparent;
            border: none;
        }
        .p-tabview.p-tabview-top .p-tabview-nav li a, .p-tabview.p-tabview-top .p-tabview-nav li a:hover{
            background: transparent;
            border: none;
        }
        .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight a,  .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight a:hover{
            background: transparent;
            border: none;
        }
        .p-tabview.p-tabview-top .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover a{
             background: transparent;
             border:none;
         }
        span.p-tabview-title {
            color: #002C4D;
        }
        span.p-tabview-title span{
            color:#9D9D9D;
            text-transform: uppercase;
        }
        .taken{
            border-bottom: 2px solid #9F9F9F !important;
            padding-bottom: 5px;
        }
        .pending{
            border-bottom: 2px solid #ECA427 !important;
            padding-bottom: 5px;
        }
        .rejected{
            border-bottom: 2px solid #EC2727 !important;
            padding-bottom: 5px;
        }
        .solved{
            border-bottom: 2px solid #0099FF !important;
            padding-bottom: 5px;
        }
        .p-tabview-panels{
            padding-top: 30px;
            padding-bottom: 30px;
        }
        .issue-list{
            list-style: none;
            height: 260px;
            overflow: auto;
            padding-right: 10px;
            li{
                display: block;
                align-items: center;
                padding: 15px 25px;
                background: #fff;
                width: 100%;
                position:relative;
                margin-bottom: 20px;
            }
            .issue-status span:first-child{
                background: #757575;
                font-size: 13px;
                padding:3px 5px;
                color: #fff;
                font-weight: 700; 
                margin-right: 10px;
            }
        
            li .issue{
                width: 400px;
                font-size: 14px;
                line-height: 1.8;
                cursor: pointer;
            }
            .issue-status span:nth-child(2){
                font-size: 13px;
                padding:3px 5px;
                font-weight: 700; 
                margin-right: 10px;
                border: 1px solid #F3F3F3;
                text-transform: uppercase;
            }
            .issue-status {
                position: absolute;
                right: 20px;
            }
            .pending-text{
                color: #ECA427;
            }
            .pending-border{
                border: 1px solid #ECA427;
            }
            .reject-text{
                color: #EC2727;
            }
            .reject-border{
                border: 1px solid #EC2727;
            }
            .solved-text{
                color: #0099FF;
            }
            .solved-border{
                border: 1px solid #0099FF;
            }
            .issue-icon {
                font-size: 18px;
                vertical-align: middle;
                line-height: 1.8;
                margin-right: 20px;
                float: left;
                place-self: flex-start;
                -moz-place-self: flex-start;
                -webkit-place-self: flex-start;
            }

        }
    }
    body.fc th{
                background: transparent !important;
            }
    .advisement-wrapper{
        width: 100%;
    }
    .advisement-text{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 378px;
    }
    .advisement-text p{
        font-size: 26px;
        color: #FFFFFF;
    }
    .schedule-calender{
        hr{
            margin-bottom: 0px;
        }
        padding:20px;
        .fc-today-button{
            display: none;
        }
        .fc .fc-ltr .ui-widget{
            position: relative !important;
        }
        
        .fc-button-group button{
            background: transparent;
            border-radius: 50% !important;
            border-color: #fff !important;
            height: 25px;
            width: 25px;
            text-align: center;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
        }
        .fc-button-group button:hover{
            background: #FFC400 !important;
            border-color: #FFC400 !important;
        }
        .fc-toolbar.fc-header-toolbar {
            line-height: 0;
            margin-bottom: 0px !important;
        }
        .fc-toolbar .fc-left{
            float: none;
        }
        .fc-toolbar .fc-left h2 {
            float: none;
            text-align: center;
            font-weight: 400;
            line-height: 2.8;
            font-size: 20px;
            color: #fff;
        }
        .fc td.fc-head-container{
            border:1px solid #fff !important;
        }
        .fc .fc-row{
            border-right:1px solid #fff !important;
        }
         .fc th{
            background: transparent !important;
            border:1px solid #fff !important;
            color: #FFFFFF;
            text-align: center;
            padding-left: 0;
            padding-right: 0;
         }
        .fc td.ui-widget-content{
            background: transparent !important;
            border-color: #fff !important;
            color: #FFFFFF;
            text-align: center;
        }
        .fc-ltr .fc-basic-view .fc-day-top .fc-day-number{
            float: none;
            padding-top: 15px;
            display: block;
        }
        .fc-scroller.fc-day-grid-container[style]{
            height: 190px !important;
        }
        .fc-toolbar .fc-right {
            float: none;
            position: absolute;
            top: 15px;
            right: 20px;
        }
    }
    
    .scroll-btn-wrapper{
        position: absolute;
        right: 0;
        top: 0;
        ul{
            list-style: none;
        }
        ul li{
            display: inline-flex;
            margin-left: 10px;
            height: 30px;
            width: 30px;
            border: 1px solid #707070;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            cursor: pointer;
             color: #707070;
        }
        ul li:hover{
            border: 1px solid #0099FF;
            background: #0099FF;
             color: #ffffff;
        }
    }

    .welcome-emotion{
        position: relative;
        display: inline-block;
        &::after{
            position: absolute;
            right: -28px;
            top: 5px;
            content: "";
            height: 26px;
            width: 25px;
            background: url('../images/emo.png');
        }
    }
    .profile-link{
        position: absolute;
        right: 0px;
        color: #0099FF;
        font-weight: 700;
        text-decoration: underline;
        top: 7px;
        cursor: pointer;
    }
    .profile-progress-bar{
        border-radius: 20px;
    }
    .p-progressbar-label{
        text-align: left !important;
        color: #ffffff !important;
        position: relative;
        padding-left: 175px;
        margin-left: 20px;
        &::before{
            position: absolute;
            left: 0px;
            top: 0px;
            content: "Your Profile Completeness  - "
        }
    }
    .custom-profile-grid{
        .p-xl-4{
            width: 100%;
        }
        .p-xl-8{
            width: 100%;
        }
        .p-xl-6{
            width: 100%;
        }
        .p-xl-6{
            width: 100%;
        }
    }
    .notify-flex{
        display: flex;
        align-items: center;
        justify-content: center;
        .notify-box-title .font-16{
            font-size: 16px;
        }
        .notify-box-icon{
            margin: 0 auto;
        }
    }
    .custom-notify-grid{
        .p-xl-4{
            width: auto;
        }
    }
    .todays-info-custom-height{
        height: 169px;
        .boxHeight-10em{
            height: 4em;
        }
    }
    .chart-box-wrapper{
        .point-list{
            overflow: auto;
            height: 256px;
            padding: 0 5px;
        }
        .point-list table{
            width: 100%;
        }
        .point-list table td{
            font-size: 14px;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
        }
        .point-list table td p{
            font-size: 14px;
            font-weight: 700;
        }
        .point-list table td:first-child span{
            height: 14px;
            width: 14px;
            border-radius: 50%;
            float: left;
            margin-right: 10px;
            margin-top: 3px;
        }
        .point-list table td:first-child p{
            float: left;
            font-weight: 700;
            white-space: break-spaces;
            width: 140px;
        }
        .point-list table td:last-child{
            float: right;
        }
        .point-list table tr{
            padding-bottom: 10px;
            display: inline-flex;
            border-bottom: 1px dashed #707070;
            padding-top: 10px;
            align-items: center;
            justify-content: center;
        }
        
        .chart-box{
            margin: 2em auto;
            width: 196px;
            position: relative;
            svg{
                background: #ffffff;
                border-radius: 50%;
            }
            .chart-box-title{
                font-size: 16px;
                font-weight: 700;
                position: absolute;
                bottom: -35px;
                left: 67px;
            }
            .chart-box-title.tksign::before{
                top: 0px;
            }
        }
        .chart-box::before{
            height: 174px;
            width: 174px;
            content: "";
            background: rgb(0,0,0);
            background: radial-gradient(circle, rgba(0,0,0,0.1) 0%, rgba(0,0,0,1) 0%, rgba(255,255,255,0.1) 100%);
            position: absolute;
            left: 11px;
            top: 11px;
            border-radius: 50%;
            z-index: 9;
            opacity: 0.1;
        }
    }
    .boxHeight-174{
        @include boxHeight(165px);
    }
    .boxHeight-128{
        @include boxHeight(119px);
    }
     .boxWidth-174{
        @include boxWidth(165px);
    }
    .boxHeight-182{
        @include boxHeight(182px);
    }
   
    @media (min-width:1757px){
        .notify-box-title .font-16{
            font-size: 20px;
        }
        .boxHeight-284{
            @include boxHeight(270px);
        }
        .boxHeight-400{
            @include boxHeight(238px);
        }
        .boxHeight-245{
            @include boxHeight(245px);
        }
        .wallet-icon{
            background-image: url('../images/wallet-icon.png');
            height: 50px;
            width: 45px;
            background-repeat: no-repeat;
        }
        .notify-title-position-l-b{
            bottom: 30px;
        }
        .full-width-block.p-lg-8 {
            width: 66.6667%;
        }
        .full-width-block.p-lg-4 {
            width: 33.3333%;
        }
        .schedule-calender .fc-toolbar .fc-right {
            float: none;
            position: absolute;
            top: 25px;
            right: 20px;
        }
        .dashBoard-tabs .issue-list li {
            display: inline-flex;
            align-items: center;
            padding: 15px 25px;
            background: #fff;
            width: 100%;
            position: relative;
            margin-bottom: 20px;
        }
        .issue-icon {
            font-size: 18px;
            vertical-align: middle;
            line-height: 1.8;
            margin-right: 20px;
            float: none;
        }
        .custom-profile-grid{
            .p-xl-4{
                    width: 25%;
                }
            .p-xl-8{
                width: 75%;
            }
            .p-xl-6{
                width: 50%;
            }
            .p-xl-6{
                width: 50%;
            }
        }
        .todays-info-custom-height{
            height: 246.5px;
            .boxHeight-10em{
                height: 10em;
            }
        }
        .boxHeight-174{
            @include boxHeight(174px);
        }
        .boxHeight-128{
            @include boxHeight(128px);
        }
        .boxWidth-174{
            @include boxWidth(174px);
        }
        
    } 
    @media (min-width:1361px) and (max-width:1756px){
        .dashBoard-tabs .issue-list li {
            display: inline-flex;
            align-items: center;
            padding: 15px 25px;
            background: #fff;
            width: 100%;
            position: relative;
            margin-bottom: 20px;
        }
        .issue-icon {
            font-size: 18px;
            vertical-align: middle;
            line-height: 1.8;
            margin-right: 20px;
            float: none;
        }
        .dashBoard-tabs .issue-list li .issue {
            width: 250px;
        }
        .boxHeight-165{
            @include boxHeight(230.83px);
        }
        .notify-flex-title{
            height: 27px;
            .font-20{
                font-size: 16px !important;
            }
            span{
                font-size: 11px !important;
            }
        }

    }
    @media (max-width:1360px){
        .notify-title-position-l-b{
            bottom: 10px;
        }
        .boxHeight-284{
             @include boxHeight(150px);
        }
        .font-16{
            font-size: 14px;
        }
        .dashBoard-tabs .issue-list li {
            display: block;
            align-items: center;
            padding: 15px 25px;
            background: #fff;
            width: 100%;
            position: relative;
            margin-bottom: 20px;
            float: left;
        }
        .issue-icon {
            font-size: 18px;
            vertical-align: middle;
            line-height: 1.8;
            margin-right: 20px;
            float: left;
        }
        .dashBoard-tabs .issue-list .issue-status {
            position: relative;
            right: 0px;
            float: left;
            margin-top: 10px;
        }
        .custom-profile-grid{
            .p-xl-4{
                    width: 100%;
                }
            .p-xl-8{
                width: 100%;
            }
        }
        .notify-flex-title{
            .font-20{
                font-size: 16px !important;
            }
            span{
                font-size: 11px !important;
            }
        }
    }
    @media (max-width:1199px){
        .personalProfileEqual[style]{
            min-height: auto !important;
            margin-bottom: 14px;
        }
        .notify-myPoint-custom-div-size.p-lg-2{
            width: 50%;
        }
        .notify-myPoint-custom-div-size.p-lg-4{
            width: 50%;
        }
    }
    @media (max-width:1040px){
        .netiWorld-DashBoard-wrapper{
            margin: 0px;
        }
        .seprator-inside{
            padding-right: 0em !important;
        }
        .seprator-inside::after{
            display: none;
        }
        .boxHeight-284{
             @include boxHeight(165.5px);
        }
        .notice-list{
            height: 248px;
        }
        .boxHeight-400 {
            height: 238px;
            width: 100%;
        }
    }
    @media (min-width:986px) and (max-width:1040px){
        .custom-p-grid .p-md-12{
            width: 100%;
        }
    }
    @media (max-width:767px){
        .notify-myPoint-custom-div-size.p-lg-2{
            width: 100%;
        }
        .notify-myPoint-custom-div-size.p-lg-4{
            width: 100%;
        }
    }

}
 @media (max-width:1040px){
        .netiWorld-DashBoard-wrapper{
            margin: 0px;
        }
}
