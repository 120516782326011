
@mixin gradient($gradientColorOne, $gradientColorSecond, $gradientOperationOne, $gradientOperationTwo){
    background: $gradientColorOne; /* Old browsers */
    background: -moz-linear-gradient($gradientOperationOne, $gradientColorOne 0%, $gradientColorSecond 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient($gradientOperationOne, $gradientColorOne 0%,$gradientColorSecond 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to $gradientOperationTwo, $gradientColorOne 0%,$gradientColorSecond 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.main-section{
    &.netiworld-main-dashboard{
        letter-spacing: .25px;

        .font-10{
            font-size: 10px;
        }
        .font-12{
            font-size: 12px;
        }
        .font-20{
            font-size: 20px !important;
        }
        .p-0{
            padding: 0 !important;
        }
        .header{
            margin: -27px -27px 0px -20px;

            > div:first-child{
                flex: 1 0 63%;
            }
            > div:last-child{
                flex: 1 0 20%;
            }

            .user-info{
                background-color: #0099FF;
                padding: 30px;
                color: #ffffff;

                .img{
                    position: relative;
                    max-width: fit-content;
                    height: 156px;
                    margin: 0 30px 0 0;
                    border: 4px solid #ffffff;
                    padding: 0;

                    img{
                        // width: 100%;
                        height: 100%;
                    }

                    i{
                        position: absolute;
                        bottom: 0;
                        font-size: 24px;
                        left: 43%;
                    }
                }

                .info{
                    align-self: flex-end;

                    .name{
                        align-self: flex-end;
                        margin-bottom: 10px;

                        h1{
                            font-size: 25px;
                            font-weight: 700;
                        }
                    }

                    .others-info{
                        align-self: flex-start;
                        margin-bottom: 10px;

                        >div{
                            font-size: 12px;
                            font-weight: 700;
                        }
                    }

                    .progress-wrapper{
                        display: flex;
                        margin-bottom: -5px;

                        .progress {
                            display: inline-flex;
                            width: 100%;
                            align-items: center;
                            justify-content: center;
                            background: #F2F2F2;
                            padding: 2px 9px;
                            border-radius: 40px;
                            font-size: 10px;
                            font-weight: 700;

                            &:first-child{
                                width: 120px;
                                margin: 0 5px 0 0;
                            }

                            &:last-child{
                                // width: 120px;
                            }

                            p{
                                color: #0099FF;
                                padding: 3px 0;
                                font-size: 10px;
                            }

                            .p-progressbar{
                                width: 100%;
                                border-radius: 40px;
                                margin-right: 10px;
                                position: relative;
                                height: 12px;

                                .p-progressbar-value{
                                    &.p-progressbar-value-animate{
                                        @include gradient( #0099FF, #6EC5FF, left, right);
                                    }
                                }

                                .p-progressbar-label{
                                    color: #fff;
                                    text-align: left !important;
                                    padding-left: 140px;
                                    font-size: 9px;
                                    line-height: inherit;
                                }

                                .p-progressbar-label::before {
                                    position: absolute;
                                    left:15px;
                                    top: 0px;
                                    content: "Your Profile Completeness - ";
                                    color: #fff;
                                }

                                .p-progressbar-value{
                                    border-radius: 40px;
                                }
                            }
                            .progress-icon{
                                .p-button:enabled:focus{
                                    border-color: transparent !important;
                                }
                                .p-button-text{
                                    padding:0;
                                }
                                .p-button.p-button-icon-only{
                                    width: auto;
                                    background: transparent;
                                    border-color: transparent;
                                    color: #0099FF;
                                }
                            }
                        }
                    }
                }

                
            }
            .advertise-info{
                // background-image: url('../images/mainDashboardIcon/eduman-ad.png');
                // padding: 30px;
                background-repeat: no-repeat;
                background-size: cover;
                height: 224px;
                margin: -8px;

                img{
                    height: 100%;
                }

            }
        }

        .card-section{
            // background-color: #dedede;

            .card-section-inside{
                // @include gradient( #006BFF, #19B8FF, left, right);
                flex: 1 0 21%;
                margin: 0px 15px 15px 10px ;
                height: auto;
                border-radius: 12px;
                padding: 0px 0px 5px 15px;
                box-shadow: 0px 6px 20px 0px #00000015;

                // &:first-child, &:nth-child(5){
                //     margin-left: 0;
                // }

                // &:nth-child(4), &:last-child{
                //     margin-right: 0;
                // }

                &.wallet {
                    @include gradient( #5521E9, #D596F6, left, right);
                }

                &.msg {
                    @include gradient( #006BFF, #19B8FF, left, right);
                }

                &.email {
                    @include gradient( #FF1616, #FC3989, left, right);
                }

                &.assign-point {
                    @include gradient( #005DFD, #11D57B, left, right);
                }

                &.unsolved-token {
                    @include gradient( #F95811, #F9C221, left, right);
                }

                &.unrated-token {
                    @include gradient( #FF1400, #FE8989, left, right);
                }

                &.unread-email{
                    @include gradient( #6382FF, #F22E63, left, right);
                }

                &.unread-notice{
                    @include gradient( #B300FD, #00FEED, left, right);
                }

                // .msg-icon, .email-icon, .assign-point-icon 

                .inside-box{
                    position: relative;
                    overflow: hidden;
                    padding: 24px 0 0 0;

                    .wallet-icon, .msg-icon, .email-icon, .assign-point-icon, .unsolved-token-icon, .unrated-token-icon, .unread-email-icon, .unread-notice-icon {
                        height: 33px;
                        width: 31px;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }

                    .wallet-icon{
                        background-image: url('../images/mainDashboardIcon/wallet-Icon.png');
                    }

                    .msg-icon{
                        background-image: url('../images/mainDashboardIcon/message-Icon.png');
                    }

                    .email-icon{
                        background-image: url('../images/mainDashboardIcon/email-Icon.png');
                    }

                    .assign-point-icon{
                        background-image: url('../images/mainDashboardIcon/user-Icon.png');
                    }

                    .unsolved-token-icon{
                        background-image: url('../images/mainDashboardIcon/unsolved-token-Icon.png');
                    }

                    .unrated-token-icon{
                        background-image: url('../images/mainDashboardIcon/not-rated-token-Icon.png');
                        transform: scale(1.5);
                    }

                    .unread-email-icon{
                        background-image: url('../images/mainDashboardIcon/unread-email-Icon.png');
                    }

                    .unread-notice-icon{
                        background-image: url('../images/mainDashboardIcon/unreadnotice-Icon.png');
                    }

                    h1{
                        font-size: 16px;
                        font-weight: 500;
                        margin: 10px 0 2px 0;
                    }

                    hr{
                        margin: 0 0 5px 0;
                        max-width: 150px;
                        opacity: .25;
                    }

                    .inside-currency{
                        margin-bottom: 10px;
                        
                        p{
                            position: relative;
                            font-size: 20px;
                            font-weight: 700;
                            padding-left: 20px;

                            &.tk-sign::before{
                                content: '৳';
                                position: absolute;
                                left: 0;
                                font-family: arial, sans-serif;
                                font-size: 12px;
                                opacity: .75;
                            }

                            i{
                                position: absolute;
                                left: 0;
                                top: 2px;
                                font-size: 12px;
                                opacity: .75;
                            }
                        }
                    }

                    .animated-circle{
                        height: 106px;
                        overflow: hidden;
                        left: unset;
                        right: -98px;
                        top: -28px;
                        -webkit-transform: rotate(270deg); /* Safari and Chrome */
                        -moz-transform: rotate(270deg);   /* Firefox */
                        -ms-transform: rotate(270deg);   /* IE 9 */
                        -o-transform: rotate(270deg);   /* Opera */
                        transform: rotate(270deg);
                    }
                }

                
            }
        }
    }
}